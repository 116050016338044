import dayjs from 'dayjs'

export const formatDates = (startDate: string, endDate: string) => {
  const _startDate = dayjs(startDate)
  // there is a difference in dayjs for chrome and firebox. endDate is like '10-10' no year so the old dayjs method failed
  // with invalid date in firefox
  // let _endDate = dayjs(endDate );
  //_endDate = _endDate.set("year", _startDate.year());
  let _endDate = dayjs(+_startDate.year() + '-' + endDate)
  if (endDate && endDate.length == 10) {
    _endDate = dayjs(endDate)
  }

  return `${_startDate.format('DD/MM/YYYY')} &ndash; ${_endDate.format('DD/MM/YYYY')}`
}

export const formatDatesPlain = (startDate: string, endDate: string) => {
  console.log("formatDatesPlain", startDate, endDate)
  const _startDate = dayjs(startDate)
  // there is a difference in dayjs for chrome and firebox. endDate is like '10-10' no year so the old dayjs method failed
  // with invalid date in firefox
  // let _endDate = dayjs(endDate );
  //_endDate = _endDate.set("year", _startDate.year());
  let _endDate = undefined
  if (endDate && endDate.length === 5) {
    _endDate = dayjs(+_startDate.year() + '-' + endDate)
  } else {
    _endDate = dayjs(endDate)
  }

  return `${_startDate.format('DD/MM/YYYY')} - ${_endDate.format('DD/MM/YYYY')}`
}
export const formatDate = (dd: string) => {
  const _dDate = dayjs(dd)

  return `${_dDate.format('DD/MM/YYYY')}`
}

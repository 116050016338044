<template>
  <nav class="nav bg-alternative d-print-none">
    <div class="navbar navbar-primary">
      <div class="navbar-inner container px-0">
        <ul class="nav-primary">
          <!-- sonar-ignore -->
          <li v-for="menuItem in menu.menuItems" :key="menuItem.label">
            <a
              v-if="menuItem.link !== '/calculator/master-data' && !calculation.companyInformation?.id"
              :id="`${menuItem.id}-link`"
              aria-disabled="true"
              class="nav-link disabled"
              :data-cy-inner="menuItem.labelKey"
            >
              <span>
                {{ menuItem.label }}
              </span>
            </a>
            <router-link
              v-else
              :id="`${menuItem.id}-link`"
              :active-class="'current bold'"
              :exact="menuItem.exact"
              :to="menuItem.link"
              class="nav-link"
              :data-cy-inner="menuItem.labelKey"
            >
              <span>
                {{ menuItem.label }}
              </span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { Menu } from "@/shared/MenuStructure"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"

export default class Navigation extends BaseComponent {
  menu: Menu = {} as Menu

  get calculation() {
    return this.store.getters.getCurrentCalculation
  }

  async beforeMount() {
    this.menu = this.store.getters.getMenuStructure.calculatorMenu
  }
}
</script>

<style lang="scss" scoped>

.nav-primary .nav-link:hover span,
.nav-primary .current span {
  color: $color-primary-dark;
  border-bottom: 7px solid transparent;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disabled:hover {
  opacity: 0.5;
  pointer-events: none;
  color: #454545;
}
</style>

import { Log, UserManagerSettings } from 'oidc-client'
import jwtEnv from "./jwtEnv.json";
import {useStore} from "vuex";


export default class OidcConfig {
  oidcSettings: UserManagerSettings;

  constructor(options: {
    clientId: string;
    authority: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
  }) {
    //console.log("oidc constructor called, org.clientId: ", options.clientId, "postfix: ", getProfilePostfix())
    Log.logger = console;
    Log.level = Log.DEBUG;

    this.oidcSettings = {
      authority: options.authority,
      client_id: options.clientId,
      redirect_uri: options.redirectUri,
      response_type: "code",
      scope: "openid",
      post_logout_redirect_uri: options.postLogoutRedirectUri,
      loadUserInfo: true,
      automaticSilentRenew: false,
      monitorSession: false,
      accessTokenExpiringNotificationTime: 5*60, // notification 5 minutes before expiration
      extraQueryParams: {claims: '{"userinfo":{"name":null,"dk:gov:cpr":null,"dk:gov:cvr":null,"dk:gov:org_name":null,"dk:gov:virk:email":null,"dk:gov:cpr_uuid":null,"dk:gov:professional_uuid":null,"dk:gov:nsis:loa":null}}'}
    };
  }
}

function getProfilePostfix(): string {
  const method = window.sessionStorage.getItem('setLoginMethod')
  if (method === 'SSI') {
    return '_ssi'
  } else {
    return ''
  }
}

function getSetting(): any {
  const currentUrl = window.location.href;
  //console.log("getSetting url: ", currentUrl, ", postfix: ", getProfilePostfix())
  if (currentUrl.startsWith("http://localhost.erstdev") || currentUrl.startsWith("https://localhost.erstdev")) {
   return getProfilePostfix() === '_ssi' ? jwtEnv.config.localhost_ssi : jwtEnv.config.localhost;
  } else if (currentUrl.startsWith('http://localhost')) {
    return jwtEnv.config.keycloak_local;
  } else if (currentUrl.startsWith("http://kkbackend")) {
    return jwtEnv.config.keycloak_cypress_docker_local;
  } else if (currentUrl.startsWith("https://dev.")) {
    return getProfilePostfix() === '_ssi' ? jwtEnv.config.dev_ssi : jwtEnv.config.dev;
  } else if (currentUrl.startsWith("https://dev2.")) {
    return getProfilePostfix() === '_ssi' ? jwtEnv.config.dev2_ssi : jwtEnv.config.dev2;
  } else if (currentUrl.startsWith("https://test.")) {
    return getProfilePostfix() === '_ssi' ? jwtEnv.config.test_ssi : jwtEnv.config.test;
  } else if (currentUrl.startsWith("https://preprod.")) {
    return getProfilePostfix() === '_ssi' ? jwtEnv.config.preprod_ssi : jwtEnv.config.preprod;
  } else {
    return getProfilePostfix() === '_ssi' ? jwtEnv.config.prod_ssi : jwtEnv.config.prod;
  }
}

const jwtEnvConfig = getSetting();

const options = {
  clientId: jwtEnvConfig.clientId,
  authority: jwtEnvConfig.authority,
  redirectUri: jwtEnvConfig.redirectUri,
  postLogoutRedirectUri: jwtEnvConfig.postLogoutRedirectUri,
};

export const oidcConfig = new OidcConfig(options);

<template>
  <SharedHeader>
    <template #firstColumn>
      <div id='guides-first-column-header'>
        <h1>{{ currentPageHeader.title }}</h1>
        <span v-dompurify-html='currentPageHeader.description'></span>
      </div>
      <div class='mt-5'>
        <!--a v-if='currentPageHeader.buttonPrimaryLink' :href='currentPageHeader.buttonPrimaryLink'
           class='button button-primary mr-4' target='_blank'>{{ currentPageHeader.buttonPrimaryText }}</a>
        <a v-if='currentPageHeader.buttonSecondaryLink' :href='currentPageHeader.buttonSecondaryLink'
           class='button button-secondary' target='_blank'>{{ currentPageHeader.buttonSecondaryText }}</a-->
        <!-- convert from href to button due to SQ security errors -->
        <button v-if='currentPageHeader.buttonPrimaryLink'
                type="button"
                class="button button-primary mr-4"
                @click="openLink(currentPageHeader.buttonPrimaryLink)">{{ currentPageHeader.buttonPrimaryText }}
        </button>
        <button v-if='currentPageHeader.buttonSecondaryLink'
                type="button"
                class="button button-secondary"
                @click="openLink(currentPageHeader.buttonSecondaryLink)">{{ currentPageHeader.buttonSecondaryText }}
        </button>
      </div>
    </template>
    <template #secondColumn>
      <img v-if='currentPageHeader.imageUrl' :src='currentPageHeader.imageUrl'
           alt='' />
    </template>
  </SharedHeader>
  <div class='container px-0 pb-6'>
    <h2>
      {{ pageTitle }}
    </h2>
    <span v-dompurify-html='pageDescription'></span>
    <AccordionComponent :accordion-list='getAccordions("guides.background")'></AccordionComponent>
  </div>
</template>

<script lang='ts'>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import * as DKFDS from 'dkfds'
import { Options, Vue } from 'vue-class-component'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import { GuideHeader } from '@/components/guides/Guides.vue'
import AccordionComponent from '@/components/AccordionComponent.vue'
import SharedHeader from '@/components/calculator/shared/SharedHeader.vue'
import DOMPurify from 'dompurify'

@Options({
  components: {
    AccordionComponent,
    SharedHeader
  }
})
export default class Background extends BaseComponent {
  currentPageHeader: GuideHeader = {} as GuideHeader

  pageTitle = ''
  pageDescription = ''

  get documentToHtmlString() {
    return documentToHtmlString
  }

  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

  async beforeMount() {
    this.initPage()
    this.setDocumentTitle(this.pageTitle)
  }

  initPage() {
    this.pageTitle = this.getContentfulString('guides.background.page.title')
    this.pageDescription = this.getRenderedContentfulRT('guides.background.page.description')

    this.currentPageHeader.title = this.getContentfulString('guides.background.header.title')
    this.currentPageHeader.description = this.getRenderedContentfulRT('guides.background.header.description')
    const pLink = this.Contentful.findSimpleTextOrNull('guides.background.header.buttonprimary.link')
    this.currentPageHeader.buttonPrimaryLink = pLink ? DOMPurify.sanitize(pLink) : pLink
    this.currentPageHeader.buttonPrimaryText = this.Contentful.findSimpleTextOrNull('guides.background.header.buttonprimary.text')
    const sLink = this.Contentful.findSimpleTextOrNull('guides.background.header.buttonsecondary.link')
    this.currentPageHeader.buttonSecondaryLink = sLink ? DOMPurify.sanitize(sLink) : sLink
    this.currentPageHeader.buttonSecondaryText = this.Contentful.findSimpleTextOrNull('guides.background.header.buttonsecondary.text')
    this.currentPageHeader.imageUrl = this.Contentful.findSimpleTextOrNull('guides.background.header.imageurl')
  }

  openLink(url: string) {
    window.open(url)
  }

}
</script>

<style scoped>

</style>

import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "full-width"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_ctx.richTextRendered)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", null, null, 512), [
          [_directive_dompurify_html, _ctx.richTextRendered]
        ])
      ]))
    : _createCommentVNode("", true)
}
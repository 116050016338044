import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "aria-atomic": "true",
  "aria-label": "Beskedbox der viser information",
  class: "alert alert-info alert--show-icon",
  role: "alert"
}
const _hoisted_2 = { class: "alert-body" }
const _hoisted_3 = { class: "alert-heading" }
const _hoisted_4 = { class: "alert-text" }
const _hoisted_5 = { class: "row py-6" }
const _hoisted_6 = { class: "col-12 px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedHeader = _resolveComponent("SharedHeader")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SharedHeader, {
      showCalculationRow: true,
      tabMenu: _ctx.menu
    }, {
      firstColumn: _withCtx(() => [
        _withDirectives(_createElementVNode("div", null, null, 512), [
          [_directive_dompurify_html, _ctx.documentToHtmlString(_ctx.pageProperties.headerText)]
        ])
      ]),
      secondColumn: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.pageProperties.importantInfoTitle), 1),
            _withDirectives(_createElementVNode("p", _hoisted_4, null, 512), [
              [_directive_dompurify_html, _ctx.documentToHtmlString(_ctx.pageProperties.importantInfoText)]
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["tabMenu"]),
    _createVNode(_component_Breadcrumbs, {
      scrollPosition: _ctx.scrollPosition,
      tabMenu: _ctx.menu,
      tabsAreVisible: _ctx.tabsAreVisible
    }, null, 8, ["scrollPosition", "tabMenu", "tabsAreVisible"]),
    _createElementVNode("div", {
      class: _normalizeClass([!_ctx.tabsAreVisible && 'pt-905', "container"])
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_router_view)
        ])
      ])
    ], 2)
  ], 64))
}
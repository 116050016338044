import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f4827178"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "guides-first-column-header" }
const _hoisted_2 = { class: "mt-5" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "container px-0 pb-6" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "w-percent-100 w-percent-100-child" }
const _hoisted_7 = { class: "news-content" }
const _hoisted_8 = { class: "news-label tag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedHeader = _resolveComponent("SharedHeader")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SharedHeader, null, {
      firstColumn: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.currentPageHeader.title), 1),
          _withDirectives(_createElementVNode("span", null, null, 512), [
            [_directive_dompurify_html, _ctx.currentPageHeader.description]
          ])
        ]),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.currentPageHeader.buttonPrimaryLink)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "button button-primary mr-4",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openLink(_ctx.currentPageHeader.buttonPrimaryLink)))
              }, _toDisplayString(_ctx.currentPageHeader.buttonPrimaryText), 1))
            : _createCommentVNode("", true),
          (_ctx.currentPageHeader.buttonSecondaryLink)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                type: "button",
                class: "button button-secondary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openLink(_ctx.currentPageHeader.buttonSecondaryLink)))
              }, _toDisplayString(_ctx.currentPageHeader.buttonSecondaryText), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      secondColumn: _withCtx(() => [
        (_ctx.currentPageHeader.imageUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.currentPageHeader.imageUrl,
              alt: ""
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.pageTitle), 1),
      (_ctx.pageDescription !== "")
        ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_5, null, 512)), [
            [_directive_dompurify_html, _ctx.pageDescription]
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedNews, (column) => {
          return (_openBlock(), _createElementBlock("div", {
            key: column.fields.title,
            class: _normalizeClass(["news-wrapper", [( column.fields.type === 'informationsbesked' ? 'info' : ''), (column.fields.type === 'advarselsbesked' ? 'warning' : ''), (column.fields.type === 'succesbesked' ? 'success' : '')]])
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.formatDate(column.sys.createdAt)) + " " + _toDisplayString(_ctx.newsTypeLabelText(column.fields.type)), 1),
              _createElementVNode("h4", null, _toDisplayString(column.fields.title), 1),
              _withDirectives(_createElementVNode("p", null, null, 512), [
                [_directive_dompurify_html, _ctx.documentToHtmlString(column.fields.text)]
              ])
            ])
          ], 2))
        }), 128))
      ])
    ])
  ], 64))
}
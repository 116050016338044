<template>

  <div class="wrapper-div" id="resultsEsg-wrapper-div">

    <div id="section-one-container" class="section-one-container">
      <h1 id="section-one-main-header">
        <SimpleText text-key="results.esg.result-main-header"/>
      </h1>
      <p id="main-description-text" class="main-description-text">
        <RichText text-key="results.esg.result-main-description-text"/>
      </p>

      <table id="esg-results-table1-emission" class="table results-table w-percent-100 mb-3" v-if="results?.groupingResults"
      aria-describedby="esg-result-table1-emission-header1">
        <thead>
          <tr class="bg-primary text-color-white">
            <th id="esg-result-table1-emission-header1" colspan="3" scope="colgroup">
              <SimpleText text-key="results.esg.result-table1-emission-header-one"/>
            </th>
          </tr>
          </thead>
          <thead>
          <tr style="font-weight: 600" class="w-percent-100">
            <th headers="esg-result-table1-emission-header1" style="width: 55%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-one">
              <SimpleText text-key="results.esg.result-table-subheader-one"></SimpleText>
            </th>
            <th headers="esg-result-table1-emission-header1" style="width: 15%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-two">
              <SimpleText text-key="results.esg.result-table-subheader-two"></SimpleText>
            </th>
            <th headers="esg-result-table1-emission-header1" style="width: 30%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-three">
              <SimpleText text-key="results.esg.result-table-subheader-three"></SimpleText>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr id="esg-result-scope-one">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scope-one"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.esgResult?.scope1Total" class="align-text-right"> {{ doPrettify(results.esgResult.scope1Total) }}</td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-scope-two">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scope-two"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.esgResult?.scope2Total" class="align-text-right"> {{ doPrettify(results.esgResult.scope2Total) }}</td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-scope-three">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scope-three"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.esgResult?.scope3Total" class="align-text-right"> {{ doPrettify(results.esgResult.scope3Total) }}</td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-scopes-total">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scopes-total"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.ghgCalculatedResult?.totalResult" class="align-text-right"> {{
              doPrettify(results.ghgCalculatedResult.totalResult)
            }}
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>
        <tr id="esg-result-co2intensity">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-co2intensity"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-two"></SimpleText>
          </td>
          <td v-if="results.esgResult?.co2Intensity" class="align-text-right"> {{ doPrettify(results.esgResult.co2Intensity) }}</td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>
        </tbody>
      </table>

      <RichText text-key="results.esg.pre-table2-energy-description"/>
      
      <table id="esg-results-table2-energy" class="table results-table w-percent-100 mb-3" v-if="results?.groupingResults"
      aria-describedby="esg-result-table2-energy-header1">
        <thead>
          <tr class="bg-primary text-color-white">
            <th id="esg-result-table2-energy-header1" colspan="4" scope="colgroup">
              <SimpleText text-key="results.esg.result-table2-energy-header-one"/>
            </th>
          </tr>
          </thead>
          <thead>
          <tr style="font-weight: 600" class="w-percent-100">
            <th headers="esg-result-table2-energy-header1" style="width: 55%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-one">
              <SimpleText text-key="results.esg.result-table-subheader-one"></SimpleText>
            </th>
            <th headers="esg-result-table2-energy-header1" style="width: 15%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-two">
              <SimpleText text-key="results.esg.result-table-subheader-two"></SimpleText>
            </th>
            <th headers="esg-result-table2-energy-header1" style="width: 15%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-three">
              <SimpleText text-key="results.esg.result-table-subheader-three"></SimpleText>
            </th>
            <th headers="esg-result-table2-energy-header1" style="width: 15%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-four">
              <SimpleText text-key="results.esg.result-table-subheader-four"></SimpleText>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr id="esg-result-fossil-energy">
            <td class="align-text-left pl-6">
              <SimpleText text-key="results.esg.result-table-row-fossil-energy"></SimpleText>
            </td>
            <td>
              <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
            </td>
            <td v-if="results.esgResult?.energyConsumptionFossilSourcesResult" class="align-text-right">
              {{ doPrettify(results.esgResult.energyConsumptionFossilSourcesResult) }}
              <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
            <td v-if="results.esgResult?.energyConsumptionFossilSourcesPct" class="align-text-right">
              {{ doPrettify(results.esgResult.energyConsumptionFossilSourcesPct) }}%
              <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>
          <tr id="esg-result-nuclear-energy">
            <td class="align-text-left pl-6">
              <SimpleText text-key="results.esg.result-table-row-nuclear-energy"></SimpleText>
            </td>
            <td>
              <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
            </td>
            <td v-if="results.esgResult?.energyConsumptionNuclearSourcesResult" class="align-text-right">
              {{ doPrettify(results.esgResult.energyConsumptionNuclearSourcesResult) }}
              <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
            <td v-if="results.esgResult?.energyConsumptionNuclearSourcesPct" class="align-text-right">
              {{ doPrettify(results.esgResult.energyConsumptionNuclearSourcesPct) }}%
              <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>
          <tr id="esg-result-renewable-energy">
            <td class="align-text-left pl-6">
              <SimpleText text-key="results.esg.result-table-row-renewable-energy"></SimpleText>
            </td>
            <td>
              <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
            </td>
            <td v-if="results.esgResult?.energyConsumptionRenewableSourcesResult" class="align-text-right">
              {{ doPrettify(results.esgResult.energyConsumptionRenewableSourcesResult) }}
              <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
            <td v-if="results.esgResult?.energyConsumptionRenewableSourcesPct" class="align-text-right">
              {{ doPrettify(results.esgResult.energyConsumptionRenewableSourcesPct) }}%
              <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>
          <tr id="esg-result-energytotal">
            <td class="align-text-left">
              <SimpleText text-key="results.esg.result-table-row-energytotal"></SimpleText>
            </td>
            <td>
              <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
            </td>
            <td v-if="results.esgResult?.energyTotal" class="align-text-right">
              {{ doPrettify(results.esgResult.energyTotal) }}
              <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
            <td v-if="results.esgResult?.energyTotal" class="align-text-right"> {{doPrettify(100)}}%</td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>
        </tbody>
      </table>

      <RichText text-key="results.esg.pre-table3-water-and-waste-description"/>

      <table id="esg-results-table3-water-and-waste" class="table results-table w-percent-100 mb-3" v-if="results?.groupingResults"
      aria-describedby="esg-result-table3-water-and-waste-header1">
        <thead>
          <tr class="bg-primary text-color-white">
            <th id="esg-result-table3-water-and-waste-header1" colspan="3" scope="colgroup">
              <SimpleText text-key="results.esg.result-table3-water-and-waste-header-one"/>
            </th>
          </tr>
          </thead>
          <thead>
          <tr style="font-weight: 600" class="w-percent-100">
            <th headers="esg-result-table3-water-and-waste-header1" style="width: 55%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-one">
              <SimpleText text-key="results.esg.result-table-subheader-one"></SimpleText>
            </th>
            <th headers="esg-result-table3-water-and-waste-header1" style="width: 15%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-two">
              <SimpleText text-key="results.esg.result-table-subheader-two"></SimpleText>
            </th>
            <th headers="esg-result-table3-water-and-waste-header1" style="width: 30%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-three">
              <SimpleText text-key="results.esg.result-table-subheader-three"></SimpleText>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr id="esg-result-waterconsumption">
            <td class="align-text-left">
              <SimpleText text-key="results.esg.result-table-row-waterconsumption"></SimpleText>
            </td>
            <td>
              <SimpleText text-key="calculation.dropdown.possiblevalue.fuels.m3"></SimpleText>
            </td>
            <td v-if="results.esgResult?.waterConsumption" class="align-text-right"> {{ doPrettify(results.esgResult.waterConsumption) }}</td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>
          <tr id="esg-result-waterintensity">
            <td class="align-text-left pl-6">
              <SimpleText text-key="results.esg.result-table-row-waterintensity"></SimpleText>
            </td>
            <td>
              <SimpleText text-key="results.esg.result-table-measurement-unit-nine"></SimpleText>
            </td>
            <td v-if="results.esgResult?.waterIntensity" class="align-text-right"> {{ doPrettify(results.esgResult.waterIntensity) }}</td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>

          <tr id="esg-result-wastetotal">
            <td class="align-text-left">
              <SimpleText text-key="results.esg.result-table-row-wastetotal"></SimpleText>
            </td>
            <td>
              <SimpleText text-key="results.esg.result-table-measurement-unit-eight"></SimpleText>
            </td>
            <td v-if="results.esgResult?.wasteTotal" class="align-text-right">
              {{ doPrettify(results.esgResult.wasteTotal) }}
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>

          <tr id="esg-result-wastehazardous">
            <td class="align-text-left  pl-6">
              <SimpleText text-key="results.esg.result-table-row-wastehazardous"></SimpleText>
              <Tooltip
                  aria-label="result-result-waste-hazardous-percentage-tooltip"
                  textKey="results.esg.result-waste-hazardous-percentage-tooltip"
                  style="align-content: flex-end"
                  size="md-gr"
              ></Tooltip>
            </td>
            <td>
              <SimpleText text-key="results.esg.result-table-measurement-unit-five"></SimpleText>
            </td>
            <td v-if="results.esgResult?.wasteHazardousPercentage" class="align-text-right">
              {{ doPrettify(results.esgResult.wasteHazardousPercentage) }}
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>

          <tr id="esg-result-wasterecycled">
            <td class="align-text-left  pl-6">
              <SimpleText text-key="results.esg.result-table-row-wasterecycled"></SimpleText>
            </td>
            <td>
              <SimpleText text-key="results.esg.result-table-measurement-unit-five"></SimpleText>
            </td>
            <td v-if="results.esgResult?.wasteRecycledPercentage" class="align-text-right"> {{
                doPrettify(results.esgResult.wasteRecycledPercentage)
              }}
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>
        </tbody>
      </table>

      <RichText text-key="results.esg.pre-table4-electricity-description"/>

      <table id="esg-results-table4-electricity" class="table results-table w-percent-100 mb-3" v-if="results?.groupingResults"
      aria-describedby="esg-result-table4-electricity-header1">
        <thead>
          <tr class="bg-primary text-color-white">
            <th id="esg-result-table4-electricity-header1" colspan="4" scope="colgroup">
              <SimpleText text-key="results.esg.result-table4-electricity-header-one"/>
            </th>
          </tr>
          </thead>
          <thead>
          <tr style="font-weight: 600" class="w-percent-100">
            <th headers="esg-result-table4-electricity-header1" style="width: 55%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-one">
              <SimpleText text-key="results.esg.result-table-subheader-one"></SimpleText>
            </th>
            <th headers="esg-result-table4-electricity-header1" style="width: 15%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-two">
              <SimpleText text-key="results.esg.result-table-subheader-two"></SimpleText>
            </th>
            <th headers="esg-result-table4-electricity-header1" style="width: 15%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-three">
              <SimpleText text-key="results.esg.result-table-subheader-three"></SimpleText>
            </th>
            <th headers="esg-result-table4-electricity-header1" style="width: 15%" class="py-405 align-text-left bg-alternative color-text-black" id="results.esg.result-table-subheader-four">
              <SimpleText text-key="results.esg.result-table-subheader-four"></SimpleText>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr id="esg-result-renewable-electricity">
            <td class="align-text-left pl-6">
              <SimpleText text-key="results.esg.result-table-row-renewable-electricity"></SimpleText>
            </td>
            <td>
              <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
            </td>
            <td v-if="results.esgResult?.electricityRenewableTotal" class="align-text-right">
              {{ doPrettify(results.esgResult.electricityRenewableTotal) }}
              <div v-if="results.esgResult.mustUpdateDueToESGElectricity">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGElectricity" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
            <td v-if="results.esgResult?.electricityRenewableSharePct" class="align-text-right">
              {{doPrettify(results.esgResult.electricityRenewableSharePct)}}%
              <div v-if="results.esgResult.mustUpdateDueToESGElectricity">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGElectricity" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>
          <tr id="esg-result-non-renewable-electricity">
            <td class="align-text-left pl-6">
              <SimpleText text-key="results.esg.result-table-row-non-renewable-electricity"></SimpleText>
            </td>
            <td>
              <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
            </td>
            <td v-if="results.esgResult?.electricityNonRenewableTotal" class="align-text-right">
              {{ doPrettify(results.esgResult.electricityNonRenewableTotal) }}
              <div v-if="results.esgResult.mustUpdateDueToESGElectricity">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGElectricity" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
            <td v-if="results.esgResult?.electricityNonRenewableSharePct" class="align-text-right">
              {{doPrettify(results.esgResult.electricityNonRenewableSharePct)}}%
              <div v-if="results.esgResult.mustUpdateDueToESGElectricity">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGElectricity" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>
          <tr id="esg-result-electricity-total">
            <td class="align-text-left">
              <SimpleText text-key="results.esg.result-table-row-electricity"></SimpleText>
            </td>
            <td>
              <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
            </td>
            <td v-if="results.esgResult?.electricityTotal" class="align-text-right">
              {{ doPrettify(results.esgResult.electricityTotal) }}
              <div v-if="results.esgResult.mustUpdateDueToESGElectricity">
                <SimpleText text-key="results.esg.result-table.number-may-not-be-correct"/>
                <a href="/klimakompasset/overview" style="text-decoration: none">
                  <SimpleText text-key="results.esg.result-table.update-link"/>
                </a>
              </div>
            </td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGElectricity" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
            <td v-if="results.esgResult?.electricityTotal" class="align-text-right"> {{doPrettify(100)}}%</td>
            <td v-else-if="results.esgResult?.mustUpdateDueToESGElectricity" class="italic-text">
              <SimpleText text-key="results.esg.result-table.must-update"/>
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link"/>
              </a>
            </td>
            <td v-else class="italic-text-right">
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script lang="ts">

import {Menu} from "@/shared/MenuStructure";
import {useRouter} from "vue-router";
import {Options} from "vue-class-component";
import RichText from "@/components/RichText.vue";
import SimpleText from "@/components/SimpleText.vue";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import ContentfulContent from "@/shared/ContentfulContent";
import {Prop} from "vue-property-decorator";
import {
  GroupingCalculatedResults
} from "../../../../openapi/generated-clients/climatecompass";
import Tooltip from "@/components/Tooltip.vue";

@Options({
  components: {
    Tooltip,
    RichText,
    SimpleText,
  }
})
export default class ResultsESG extends BaseComponent {

  @Prop()
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults

  @Prop()
  printMode?: boolean

  menu = {} as Menu
  router = useRouter()

  contentfulContent: ContentfulContent = this.store.getters.getContentfulContent

  async beforeMount() {
    this.scrollToTargetWithOffset('section-one-container', 10)
  }

}
</script>

<style scoped lang="scss">

* {
  -webkit-print-color-adjust: exact;
}

.wrapper-div {
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.section-one-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5em;
  max-width: 100%;
  margin: 2px;
}

.section-two-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-top: 10px;
}

.main-description-text {
  max-width: 100%;
}

.italic-text {
  font-style: italic;
}

.italic-text-right {
  font-style: italic;
  text-align: right;
}

tr > th.py-405.align-text-left.bg-alternative {
  color: black;
  border-right: 1px solid $color-primary !important;
  border-bottom: 1px solid #BFBFBF !important;
}

</style>
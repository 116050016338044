<template>
  <div class='container px-0 py-9' v-if="pageAccess">
    <h1>Excel mappings</h1>
    <section>
      <form enctype="multipart/form-data">
        <button class="button button-primary" @click.prevent="dummyUploadFile()">Vælg Excel fil</button>
        <input
            style="display: none;"
            type="file"
            :id="`map-fileinput`"
            name="file"
            @change="addFile"
        />
        SheetName:
        <input type="text" id="form-input-4" class="form-control" v-model="sheetName" placeholder="f.eks. 2022v1">

      </form>
      <h2>Excel nøgler som mangler mapping ({{ excelNonMappedKeys.length }})</h2>
      <p>Filnavn: {{ excelFileName.fileName }}</p>

      <div class='table--responsive-scroll' style="max-height: 550px; overflow-x: hidden">
        <table class='table table--selectable w-percent-100' id='selectable-table1' style="min-width: 100%; table-layout: fixed;">
          <caption>Nøgler som mangler mapnings</caption>
          <colgroup>
            <col style="width: 12%" />
            <col style="width: 13%" />
            <col style="width: 13%" />
            <col style="width: 13%" />
            <col style="width: 23%" />
            <col style="width: 20%" />
            <col style="width: 6%" />
          </colgroup>
          <thead>
          <tr>
            <th @click="sortExcelNonMappedKeys('mainCategory')">Hovedkategori</th>
            <th @click="sortExcelNonMappedKeys('category')">Kategori</th>
            <th @click="sortExcelNonMappedKeys('parameter1')">Parameter 1</th>
            <th @click="sortExcelNonMappedKeys('parameter2')">Parameter 2</th>
            <th @click="sortExcelNonMappedKeys('key')">Excel nøgle</th>
            <th>Scopes (s1/s2/s3/os)</th>
            <th @click="sortExcelNonMappedKeys('unit')">Enhed</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='item in excelNonMappedKeys' :key='item.key' @change="sortTablesBySelectedUnit" @click='selectRowToMap(item.key, true)'
              :class='{ "selected-row":  isSelected(item.key)}'>
            <td>{{ item.mainCategory }}</td>
            <td>{{ item.category }}</td>
            <td>{{ item.parameter1 }}</td>
            <td>{{ item.parameter2 }}</td>
            <td>{{ item.key }}</td>
            <td>{{ item.scope1 }}<br>{{ item.scope2 }}<br>{{ item.scope3 }}<br>{{ item.outsideScope }}</td>
            <td>{{ item.unit }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <input :id="'input_filter'" type='checkbox' name='selectable-table2-check[]' class='form-checkbox' :value="filterByUnit"
             @change="filterByUnitToggle(isSelected(this.excelKeyToMap !== '' ? this.excelKeyToMap : this.masterKeyToMap))">
      <label :for="'input_filter'"><span>Filtrer Enhed for bedste hit</span></label>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Filtrer via indhold: <input type="text" id="filterInput" @keyup="filterInput()" placeholder="Filtrer.." title="Skriv søgekriterie">
      <h2>EMF Master data med egen EMC ({{ emfMasterdataList.length }})</h2>
      <div v-if="sortedVersions?.length > 1" >
        Hent scope værdier for version:
        <select
            class="form-select"
            :value="emissionFactorYearVersion"
            v-model="emissionFactorYearVersion"
            name="versions">
          <option v-for="item in [...this.versions?.versions]" :key="item.id" :value="item.id">
            {{ item.year }}v{{item.version}}
          </option>
        </select>

      </div>
      <div class='table--responsive-scroll' style="max-height: 550px; overflow-y: auto; overflow-x: hidden">
        <table class='table table--selectable' id='selectable-table2' style="min-width: 100%; table-layout: fixed">
          <caption>EMF Master data med egen EMC</caption>
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 7%" />
            <col style="width: 15%" />
            <col style="width: 8%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
            <col style="width: 30%" />
          </colgroup>
          <thead>
          <tr>
            <th @click="sortEMFMasterData('key')">Key</th>
            <th @click="sortEMFMasterData('subgroupingId')">Subgrp.</th>
            <th @click="sortEMFMasterData('subgroupingName')">Subgrp. navn</th>
            <th @click="sortEMFMasterData('unit')">Enhed</th>
            <th @click="sortEMFMasterData('information')">Field_enum</th>
            <th>Scopes (s1/s2/s3/os)</th>
            <th @click="sortEMFMasterData('description')">Beskrivelse</th>
            <th @click="sortEMFMasterData('hasOwnEMC')">Egen EMC</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='item in emfMasterdataList' :key='item.id' @change="sortTablesBySelectedUnit" @click='selectRowToMap(item.key, false)'
              :class='{ "selected-row":  isSelected(item.key) }'>
            <td>{{ item.key }}</td>
            <td>{{ item.subgroupingId }}</td>
            <td>{{ item.subgroupingName }}</td>
            <td>{{ item.unit }}</td>
            <td>{{ item.information }}</td>
            <td>{{ item.scope1 }}<br>{{ item.scope2 }}<br>{{ item.scope3 }}<br>{{ item.outsideScope }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.hasOwnEMC ? 'Ja' : 'Nej' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <h2>Excel mappings ({{ excelMappings.length }})</h2>
      <div id="button-container-div" class="pb-6">
        <button id='submit-button-forecast' type='submit' class='button button-primary'
                title="Når der er valgt nøgle of master data oprettes en mapning" @click="createMapping">Gem
        </button>
        <button id='submit-button-forecast3' type='submit' class='button button-primary'
                @click="deleteMapping()" title="Slet excel mapping">Slet
        </button>
        <button id='submit-button-forecast2' type='submit' class='button button-primary' @click="exportToSqlString"
                title="Eksporter Excel mapping så anvendes fra andre miljøer">Exporter SQL
        </button>
      </div>
      <div class='table--responsive-scroll' style="max-height: 550px; overflow-y: auto; overflow-x: hidden">
        <table class="table table--selectable" id="selectable-table3" style="min-width: 100%; table-layout: fixed">
          <caption>Excel mapninger</caption>
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 40%" />
            <col style="width: 40%" />
          </colgroup>
          <thead>
          <tr>
            <th @click="sortExcelMappings('id')">id</th>
            <th @click="sortExcelMappings('emfKey')">EMF Key</th>
            <th @click="sortExcelMappings('emfDesc')">EMF Desc</th>
            <th @click="sortExcelMappings('excelKey')">ExcelKey</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='item in excelMappings' :key='item' @click='selectExcelMappingsRowToDelete(item.id)' :class='{ "selected-row":  isSelectedForDeletion(item.id) }'>
            <td>{{ item.id }}</td>
            <td>{{ item.emfKey }}</td>
            <td>{{ item.emfDesc }}</td>
            <td>{{ item.excelKey }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
  <div class='container px-0 py-9' v-else>Din brugerprofil har ikke adgang til dette skærmbillede</div>
</template>

<script lang='ts'>
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import {EMFMasterdata, ExcelMappingdata, ExcelRow, FileMessage, Versions} from '../../../openapi/generated-clients/climatecompass'
import * as DKFDS from 'dkfds'
import {configurationClient, emissionsfactorImportClient, versionClient} from '@/shared/BackendService'
import {forEach, map} from "lodash";
import {Watch} from "vue-property-decorator";
import {useStore} from "vuex";

export default class ExcelMappings extends BaseComponent {
  emfMasterdataList: EMFMasterdata[] = []
  originalEmfMasterdataList: EMFMasterdata[] = []
  excelNonMappedKeys: ExcelRow[] = []
  originalExcelNonMappedKeys: ExcelRow[] = []
  excelMappings: ExcelMappingdata[] = []
  excelMappingsToDelete: ExcelMappingdata[] = []
  excelFile = {} as File
  excelFileName = {} as Partial<FileMessage>

  selectedRows: Record<string, boolean> = {}
  selectedRowsToDelete: Record<number, boolean> = {}
  filterByUnit = false

  excelKeyToMap = ""
  masterKeyToMap = ""
  idToDelete = 0
  mappingId = 0
  sheetName = '2022v1'
  pageAccess = false

  store = useStore()

  excelNonMappedKeysSorting: {
    column: string | null;
    order: 'asc' | 'desc';
  } = { column: null, order: 'asc' };

  emfMasterdataSorting: {
    column: string | null;
    order: 'asc' | 'desc';
  } = { column: null, order: 'asc' };

  excelMappingsSorting: {
    column: string | null;
    order: 'asc' | 'desc';
  } = { column: null, order: 'asc' };

  versions = {} as Versions
  selectedEmissioncoefficientVersionId = 1

  //--- Life-cycle hooks---//

  async beforeMount() {
    DKFDS.init()
    //await this.getEMFMasterdata()
    await this.loadEMFMasterdata()
    await this.loadExcelMappingdata()
    this.versions = (await versionClient.fetchAllVersions()).data
    await configurationClient
        .getImportAccess()
        .then((response) => {
          this.pageAccess = response.data as unknown as boolean
        })
        .catch((error) => {
          console.error(error)
        })
  }

  updated() {
    DKFDS.init()
  }

  //--- Life-cycle hooks end---//

  get sortedVersions() {
    if (this.versions) {
      return this.versions?.versions
    } else {
      return []
    }
  }

  get emissionFactorYearVersion() {
    return this.selectedEmissioncoefficientVersionId
  }

  set emissionFactorYearVersion(id: number) {
    this.selectedEmissioncoefficientVersionId = id
    this.loadEMFMasterdata()
  }

  getUnmappedExcelColumnValue(row: ExcelRow, column: keyof ExcelRow): string {
    return String(row[column]) || '';
  }

  getEMFMasterdataColumnValue(row: EMFMasterdata, column: keyof EMFMasterdata): string {
    return String(row[column]) || '';
  }

  getExcelMappingsColumnValue(row: ExcelMappingdata, column: keyof ExcelMappingdata): string {
    return String(row[column]) || '';
  }

  filterInput() {
    const input = document.getElementById("filterInput") as HTMLInputElement;
    if (input) {
      const filter = input.value.toUpperCase();
      const table = document.getElementById("selectable-table2");
      const tr = table?.getElementsByTagName("tr");
      if (tr) {
        for (let i = 0; i < tr.length; i++) {
          const tdKey = tr[i].getElementsByTagName("td")[0];
          const tdSg = tr[i].getElementsByTagName("td")[1];
          const tdSgName = tr[i].getElementsByTagName("td")[2];
          const tdUnit = tr[i].getElementsByTagName("td")[3];
          const tdDescr = tr[i].getElementsByTagName("td")[5];
          if (tdKey && tdSgName && tdSg && tdUnit && tdDescr) {
            //console.log("tda", tdKey, tdSg, tdSgName)
            const txtKeyValue = tdKey.textContent || tdKey.innerText;
            const txtSgNameValue = tdSgName.textContent || tdSgName.innerText;
            const txtSgValue = tdSg.textContent || tdSg.innerText;
            const txtUnit = tdUnit.textContent || tdUnit.innerText;
            const txtDesc = tdDescr.textContent || tdDescr.innerText;
            if (txtKeyValue.toUpperCase().indexOf(filter) > -1 ||
                txtSgNameValue.toUpperCase().indexOf(filter) > -1 ||
                txtSgValue.toUpperCase().indexOf(filter) > -1 ||
                txtUnit.toUpperCase().indexOf(filter) > -1 ||
                txtDesc.toUpperCase().indexOf(filter) > -1
            ) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }
        }
      }
    }
  }

  @Watch("selectedRows", {deep: true})
  async updateTableData() {
    console.log('entered updateTableData')
    if (this.filterByUnit && (this.excelKeyToMap || this.masterKeyToMap)) {
      console.log('1st if in updateTableData')
      this.sortTablesBySelectedUnit()
    }
    if (this.filterByUnit && (!this.masterKeyToMap && !this.excelKeyToMap)) {
      console.log('2nd if in updateTableData')
      this.excelNonMappedKeys = (await emissionsfactorImportClient.getUnmappedExcelKeys(this.excelFile, this.sheetName)).data || []
      this.emfMasterdataList = this.originalEmfMasterdataList
    } else if (!this.filterByUnit && (!this.masterKeyToMap && !this.excelKeyToMap)) {
      console.log('3rd if in updateTableData')
      this.excelNonMappedKeys = (await emissionsfactorImportClient.getUnmappedExcelKeys(this.excelFile, this.sheetName)).data || []
      this.emfMasterdataList = this.originalEmfMasterdataList
    }
  }

  async loadEMFMasterdata() {
    let result = {} as EMFMasterdata[]
    await emissionsfactorImportClient.getEMFMasterdata(this.selectedEmissioncoefficientVersionId).then((response) => {
      if (response.data) {
        result = response.data
        this.originalEmfMasterdataList = result
        this.emfMasterdataList = result
      }
    })
    return result
  }

  async loadExcelMappingdata() {
      this.eventHub.emit('start-loading', 'transfer-load-from')
      await this.store.dispatch('loadExcelMappingList').finally(() => {
        this.eventHub.emit('stop-loading', 'transfer-load-from')
      }).then(() => {
        this.excelMappings = this.store.getters.getExcelMappingList
      })
  }

  async filterByUnitToggle() {
    console.log('entered FilterByUnitToggle')
    this.filterByUnit = !this.filterByUnit
    console.log(this.filterByUnit)
    if (!this.filterByUnit) {
      this.excelNonMappedKeys = this.originalExcelNonMappedKeys
      this.emfMasterdataList = this.originalEmfMasterdataList
      if (!this.isSelected(this.excelKeyToMap) && !this.isSelected(this.masterKeyToMap)) {
        return console.log('no sorting key selected. returned until key is selected')
      } else {
        return console.log('checkbox not checked, resetting lists, then returned')
      }
    }
    this.sortTablesBySelectedUnit()
  }

  sortTablesBySelectedUnit() {
    const excelKeyIsSelected = this.excelKeyToMap
    console.log(excelKeyIsSelected + ' <--excelKeyIsSelected Value')
    const masterKeyIsSelected = this.masterKeyToMap
    console.log(masterKeyIsSelected + ' <--masterKeyisSelected Value')

    if (!this.filterByUnit) return console.log('filterByUnit === false, so returned')

    if (excelKeyIsSelected) {
      this.sortedEmfMasterdataList()
    } else if (masterKeyIsSelected) {
      this.sortedExcelNonMappedKeys()
    }
  }

  sortEMFMasterData(column: string) {
    if (this.emfMasterdataSorting.column === column) {
      // If the same column is clicked, toggle the sorting order
      this.emfMasterdataSorting.order =
          this.emfMasterdataSorting.order === 'asc' ? 'desc' : 'asc';
    } else {
      // If a different column is clicked, set it as the new sorting column
      this.emfMasterdataSorting.column = column;
      this.emfMasterdataSorting.order = 'asc';
    }

    // Create a sorted copy of the array
    this.emfMasterdataList = [...this.emfMasterdataList].sort((a, b) => {
      const aValue = this.getEMFMasterdataColumnValue(a, column as keyof EMFMasterdata);
      const bValue = this.getEMFMasterdataColumnValue(b, column as keyof EMFMasterdata);

      if (aValue < bValue) return this.emfMasterdataSorting.order === 'asc' ? -1 : 1;
      if (aValue > bValue) return this.emfMasterdataSorting.order === 'asc' ? 1 : -1;
      return 0;
    });
  }

  sortExcelNonMappedKeys(column: string) {
    if (this.excelNonMappedKeysSorting.column === column) {
      // If the same column is clicked, toggle the sorting order
      this.excelNonMappedKeysSorting.order =
          this.excelNonMappedKeysSorting.order === 'asc' ? 'desc' : 'asc';
    } else {
      // If a different column is clicked, set it as the new sorting column
      this.excelNonMappedKeysSorting.column = column;
      this.excelNonMappedKeysSorting.order = 'asc';
    }

    // Create a sorted copy of the array
    this.excelNonMappedKeys = [...this.excelNonMappedKeys].sort((a, b) => {
      const aValue = this.getUnmappedExcelColumnValue(a, column as keyof ExcelRow);
      const bValue = this.getUnmappedExcelColumnValue(b, column as keyof ExcelRow);

      if (aValue < bValue) return this.excelNonMappedKeysSorting.order === 'asc' ? -1 : 1;
      if (aValue > bValue) return this.excelNonMappedKeysSorting.order === 'asc' ? 1 : -1;
      return 0;
    });
  }

  sortExcelMappings(column: string) {
    if (this.excelMappingsSorting.column === column) {
      // If the same column is clicked, toggle the sorting order
      this.excelMappingsSorting.order =
          this.excelMappingsSorting.order === 'asc' ? 'desc' : 'asc';
    } else {
      // If a different column is clicked, set it as the new sorting column
      this.excelMappingsSorting.column = column;
      this.excelMappingsSorting.order = 'asc';
    }

    // Create a sorted copy of the array
    try {
      this.excelMappings = [...this.excelMappings].sort((a, b) => {

        const extractNumber = (str: string) => parseInt(str.replace(/^EMF_/, ''), 10);
        const aValue = this.getExcelMappingsColumnValue(a, column as keyof ExcelMappingdata);
        const bValue = this.getExcelMappingsColumnValue(b, column as keyof ExcelMappingdata);
        const containsEMF = (value: string) => value.includes('EMF_');

        // Conditionally apply extractNumber if string contains 'EMF_' - else use normal a&b Value
        const numericA = containsEMF(aValue) ? extractNumber(aValue) : aValue;
        const numericB = containsEMF(bValue) ? extractNumber(bValue) : bValue;

        if (numericA < numericB) return this.excelMappingsSorting.order === 'asc' ? -1 : 1;
        if (numericA > numericB) return this.excelMappingsSorting.order === 'asc' ? 1 : -1;
        return 0;
      });
    } catch (error) {
      console.warn(error);
    }
  }

  sortedEmfMasterdataList() {
    console.log('entered sortedEmfMasterdataList')
    // Filter and sort the EMF Master Data based on the selected unit from selectableTable1.
    if (this.excelKeyToMap) {
      const selectedRow = this.excelNonMappedKeys.find((item) => item.key?.toLowerCase() === this.excelKeyToMap.toLowerCase());
      this.emfMasterdataList = this.originalEmfMasterdataList
      if (selectedRow) {
        const unitToFilterBy = selectedRow.unit?.toLowerCase();
        return this.emfMasterdataList = this.emfMasterdataList.filter((item) => item.unit?.toLowerCase() === unitToFilterBy?.toLowerCase());
      }
    }
    // If no row is selected in selectableTable1, return the original EMF Master Data.
    console.log('returned default')
    return this.emfMasterdataList = this.originalEmfMasterdataList;
  }

  sortedExcelNonMappedKeys() {
    console.log('entered sortedExcel')
    // Filter and sort the Excel Non-Mapped Keys based on the selected unit from selectableTable2.
    if (this.masterKeyToMap) {
      const selectedRow = this.emfMasterdataList.find((item) => item.key?.toLowerCase() === this.masterKeyToMap.toLowerCase());
      this.excelNonMappedKeys = this.originalExcelNonMappedKeys
      console.log(selectedRow)
      if (selectedRow) {
        const unitToFilterBy = selectedRow.unit?.toLowerCase();
        return this.excelNonMappedKeys = this.excelNonMappedKeys.filter((item) => item.unit?.toLowerCase() === unitToFilterBy?.toLowerCase());
      }
    }
    // If no row is selected in selectableTable2, return the original Excel Non-Mapped Keys.
    console.log('no row selected - returned NonMappedKeys = original')
    return this.excelNonMappedKeys = this.originalExcelNonMappedKeys
  }

  async addFile(e: Event) {
    const sheetName = this.sheetName
    const element = e.target as HTMLInputElement
    if (!this.excelFile) {
      //this.excelFile = {}
    }
    if (!this.excelFileName) {
      this.excelFileName = {}
    }
    if (!element.files) return
    this.excelFile = element.files[0]
    this.excelFileName.fileName = element.files[0].name
    this.excelFileName.fileType = element.files[0].type
    element.form?.reset()
    this.excelNonMappedKeys = (await emissionsfactorImportClient.getUnmappedExcelKeys(this.excelFile, sheetName)).data || []
    this.originalExcelNonMappedKeys = this.excelNonMappedKeys
  }

  isSelected(key: string): boolean {
    return this.selectedRows[key];
  }

  selectRowToMap(key: string, isTable1: boolean) {

    const keyToMap = key
    const isTypeExcelRow = isTable1

    // Prevents multiple marked row as selected
    if (keyToMap != this.excelKeyToMap && this.excelKeyToMap != '') {
      this.selectedRows[this.excelKeyToMap] = false;
    } else if (keyToMap != this.masterKeyToMap && this.masterKeyToMap != '') {
      this.selectedRows[this.masterKeyToMap] = false;
    }
    // Marks row as unselected, if clicked while already selected
    if (keyToMap === this.excelKeyToMap && this.selectedRows[keyToMap]) {
      this.selectedRows[keyToMap] = false // Mark the row as unselected
      this.excelKeyToMap = ''
      console.log('set unselected and this.excelKeyToMap = ' + this.excelKeyToMap + ' and then returned')
      return
    } else if (keyToMap === this.masterKeyToMap) {
      this.selectedRows[keyToMap] = false // Mark the row as unselected
      this.masterKeyToMap = ''
      console.log('set unselected and this.excelKeyToMap = ' + this.masterKeyToMap + ' and then returned')
      return
    }
    // Checks if KeyToMap is ExcelRow or MasterData row and marks / applies class accordingly
    if (keyToMap && isTypeExcelRow) {
      this.excelKeyToMap = keyToMap
      this.selectedRows[keyToMap] = true; // Mark the row as selected
      console.log('ExcelKeyToMap = ' + this.excelKeyToMap)
    } else if (keyToMap && !isTypeExcelRow) {
      this.masterKeyToMap = keyToMap
      this.selectedRows[keyToMap] = true; // Mark the row as selected
      console.log('masterKeyToMap = ' + this.masterKeyToMap)
    }

    // Push key-value pairs into the excelMappings array
    if (this.excelKeyToMap !== '' && this.masterKeyToMap !== '') {

      try {
        this.excelMappings.push({
          excelKey: this.excelKeyToMap,
          emfKey: this.masterKeyToMap,
        });
      } catch (error) {
        console.warn('Vuex Mutation Error')
      }
      // Remove the mapped item from the excelNonMappedKeys array
      const indexToRemove = this.excelNonMappedKeys.findIndex((item) => item.key === this.excelKeyToMap);
      if (indexToRemove !== -1) {
        this.excelNonMappedKeys.splice(indexToRemove, 1);
      }

      this.selectedRows[keyToMap] = false;
      console.log(JSON.stringify(this.excelMappings, null, 2))

      this.excelKeyToMap = ''
      this.masterKeyToMap = ''
    }
  }


  isSelectedForDeletion(id: number): boolean {
    return this.selectedRowsToDelete[id];
  }

  selectExcelMappingsRowToDelete(id: number) {
    const deletionIdtoAdd = id
    let mappingsTodelete = this.excelMappingsToDelete

    if (!deletionIdtoAdd) return

    if (deletionIdtoAdd && !this.selectedRowsToDelete[deletionIdtoAdd]) {
      this.selectedRowsToDelete[deletionIdtoAdd] = true;
      mappingsTodelete.push({id: deletionIdtoAdd})
    } else {
      this.selectedRowsToDelete[deletionIdtoAdd] = false;
      mappingsTodelete = mappingsTodelete.filter((item) => item.id !== deletionIdtoAdd);
    }
    console.log(JSON.stringify(mappingsTodelete))
    this.excelMappingsToDelete = mappingsTodelete
  }

  async deleteMapping() {
    for (const map of this.excelMappingsToDelete) {
      if (map.id) {
        try {
          await emissionsfactorImportClient.deleteExcelMapping(map.id);
        } catch (error) {
          console.error("Error making API request:", error);
        }
      }
    }
    //await this.getEMFMasterdata()
    await this.loadEMFMasterdata()
    console.log("delete3", this.excelFile, this.excelFile.name)
    if (this.excelFile.name !== undefined && this.excelFile.name !== '' && this.sheetName !== '') {
      this.excelNonMappedKeys = (await emissionsfactorImportClient.getUnmappedExcelKeys(this.excelFile, this.sheetName)).data || []
    }
    await this.loadExcelMappingdata()
  }


  async createMapping() {
    const mappingsToCreate = this.excelMappings;
    console.log(mappingsToCreate);

    for (const mapping of mappingsToCreate) {
      if (mapping.emfKey && mapping.excelKey && !mapping.id) {
        const encodedEmfKey = encodeURIComponent(mapping.emfKey);
        const encodedExcelKey = encodeURIComponent(mapping.excelKey);
        //console.log('masterKey--> ' + encodedEmfKey + ' && ' + 'excelKey--> ' + encodedExcelKey);
        try {
          await emissionsfactorImportClient.createExcelMapping(encodedEmfKey, encodedExcelKey);
        } catch (error) {
          console.error("Error making API request:", error);
        }
        //console.log('masterKey--> ' + encodedEmfKey + ' && ' + 'excelKey--> ' + encodedExcelKey);
      }
    }
    //await this.getEMFMasterdata();
    await this.loadEMFMasterdata();
    this.excelNonMappedKeys = (await emissionsfactorImportClient.getUnmappedExcelKeys(this.excelFile, this.sheetName)).data || [];
    await this.loadExcelMappingdata();
  }
  dummyUploadFile() {
    const uploadInput = document.getElementById(`map-fileinput`) as HTMLInputElement
    if (uploadInput) {
      uploadInput.click()
    }
  }

  async exportToSqlString() {
    let result = {}

    await emissionsfactorImportClient.exportMapping().then((response) => {
      if (response.data) {
        result = response.data
      }
    })
    const win = window.open('', 'sql string to export', 'height=600,width=800')
    if (win) {
      result.toString().replaceAll('\n', '<br>')
      win.document.write('<p>' + result.toString().replaceAll('\n', '<br>') + '</p>')
      win.alert('SQL til mapning af excel keys, skal typisk puttes i liquibase fil og bygges ind i release')
    }
  }

}
</script>

<style scoped>
form {
  margin-top: 24px;
}

.table--selectable td {
  white-space: normal;
  word-break: break-word;
}

.selected-row {
  background-color: #BFBFBF;
}

.calc-summary li:first-of-type {
  margin-top: 0;
}


</style>
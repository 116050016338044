<template>
  <div v-if="results.groupingResults" style="text-align: center">
    <p class="title">
      {{ title }}
    </p>
    <div v-bind:class="{ 'print-graph-container': printMode }">
      <canvas ref="chart"></canvas>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from "vue-property-decorator"
import Chart from "chart.js"
import "chartjs-plugin-labels"
import { prettify } from "@/shared/PrettifyNumbers"
import { GroupingCalculatedResults } from "openapi/generated-clients/climatecompass"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"


export default class BarChartGHG extends BaseComponent {
  @Prop()
  title?: string
  @Prop({ required: true })
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults
  @Prop()
  printMode?: boolean

  chart = {} as HTMLCanvasElement
  context: CanvasRenderingContext2D = {} as CanvasRenderingContext2D

  buildChart() {
    const dataLabels = ["Scope 1", "Scope 2", "Scope 3"]
    const data = [
      Number(this.formatNumber(this.results.ghgCalculatedResult?.scope1Result ?? 0)),
      Number(this.formatNumber(this.results.ghgCalculatedResult?.scope2Result ?? 0)),
      Number(this.formatNumber(this.results.ghgCalculatedResult?.scope3Result ?? 0))
    ]

    const animationOptions: Chart.ChartAnimationOptions | undefined = this.printMode ? { duration: 0 } : {
      duration: 1000,
      easing: "easeOutQuart"
    }

    /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
    this.context = this.chart?.getContext("2d")!
    new Chart(this.context, {      // NOSONAR
      type: "bar",
      data: {
        labels: dataLabels,
        datasets: [
          {
            data: data,
            backgroundColor: [this.constants.SCOPE1_COLOR_HEX, this.constants.SCOPE2_COLOR_HEX, this.constants.SCOPE3_COLOR_HEX, this.constants.OUTSIDE_SCOPES_COLOR_HEX],
            barPercentage: 0.5,
            categoryPercentage: 1
          }
        ]
      },
      options: {
        tooltips: { enabled: false },
        hover: { mode: undefined },
        animation: animationOptions,
        layout: {
          padding: {
            top: 20
          }
        },
        plugins: {
          labels: {
            render: function(args: any) {
              return prettify(args.value)
            },
            fontColor: "#000",
            fontSize: 11,
            textMargin: 5,
            position: "outside",
            segment: true,
            segmentColor: "auto"
          }
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.getContentfulString('shared.ton-co2e.no-format'),
              },
              gridLines: {
                borderDashOffset: 2,
                borderDash: [10, 8]
              },
              ticks: {
                callback: function(label, index, labels) {
                  return label !== 0 ? prettify(label) : "0"
                },
                fontSize: 14,
                maxTicksLimit: 8,
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
                drawOnChartArea: false
              }
            }
          ]
        }
      }
    })
  }

  mounted() {
    this.buildChart()
  }

  @Watch("results")
  watch(newValue: GroupingCalculatedResults, oldValue: GroupingCalculatedResults) {
    this.buildChart()
  }
}
</script>

<style scoped>
.title {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 550;
}
</style>

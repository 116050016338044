<template>
  <SharedHeader>
    <template #firstColumn>
      <h1>
        <SimpleText text-key="forecast.forecast-header-title"/>
      </h1>
      <span>
        <RichText text-key="forecast.forecast-header-text"/>
      </span>
      <span>
      <!--a :href="getContentfulString('forecast.guides.download-link.pdf-complete-guide')" rel="noopener" target="_blank" class="button button-primary"><SimpleText text-key="forecast.forecast-download-guide-button-text"/></a>
      <a :href="getContentfulString('forecast.guides.download-link.pdf-step-by-step-guide')" rel="noopener" target="_blank" class="button button-secondary"><SimpleText text-key="forecast.forecast-download-guide-button2-text"/></a-->
        <!-- convert from href to button due to SQ security errors -->
        <button
          type="button"
          class="button button-primary"
          @click="openLink(getContentfulString('forecast.guides.download-link.pdf-complete-guide'))"><SimpleText text-key="forecast.forecast-download-guide-button-text" />
        </button>
        <button
          type="button"
          class="button button-secondary"
          @click="openLink(getContentfulString('forecast.guides.download-link.pdf-step-by-step-guide'))"><SimpleText text-key="forecast.forecast-download-guide-button2-text" />
        </button>
      </span>
    </template>
  </SharedHeader>

  <div class="container px-0 py-9">
    <div class="forecast-guide-container">
      <h2>
        <SimpleText text-key="forecast.forecast.sub-title"/>
      </h2>
      <div class="forecast-guide-content-container">

        <div class="forecast-guide-content" id="content-box-one">
          <img class="forecast-img"
               src="https://images.eu.ctfassets.net/vhb8aakerf77/PUBJj7UjMS5mMUWPVC8DN/fe0cbb507507c87c35599d24306ad96e/Virksomhed_icon.svg"
               alt="">
          <div class="content-description">
            <h3 class="title-header">
              <SimpleText text-key="forecast.guides.content-one-description-header"/>
            </h3>
            <p class="content-description-text">
              <RichText text-key="forecast.guides.content-one-description-text"/>
            </p>
            <router-link to="/forecast/calculate-forecast" class="button button-primary col">
              <SimpleText text-key="forecast.forecast.button-link.name"/>
            </router-link>
          </div>
        </div>

        <div class="forecast-guide-content" id="content-box-two">
          <img class="forecast-img"
               src="https://images.eu.ctfassets.net/vhb8aakerf77/1gJe2mqRv9HxV7p1uP1CxJ/68acde8153ebd23b4d990e7168eaf58b/Overblik_ikon.svg"
               alt="">
          <div class="content-description">
            <h3 class="title-header">
              <SimpleText text-key="forecast.guides.content-two-description-header"/>
            </h3>
            <p class="content-description-text">
              <RichText text-key="forecast.guides.content-two-description-text"/>
            </p>
          </div>
        </div>

        <div class="forecast-guide-content" id="content-box-three">
          <img class="forecast-img"
               src="https://images.eu.ctfassets.net/vhb8aakerf77/4aR3mXN2seWHYNETOlDMs0/2a15d90ef6623201d99827d8bf9b8508/Dokument_ikon.svg"
               alt="">
          <div class="content-description">
            <h3 class="title-header">
              <SimpleText text-key="forecast.guides.content-three-description-header"/>
            </h3>
            <p class="content-description-text">
              <RichText text-key="forecast.guides.content-three-description-text"/>
            </p>
          </div>
        </div>

        <div class="forecast-guide-content" id="content-box-four">
          <img class="forecast-img"
               src="https://images.eu.ctfassets.net/vhb8aakerf77/5dOVjeJfDZYv9n8nohW44f/47e843d6c06eea2c3a9c06a2a4396fff/Excel_icon.png"
               alt="">
          <div class="content-description">
            <h3 class="title-header">
              <SimpleText text-key="forecast.guides.content-four-description-header"/>
            </h3>
            <p class="content-description-text">
              <RichText text-key="forecast.guides.content-four-description-text"/>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {documentToHtmlString} from "@contentful/rich-text-html-renderer"
import {Options} from "vue-class-component"
import Navigation from "@/components/forecast/Navigation.vue"
import {useRouter} from "vue-router";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import RichText from "@/components/RichText.vue";
import SimpleText from "@/components/SimpleText.vue";
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue";
import DOMPurify from 'dompurify'


@Options({
  methods: { DOMPurify },
  components: {
    RichText,
    SimpleText,
    Navigation,
    SharedHeader
  }
})
export default class ForecastGuides extends BaseComponent {
  router = useRouter()

  get documentToHtmlString() {
    return documentToHtmlString
  }

  openLink(url: string) {
    window.open(url)
  }

}

</script>

<style scoped>

.forecast-guide-content-container {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

}

.forecast-guide-content {
  display: inline-flex;
  padding-bottom: 0;
  margin-bottom: 60px;

}

.title-header {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;

}

.content-description {
  padding-left: 30px;
  flex: 1;
}

.forecast-img {
  flex: 1;
  max-width: 13%;
  max-height: 15%;

}

.button-primary {
  padding-right: 10px;
  margin-right: 10px;
}

.button-secondary {
  padding-left: 10px;
  margin-left: 10px;
}


</style>
<template>
  <SharedHeader>
    <template #firstColumn>
      <h1>
        <SimpleText text-key="comparison.comparison-overview.title"/>
      </h1>
      <span><RichText text-key="comparison.comparison-overview.header-description"/></span>
    </template>
    <template #secondColumn>
      <div v-if="false" class="flex flex-lg-row flex-column result-buttons column-row-gap-10">
        <div class="result-buttons__button-group flex">
          <a
              id="result-export-excel-link"
              href="javascript:void(0)"
              class="button button-secondary"
              @click="exportComparisonToExcel"
              :data-cy-id="'klimakompasset-resultat-sammenligninger'"
          >
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#file-download"></use>
            </svg>
            <SimpleText text-key='comparison.excel.button.download-title'/>
          </a>
          <Tooltip :style="`vertical-align: middle;`" :textKey="'comparison.download-excel-tooltip'" size="lg"/>
        </div>

        <!-- sonar-ignore -->
        <div class='result-buttons__button-group'>
          <a
              id='comparison-download-pdf-button'
              class="button button-secondary flex-grow-equally"
              role="button"
              target="_blank"
              @click.prevent="handleDownloadPdf"
              onkeydown="handleKeyDown()"
              tabindex="0"
          >
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#file-download"></use>
            </svg>
            <SimpleText text-key='comparison.pdf.button.download-title'/>
          </a>
        </div>
      </div>
    </template>
  </SharedHeader>

  <div class="container px-0 py-9">
    <h2 class="full-width flex center">
      <SimpleText text-key="comparison.comparison-overview.table-header"/>
    </h2>
    <ComparisonOverviewTableComponent
        id="comparisons"
        ref="comparisonTable"
        :comparisons="combinedInfo"
        @delete-comparison="confirmDeleteComparison($event)"
        @edit-comparison="editComparison($event)"
    />

  </div>

  <!-- Delete confirmation modal START-->
  <template v-if="comparisonToDelete.id && comparisonToDelete.id > 0">
    <AcceptModal
        :value-object="comparisonToDelete"
        :title-key="'comparison.dialog.delete.title'"
        :buttons-disabled="disableDeleteModalButtons"
        :button-ok-text-key="'comparison.dialog.delete.button.ok'"
        :button-reject-text-key="'comparison.dialog.delete.button.cancel'"
        @select-ok="deleteComparison($event)"
        @select-reject="resetComparisonToDelete()"
    >
      <p>
        <strong><SimpleText :textKey="'comparison.dialog.delete.text1'" /></strong>
        <br/>
        <br/>
        {{ comparisonToDelete.name }}
        <br/>
        <br/>
        <SimpleText :textKey="'comparison.dialog.delete.text2'" />
      </p>
    </AcceptModal>
  </template>
  <!-- Delete confirmation modal END-->

</template>

<script lang="ts">

import {Vue, Options} from 'vue-property-decorator';
import ComparisonOverviewTableComponent from '@/components/comparison/ComparisonOverviewTableComponent.vue'
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import SimpleText from "@/components/SimpleText.vue";
import Tooltip from "@/components/Tooltip.vue";
import RichText from "@/components/RichText.vue";
import {ComparisonOverviewInfo} from "../../../openapi/generated-clients/climatecompass";
import {useRouter} from "vue-router";
import AcceptModal from "@/components/AcceptModal.vue";
import ForecastOverviewTableComponent from "@/components/forecast/ForecastOverviewTableComponent.vue";

@Options({
  components: {
    ForecastOverviewTableComponent,
    AcceptModal, ComparisonOverviewTableComponent, SimpleText, Tooltip, RichText, SharedHeader}
})
export default class ComparisonOverview extends BaseComponent {
  router = useRouter()
  combinedInfo: ComparisonOverviewInfo[] = []
  disableDeleteModalButtons = false
  comparisonToDelete: ComparisonOverviewInfo = {} as ComparisonOverviewInfo


  async beforeMount() {
    this.eventHub.emit("start-loading", "ComparisonOverview")
    await this.store.dispatch("loadComparisons").finally(() => {
      this.eventHub.emit("stop-loading", "ComparisonOverview")
    })
    await this.getComparisons()
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault()

      const target = event.target as HTMLElement;
      if (target && target.click) {
        target.click();
      }
    }
  }

  handleDownloadPdf() {
    window.print()
  }

  resetComparisonToDelete() {
    this.comparisonToDelete = {} as ComparisonOverviewInfo
  }

  async getComparisons(){
    const result: ComparisonOverviewInfo[] = []
    if (this.comparisons) {
      await Promise.all(this.comparisons.map(async (forecast: ComparisonOverviewInfo) => {
        if (forecast.id) {
          const combined = { ...forecast } as ComparisonOverviewInfo
          result.push(combined)
        }
      }))
    }
    this.combinedInfo = result
    //console.log(JSON.stringify(this.combinedInfo, null, 2))
  }

  get comparisons() {
    return this.store.getters.getComparisons
  }

  exportComparisonToExcel() {
    console.log('Functionality not yet implemented - Sorry! :( ')
  }

  async editComparison(comId: ComparisonOverviewInfo) {
    await this.router.push('create-comparison?mode=edit&comparisonId=' + comId?.id)
    /* works local but not on slow dev
    await this.store.dispatch("setCurrentForecastInfo", forecastInfo).then(async () => {
      await this.router.push({name: 'CalculateForecast', params: {mode: 'edit' }})
    })
     */
  }

  async deleteComparison(comparisonInfo?: ComparisonOverviewInfo) {
    if (!comparisonInfo) {
      return
    }
    this.disableDeleteModalButtons = true
    this.eventHub.emit("start-loading", "ComparisonOverview")
    await this.store.dispatch("deleteComparison", comparisonInfo)
    await this.store.dispatch("loadComparisons").finally(() => {
      this.disableDeleteModalButtons = false
      this.eventHub.emit("stop-loading", "ComparisonOverview")
    })
    this.comparisonToDelete = {} as ComparisonOverviewInfo
    const message = this.getContentfulString('comparison.create.delete-button.toast-text')
      .replace('#1#', comparisonInfo.name)
    this.addToast('deleteComparisonToast', 'success', this.getContentfulString('comparison.create.delete-button.toast-title'), message, true, false, true, true)
    await this.getComparisons()
  }

  confirmDeleteComparison(comparisonInfo: ComparisonOverviewInfo) {
    this.comparisonToDelete = comparisonInfo
  }


}
</script>

<style scoped>

</style>
<template>
  <div style="text-align: center">
    <p class="title">
      {{ title }}
    </p>
  </div>
  <div v-if="resultIsNegative" class="alert alert-success">
    <div class="alert-body">
      <p v-if="negativeResultHeader" class="alert-heading" v-dompurify-html="negativeResultHeader"></p>
      <p v-if="negativeResultText" class="alert-text" v-dompurify-html="negativeResultText"></p>
    </div>
  </div>

  <div
    v-if="results.groupingResults && !resultIsNegative"
    :style="dataSet.length > 3 ? 'padding-top: 18px;' : ''"
    style="margin: 0 auto;"
    v-bind:class="{ 'print-graph-container': printMode }"
  >
    <canvas ref="chart"></canvas>
  </div>
</template>

<script lang="ts">
import Chart from "chart.js"
import "chartjs-plugin-labels"
import { GroupingCalculatedResults } from "openapi/generated-clients/climatecompass"
import { prettify } from "@/shared/PrettifyNumbers"
import { Prop, Watch } from "vue-property-decorator"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"

interface PieChartDataSet {
  label: string
  value: number
}

export default class PieChart extends BaseComponent {
  @Prop()
  title?: string
  @Prop({ required: true })
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults
  @Prop()
  showOnlyScopes?: boolean
  @Prop()
  printMode?: boolean
  @Prop()
  negativeResultHeader?: string
  @Prop()
  negativeResultText?: string

  chart = {} as HTMLCanvasElement
  context: CanvasRenderingContext2D = {} as CanvasRenderingContext2D

  resultIsNegative = false
  dataSet: PieChartDataSet[] = []

  buildChartScope1() {
    if (this.results.ghgCalculatedResult?.scope1Result && this.results?.ghgCalculatedResult?.totalResult) {
      const scope1Share = (this.results.ghgCalculatedResult.scope1Result * 100) / this.results.ghgCalculatedResult.totalResult

      Number(scope1Share) < 0
        ? (this.resultIsNegative = true)
        : this.dataSet.push({
          label: `Scope 1 (${prettify(scope1Share.toFixed(1))}%)`,
          value: Number(scope1Share)
        })
    }
  }

  buildChartScope2() {
    if (this.results.ghgCalculatedResult?.scope2Result && this.results?.ghgCalculatedResult?.totalResult) {
      const scope2Share = (this.results.ghgCalculatedResult.scope2Result * 100) / this.results.ghgCalculatedResult.totalResult

      Number(scope2Share) < 0
        ? (this.resultIsNegative = true)
        : this.dataSet.push({
          label: `Scope 2 (${prettify(scope2Share.toFixed(1))}%)`,
          value: Number(scope2Share)
        })
    }
  }

  buildChartScope3() {
    if (this.results.ghgCalculatedResult?.scope3Result && this.results?.ghgCalculatedResult?.totalResult) {
      const scope3Share = (this.results.ghgCalculatedResult.scope3Result * 100) / this.results.ghgCalculatedResult.totalResult

      Number(scope3Share) < 0
        ? (this.resultIsNegative = true)
        : this.dataSet.push({
          label: `Scope 3 (${prettify(scope3Share.toFixed(1))}%)`,
          value: Number(scope3Share)
        })
    }
  }

  buildChart() {
    if (this.results.totalResult) {
      this.buildChartScope1()
      this.buildChartScope2()
      this.buildChartScope3()
    }
    if (this.resultIsNegative) {
      return
    }

    const animationOptions: Chart.ChartAnimationOptions | undefined = this.printMode ? { duration: 0 } : {
      duration: 1000,
      easing: "easeOutQuart"
    }

    /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
    this.context = this.chart?.getContext("2d")!
    const chartObj = new Chart(this.context, {
      type: "pie",
      data: {
        labels: this.dataSet.map((e) => e.label),
        datasets: [
          {
            data: this.dataSet.map((e) => e.value),
            backgroundColor: [this.constants.SCOPE1_COLOR_HEX, this.constants.SCOPE2_COLOR_HEX, this.constants.SCOPE3_COLOR_HEX, this.constants.OUTSIDE_SCOPES_COLOR_HEX],
            borderColor: "#fff",
            borderWidth: 3,
            borderAlign: "center",
            hoverBorderColor: "#fff"
          }
        ]
      },

      options: {
        tooltips: { enabled: false },
        hover: { mode: undefined },
        animation: animationOptions,
        plugins: {
          labels: {
            render: function() {
              return ""
            }
          }
        },

        legend: {
          labels: {
            padding: 13,
            boxWidth: 13,
            fontSize: 12.5
          },
          position: "bottom"
        }
      }
    })
  }

  mounted() {
    this.buildChart()
  }

  @Watch("results")
  watchResults() {
    this.buildChart()
  }
}
</script>

<style scoped>
.title {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 550;
}
</style>

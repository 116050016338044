<template id="sharedHeader">
  <div v-if="showCalculationRow" class="border-bottom">
    <div class="container px-0">
      <div class="row py-405">
        <div class="col-3">
          <h6 class="mt-0"><SimpleText text-key="sharedContent.calculation"/></h6>
          <h5 class="mt-0">{{ calculation.calculationPurpose?.name }}</h5>
        </div>

        <div class="col-3">
          <h6 class="mt-0"><SimpleText text-key="sharedContent.period"/></h6>
          <h5
            v-if="calculation.calculationPurpose?.startDate"
            :aria-label="formatDates(calculation.calculationPurpose.startDate, calculation.calculationPurpose.endDate || getContentfulString(Constants.missingContent))"
            class="mt-0"
            v-dompurify-html="formatDates(calculation.calculationPurpose.startDate, calculation.calculationPurpose.endDate || getContentfulString(Constants.missingContent))"
          >
            {{ formatDates(calculation.calculationPurpose.startDate, calculation.calculationPurpose.endDate) || getContentfulString(Constants.missingContent) }}
          </h5>
        </div>
        <div class="col-3">
          <h6 v-if="this.country2" class="mt-0">
            <SimpleText text-key="sharedContent.country"/>
          </h6>
          <h5 v-if="countryName" class="mt-0" aria-live="polite">{{ countryName }}</h5>
          <h5 v-else-if="country" class="mt-0">{{ country }}</h5>
        </div>
        <div class="col-3">
          <h6 v-if="calculation.emissionCoefficientsVersion?.year" class="mt-0">
            {{ emissionCoefficientTitle }}
          </h6>
          <h5 v-if="calculation.emissionCoefficientsVersion?.year" class="mt-0">{{ this.calculation.emissionCoefficientsVersion.year }}</h5>
        </div>
      </div>
    </div>
  </div>

  <!--div v-if="tabMenu && tabMenu.name" :class="'bg-alternative border-bottom ' + additionalBackgroundCssClass" -->
  <div :class="'bg-alternative border-bottom ' + additionalBackgroundCssClass" >
    <div :id="'tabs-container-'+ generateUniqueId" class="container px-0">
      <div :class="'row ' + getHeaderPadding">
        <template v-if="useOneColumn">
          <slot name="firstColumn"></slot>
        </template>
        <template v-else>
          <div class="col-7">
            <slot name="firstColumn"></slot>
          </div>
          <div class="col-5">
            <slot name="secondColumn"></slot>
          </div>
        </template>
      </div>
      <div v-if="tabMenu && tabMenu.name !== 'MasterDataTabs'" :class="'tabnav-kk d-print-none ' + additionalCssClass">
        <router-link v-for="tabItem in tabMenu.menuItems" :key="tabItem.link" :data-cy-name="tabItem.label"
                     :class="active(tabItem.link)" :to="{path: tabItem.link, hash: '#tabs'}">
          <span>{{ tabItem.label }}</span>
        </router-link>
      </div>
      <div v-if="tabMenu && tabMenu.name === 'MasterDataTabs'" :class="'tabnav-kk d-print-none ' + additionalCssClass">
        <span v-for="tabItem in tabMenu.menuItems" :key="tabItem.link" style="display: contents">
          <router-link v-if="tabItem.id === 'purpose'" :class="active(tabItem.link)"
                       :to="tabItem.link" :data-cy-name="tabItem.label">
            <span>{{ tabItem.label }}</span>
          </router-link>
          <router-link
            v-else-if="tabItem.id === 'company-information' && calculation.calculationPurpose?.name"
            :class="active(tabItem.link)"
            :to="tabItem.link"
          >
            <span>{{ tabItem.label }}</span>
          </router-link>
          <a v-else aria-disabled="true" class="bg-primary disabled">
            {{ tabItem.label }}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { formatDates } from "@/shared/DateFormatHelper"
import { Country } from "openapi/generated-clients/climatecompass"
import { useRoute } from "vue-router"
import { useStore } from "vuex"
import { countryClient } from "@/shared/BackendService"
import {Options, Vue} from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { isAuthenticated } from "@/store/getters"
import { Menu } from "@/shared/MenuStructure"
import SimpleText from "@/components/SimpleText.vue";
import {Constants} from "@/shared/Constants";

@Options({
  computed: {
    Constants() {
      return Constants
    }
  },
  components: {SimpleText } })
export default class SharedHeader extends Vue {
  @Prop()
  showCalculationRow?: boolean

  @Prop()
  tabMenu?: Menu

  @Prop()
  headerPadding = "py-8"

  @Prop({ required: false })
  country: string | undefined

  @Prop()
  additionalCssClass = ''

  @Prop()
  additionalBackgroundCssClass = ''

  @Prop()
  useOneColumn = false

  store = useStore()
  route = useRoute()
  countryName = ""
  emissionCoefficientTitle = this.getContentfulString('shared.header-emission-title')
  get getHeaderPadding() {
    return this.headerPadding ?? 'py-8'
  }
  // needed else error in intellij
  get formatDates() {
    return formatDates
  }

  get calculation() {
    return this.store.getters.getCurrentCalculation
  }

  getContentfulString(contentfulKey: string) {
    return this.store.getters.getContentfulContent.findSimpleText(contentfulKey)
  }

  active(path: string) {
    return this.route.path === path ? "active-tab" : "bg-primary text-color-white"
  }

 get generateUniqueId() {
    let id = 1
    if(document.getElementById('tabs-container-' + id)) {
        id++
    }
      return id
  }

  async beforeMount() {
    if(!isAuthenticated()) return
    const countries = await countryClient.fetchCountries()
    if (countries.data.countries) {
      this.countryName =
          countries.data.countries.find((country: Country) => {
            return country.code === this.calculation.companyInformation?.country
          })?.name || ""
    }
  }

  get country2() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.country')
  }

}
</script>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid #bfbfbf;
}

.tabnav-kk {
  margin: 0;
  list-style-type: none;
  padding: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.tabnav-kk a {
  box-sizing: inherit;
  border: none;
  border-top: 4px solid #f5f5f5;
  background-image: none;
  display: inline-block;
  width: auto;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border-radius: 0;
  line-height: normal;
  padding: 8px 24px;
  margin: 0 4px 4px 0;
}

.active-tab {
  color: black !important;
  background-color: #fff !important;
  background-image: none;
  border: 1px solid $color-border-grey !important;
  border-bottom: 1px solid #fff !important;
  border-radius: 0;
  text-decoration: none !important;
  margin: 0 4px -1px 0 !important;
  padding-top: 11px !important;
  position: relative;
  z-index: 350;
}
</style>
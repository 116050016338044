<template>
  <!--Note: Double SharedHeader is intentional here. For reference see: KK2-1703 -->
  <SharedHeader :showCalculationRow="true" :use-one-column="true" :header-padding="0">
    <template #firstColumn>
    </template>
  </SharedHeader>
  <SharedHeader :tabMenu="menu" showCalculationRow>
    <template #firstColumn>
      <div>
        <h1 class="h1">
          <SimpleText text-key="results.headerTitle"/>
          {{ calculation.calculationPurpose.name }}:
          <br />
          <span class="h2">
            {{ doPrettify(this.totalEmission) }}
            <span v-dompurify-html='getContentfulString("results.tonCo2")'></span>
          </span>
        </h1>
      </div>
    </template>
    <template #secondColumn>
      <div class="flex flex-lg-row flex-column result-buttons">
        <div class="result-buttons__button-group flex">
          <router-link
              id="`result-export-excel-link`"
              role="button"
              to="#"
              class="button button-secondary"
              @click="exportToExcel"
              onkeydown="handleKeyDown()"
          >
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#file-download"></use>
            </svg>
            <SimpleText text-key="results.downloadExcelButtonText"/>
          </router-link>
          <Tooltip :style="`vertical-align: middle;`" :text="this.downloadExcelTooltip" size="lg" />
        </div>

        <!-- sonar-ignore -->
        <div class="result-buttons__button-group flex">
          <router-link
            :id="`result-print-link`"
            :to="`/print-results/${calculation.id}/${thirdpartyInfo.behalfOfVatNr}`"
            class="button button-secondary"
            role="button"
            target="_blank"
            onkeydown="handleKeyDown()"
          >
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#file-download"></use>
            </svg>
            <SimpleText text-key="results.printButtonText"/>
          </router-link>
          <Tooltip :style="`vertical-align: middle;`" :text="this.printTooltip" size="lg" />
        </div>
      </div>
    </template>
  </SharedHeader>

  <div class="container">
    <div class="row py-6">
      <div class="col-12 px-0 print-exact-color">
        <MethodSelection :results="results" @methodSelectionChanged="doRecalculate" @methodSelectionChangedRFI="doRecalculateRFI"/>
        <router-view :results="results" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue"
import { Menu } from "@/shared/MenuStructure"
import {Calculate, GroupingCalculatedResults} from "openapi/generated-clients/climatecompass"
import { calculateClient, calculationClient } from "@/shared/BackendService"
import { downloadFile } from "@/shared/FileHelper"
import MethodSelection from "@/components/calculator/results/MethodSelection.vue"
import { Options } from "vue-class-component"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import _ from "lodash";
import SimpleText from "@/components/SimpleText.vue";
import RichText from "@/components/RichText.vue";

@Options({
  components: {
    SimpleText,
    RichText,
    SharedHeader,
    MethodSelection
  }
})
export default class Results extends BaseComponent {
  menu: Menu = {} as Menu

  results: GroupingCalculatedResults = {} as GroupingCalculatedResults
  totalEmission = ""

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault()

      const target = event.target as HTMLElement;
      if (target && target.click) {
        target.click();
      }
    }
  }

  async getCalculation(calculationId: number, isLocationBased: boolean, doReCalc = false) {
    if (doReCalc) {
      // when called from the toggleswitch we need to recalculate. Inspired from the GenericCalculator code
      await calculateClient.declarationCalculations(calculationId, isLocationBased, 'null')
      const newCalculation = { ...this.calculation, electricityEnvironmentDeclaration: isLocationBased }
      await this.store.dispatch("setCalculation", newCalculation)
    }
    this.results = (await calculationClient.getResultCalculations(calculationId, this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null')).data || {}
    if (this.results.groupingResults && this.results.groupingResults.length >= 2) {
      this.results.groupingResults?.sort((a, b) => {
        return a.groupingId! - b.groupingId!
      })
    }
    this.totalEmission = this.results
      .groupingResults!.reduce((sum: number, cur: any) => sum + Number(cur.calculatedResult?.scopesResult), 0)
      .toFixed(2)
  }

  get thirdpartyInfo() {
    return this.store.getters.getThirdpartyInfo
  }

  async getCalculationRFI(calculationId: number, isRFI: boolean, doReCalc = false) {
    if (doReCalc) {
      await calculateClient
          .rfiCalculations(this.calculation.id, isRFI, 'null')
          .then(() => {
            this.calculation.rfi = isRFI
            this.store.dispatch("setCalculation", this.calculation)
          })
          .catch((error) => {
            console.warn("[Advarsel] getCalculationRFI fejl: " + error)
          })
    }
    this.results = (await calculationClient.getResultCalculations(calculationId, this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null')).data || {}
    if (this.results.groupingResults && this.results.groupingResults.length >= 2) {
      this.results.groupingResults?.sort((a, b) => {
        return a.groupingId! - b.groupingId!
      })
    }
    this.totalEmission = this.results
        .groupingResults!.reduce((sum: number, cur: any) => sum + Number(cur.calculatedResult?.scopesResult), 0)
        .toFixed(2)
  }

  async doRecalculate(event: any) {
    // toogleswitch was changed to this method is triggered
    await this.getCalculation(event.calculationId, event.isLocationBased, true)
  }

  async doRecalculateRFI(event: any) {
    // toogleswitch was changed to this method is triggered
    await this.getCalculationRFI(event.calculationId, event.isRFI, true)
  }

  async exportToExcel(event: any) {
    const countryCode = this.useTextKeys ? 'TE' : (this.store.getters.getLocale === 'da' ? 'DK' : 'US')
    const env = document.location.host.split(".")[0]
    const response = await calculationClient.exportToExcel(this.calculation.id!, this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null', countryCode, env, { responseType: "blob" })
    const blob = new Blob([response.data], { type: response.headers["content-type"] })
    // due to CORS we cannot access Content-Disposition header here, so we rebuild filename
    const filename = `klimakompasset-resultat-${this.calculation.calculationPurpose.name}.xlsx`
    downloadFile(blob, filename)
  }

  async beforeMount() {
    if (!this.calculation?.id) {
      this.$router.push("/overview") // Hop direkte til oversigts-siden, hvis der ikke er valgt en beregning, da der så ikke findes beregningsresultater.
    } else {
      this.menu = this.store.getters.getMenuStructure.resultTabs
      await this.getCalculation(this.calculation.id!, false, false)
    }
  }

  get printTooltip() {
    return this.store.getters.getContentfulContent.findSimpleText('results.printTooltip')
  }

  get downloadExcelTooltip() {
    return this.store.getters.getContentfulContent.findSimpleText('results.downloadExcelTooltip')
  }

}
</script>

<style lang="scss" scoped>

.result-buttons {
  @include grid-gap(5);

  & > * {
    flex: 1;
    flex-basis: 0;
    min-width: 0;
  }

  &__button-group .button {
    flex: 1;
    flex-basis: 0;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>

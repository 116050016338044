import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, normalizeClass as _normalizeClass, renderList as _renderList, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63fc0234"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "border-bottom"
}
const _hoisted_2 = { class: "container px-0" }
const _hoisted_3 = { class: "row py-405" }
const _hoisted_4 = { class: "col-3" }
const _hoisted_5 = { class: "mt-0" }
const _hoisted_6 = { class: "mt-0" }
const _hoisted_7 = { class: "col-3" }
const _hoisted_8 = { class: "mt-0" }
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = { class: "col-3" }
const _hoisted_11 = {
  key: 0,
  class: "mt-0"
}
const _hoisted_12 = {
  key: 1,
  class: "mt-0",
  "aria-live": "polite"
}
const _hoisted_13 = {
  key: 2,
  class: "mt-0"
}
const _hoisted_14 = { class: "col-3" }
const _hoisted_15 = {
  key: 0,
  class: "mt-0"
}
const _hoisted_16 = {
  key: 1,
  class: "mt-0"
}
const _hoisted_17 = ["id"]
const _hoisted_18 = { class: "col-7" }
const _hoisted_19 = { class: "col-5" }
const _hoisted_20 = {
  key: 2,
  "aria-disabled": "true",
  class: "bg-primary disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showCalculationRow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h6", _hoisted_5, [
                  _createVNode(_component_SimpleText, { "text-key": "sharedContent.calculation" })
                ]),
                _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.calculation.calculationPurpose?.name), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h6", _hoisted_8, [
                  _createVNode(_component_SimpleText, { "text-key": "sharedContent.period" })
                ]),
                (_ctx.calculation.calculationPurpose?.startDate)
                  ? _withDirectives((_openBlock(), _createElementBlock("h5", {
                      key: 0,
                      "aria-label": _ctx.formatDates(_ctx.calculation.calculationPurpose.startDate, _ctx.calculation.calculationPurpose.endDate || _ctx.getContentfulString(_ctx.Constants.missingContent)),
                      class: "mt-0"
                    }, [
                      _createTextVNode(_toDisplayString(_ctx.formatDates(_ctx.calculation.calculationPurpose.startDate, _ctx.calculation.calculationPurpose.endDate) || _ctx.getContentfulString(_ctx.Constants.missingContent)), 1)
                    ], 8, _hoisted_9)), [
                      [_directive_dompurify_html, _ctx.formatDates(_ctx.calculation.calculationPurpose.startDate, _ctx.calculation.calculationPurpose.endDate || _ctx.getContentfulString(_ctx.Constants.missingContent))]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_10, [
                (this.country2)
                  ? (_openBlock(), _createElementBlock("h6", _hoisted_11, [
                      _createVNode(_component_SimpleText, { "text-key": "sharedContent.country" })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.countryName)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_12, _toDisplayString(_ctx.countryName), 1))
                  : (_ctx.country)
                    ? (_openBlock(), _createElementBlock("h5", _hoisted_13, _toDisplayString(_ctx.country), 1))
                    : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_14, [
                (_ctx.calculation.emissionCoefficientsVersion?.year)
                  ? (_openBlock(), _createElementBlock("h6", _hoisted_15, _toDisplayString(_ctx.emissionCoefficientTitle), 1))
                  : _createCommentVNode("", true),
                (_ctx.calculation.emissionCoefficientsVersion?.year)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_16, _toDisplayString(this.calculation.emissionCoefficientsVersion.year), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass('bg-alternative border-bottom ' + _ctx.additionalBackgroundCssClass)
    }, [
      _createElementVNode("div", {
        id: 'tabs-container-'+ _ctx.generateUniqueId,
        class: "container px-0"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass('row ' + _ctx.getHeaderPadding)
        }, [
          (_ctx.useOneColumn)
            ? _renderSlot(_ctx.$slots, "firstColumn", { key: 0 }, undefined, true)
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_18, [
                  _renderSlot(_ctx.$slots, "firstColumn", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _renderSlot(_ctx.$slots, "secondColumn", {}, undefined, true)
                ])
              ], 64))
        ], 2),
        (_ctx.tabMenu && _ctx.tabMenu.name !== 'MasterDataTabs')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass('tabnav-kk d-print-none ' + _ctx.additionalCssClass)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabMenu.menuItems, (tabItem) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  key: tabItem.link,
                  "data-cy-name": tabItem.label,
                  class: _normalizeClass(_ctx.active(tabItem.link)),
                  to: {path: tabItem.link, hash: '#tabs'}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(tabItem.label), 1)
                  ]),
                  _: 2
                }, 1032, ["data-cy-name", "class", "to"]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.tabMenu && _ctx.tabMenu.name === 'MasterDataTabs')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass('tabnav-kk d-print-none ' + _ctx.additionalCssClass)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabMenu.menuItems, (tabItem) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: tabItem.link,
                  style: {"display":"contents"}
                }, [
                  (tabItem.id === 'purpose')
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        class: _normalizeClass(_ctx.active(tabItem.link)),
                        to: tabItem.link,
                        "data-cy-name": tabItem.label
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(tabItem.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "to", "data-cy-name"]))
                    : (tabItem.id === 'company-information' && _ctx.calculation.calculationPurpose?.name)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          class: _normalizeClass(_ctx.active(tabItem.link)),
                          to: tabItem.link
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(tabItem.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["class", "to"]))
                      : (_openBlock(), _createElementBlock("a", _hoisted_20, _toDisplayString(tabItem.label), 1))
                ]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ], 8, _hoisted_17)
    ], 2)
  ], 64))
}
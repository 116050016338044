import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container py-9" }
const _hoisted_2 = { class: "mt-5" }
const _hoisted_3 = { class: "full-width" }
const _hoisted_4 = { class: "py-5" }
const _hoisted_5 = { for: "consent-checkbox" }
const _hoisted_6 = { class: "py-5" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, [
      _createVNode(_component_SimpleText, { "text-key": "acceptterms.title" })
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
      [_directive_dompurify_html, _ctx.documentToHtmlString(this.text)]
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("input", {
        id: "consent-checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userAccepted) = $event)),
        class: "form-checkbox checkbox-large",
        name: "checkbox-large[]",
        type: "checkbox"
      }, null, 512), [
        [_vModelCheckbox, _ctx.userAccepted]
      ]),
      _createElementVNode("label", _hoisted_5, [
        _createElementVNode("strong", null, [
          _createVNode(_component_SimpleText, { "text-key": "acceptterms.acceptTerms" })
        ])
      ]),
      _createVNode(_component_router_link, {
        to: `/terms-for-use`,
        target: "_blank"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SimpleText, { "text-key": "acceptterms.acceptTermsLink" })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        id: "submit-consent",
        disabled: !_ctx.userAccepted,
        class: "button button-primary px-8",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.acceptTerms()))
      }, [
        _createVNode(_component_SimpleText, { "text-key": "acceptterms.acceptButton" })
      ], 8, _hoisted_7)
    ])
  ]))
}
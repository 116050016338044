import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7509181c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"text-align":"center"}
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { ref: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.results.groupingResults)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", {
          class: _normalizeClass({ 'print-graph-container': _ctx.printMode })
        }, [
          _createElementVNode("canvas", _hoisted_3, null, 512)
        ], 2)
      ]))
    : _createCommentVNode("", true)
}
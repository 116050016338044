import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8a774be"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["aria-controls", "textContent"]
const _hoisted_3 = ["id"]
const _hoisted_4 = {
  key: 0,
  class: "accordion-image"
}
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentAccordions, (item) => {
    return (_openBlock(), _createElementBlock("ul", {
      key: item.title,
      class: "accordion"
    }, [
      _createElementVNode("li", null, [
        (item.title)
          ? _withDirectives((_openBlock(), _createElementBlock("h3", {
              key: 0,
              class: "d-print-only",
              "aria-label": item.title
            }, [
              _createTextVNode(_toDisplayString(item.title), 1)
            ], 8, _hoisted_1)), [
              [_directive_dompurify_html, item.title]
            ])
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          "aria-controls": item.title.replaceAll(" ", "_") + _ctx.uniqueId ?? "",
          class: "accordion-button d-print-none",
          textContent: _toDisplayString(item.title)
        }, null, 8, _hoisted_2),
        _createElementVNode("div", {
          id: item.title.replaceAll(" ", "_") + _ctx.uniqueId ?? "",
          "aria-hidden": "true",
          class: "accordion-content d-print-block"
        }, [
          _withDirectives(_createElementVNode("p", null, null, 512), [
            [_directive_dompurify_html, _ctx.documentToHtmlString(item.description)]
          ]),
          (item.url)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("img", {
                  src: item.url,
                  alt: item.description
                }, null, 8, _hoisted_5)
              ]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_3)
      ])
    ]))
  }), 128))
}
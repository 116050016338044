import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-031519e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container px-0 pb-6" }
const _hoisted_2 = { class: "w-percent-100 w-percent-100-child" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_AccordionComponent = _resolveComponent("AccordionComponent")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, [
      _createVNode(_component_SimpleText, { "text-key": "cookie.header.title" })
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [
      [_directive_dompurify_html, _ctx.getRenderedContentfulRT('cookie.header.description')]
    ]),
    _createVNode(_component_AccordionComponent, {
      "accordion-list": _ctx.getAccordions('cookie')
    }, null, 8, ["accordion-list"])
  ]))
}
<template>
  <div aria-hidden="false" class="fds-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">{{title}}</h2>
        <button aria-label="Luk" class="modal-close button button-unstyled" @click="$emit('select-reject')">
          <svg aria-hidden="true" class="icon-svg" focusable="false">
            <use xlink:href="#close"></use>
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <slot></slot>
      </div>
      <div class="modal-footer">
        <button class="button button-primary saveButton" data-cy-id="modal-confirm" @click="$emit('select-ok', this.valueObject)" :disabled="buttonsDisabled">
          <SimpleText :textKey="buttonOkTextKey" />
        </button>
        <button class="button button-secondary saveButton" data-cy-id="modal-cancel" @click="$emit('select-reject')" :disabled="buttonsDisabled">
          <SimpleText :textKey="buttonRejectTextKey" />
        </button>
      </div>
    </div>
  </div>
  <div id="modal-backdrop" class="modal-backdrop"/>
</template>

<script lang="ts">
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import {Prop} from "vue-property-decorator";
import {Options} from "vue-class-component";
import SimpleText from "@/components/SimpleText.vue";

@Options({
  emits: ['select-ok', 'select-reject'],
  components: {
    SimpleText
  }
})
export default class AcceptModal extends BaseComponent{
  @Prop()
  valueObject: any = {}
  @Prop()
  titleKey = ''
  @Prop()
  titleText = ''
  @Prop()
  valueText = ''
  @Prop()
  buttonsDisabled = false
  @Prop()
  buttonOkTextKey = ''
  @Prop()
  buttonRejectTextKey = ''

  title = ''

  beforeMount() {
    if (this.titleText) {
      this.title = this.titleText
    } else {
      this.title = this.getContentfulString(this.titleKey)
    }
  }


}
</script>
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bd7f3d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 2,
  class: "weight-normal ml-2"
}
const _hoisted_4 = {
  key: 3,
  class: "form-hint"
}
const _hoisted_5 = {
  key: 4,
  class: "p-3",
  style: {"text-align":"right"}
}
const _hoisted_6 = ["id", "data-error", "disabled", "max", "maxlength", "min", "pattern", "required", "step", "value"]
const _hoisted_7 = { key: 6 }
const _hoisted_8 = ["id", "data-error", "required"]
const _hoisted_9 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_10 = { key: 7 }
const _hoisted_11 = ["id", "data-error", "disabled", "required", "value"]
const _hoisted_12 = ["selected"]
const _hoisted_13 = ["disabled", "value"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["id", "data-error", "required", "value", "title"]
const _hoisted_16 = ["value"]
const _hoisted_17 = { key: 8 }
const _hoisted_18 = ["id", "data-error", "disabled", "required", "value"]
const _hoisted_19 = ["selected"]
const _hoisted_20 = ["data-dependents", "disabled", "value"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = ["data-dependents", "disabled", "value"]
const _hoisted_23 = { key: 0 }
const _hoisted_24 = ["id", "data-error", "value"]
const _hoisted_25 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type !== 'NOT_RELEVANT')
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([{ 'double-line-height': _ctx.doubleLineHeight }, "form-label"])
        }, [
          (_ctx.type !== 'NOT_RELEVANT')
            ? _withDirectives((_openBlock(), _createElementBlock("label", {
                key: 0,
                for: _ctx.id
              }, null, 8, _hoisted_2)), [
                [_directive_dompurify_html, _ctx.lable]
              ])
            : _createCommentVNode("", true),
          _createTextVNode("  "),
          (_ctx.tooltip)
            ? (_openBlock(), _createBlock(_component_Tooltip, {
                key: 1,
                text: _ctx.tooltip
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.type === 'NOT_RELEVANT')
      ? _withDirectives((_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass([{ 'double-line-height': _ctx.doubleLineHeight }, "form-label"])
        }, null, 2)), [
          [_directive_dompurify_html, _ctx.lable]
        ])
      : _createCommentVNode("", true),
    (_ctx.showOptionalLabel)
      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, null, 512)), [
          [_directive_dompurify_html, this.optionalLabel]
        ])
      : _createCommentVNode("", true),
    (_ctx.hintText)
      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, null, 512)), [
          [_directive_dompurify_html, _ctx.hintText]
        ])
      : _createCommentVNode("", true),
    (_ctx.type === 'NOT_RELEVANT')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("em", null, [
            _createVNode(_component_SimpleText, { "text-key": "sharedContent.notRelevant" })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type !== 'DROPDOWN' && _ctx.type !== 'UNIT' && _ctx.type !== 'DROPDOWN_DEPENDENT' && _ctx.type !== 'NOT_RELEVANT')
      ? (_openBlock(), _createElementBlock("input", {
          key: 5,
          id: _ctx.id,
          ref: "input",
          "data-error": _ctx.validationError,
          disabled: _ctx.disabled,
          max: _ctx.max,
          maxlength: _ctx.type === 'STRING' ? _ctx.max : '',
          min: _ctx.min,
          pattern: _ctx.regex,
          required: _ctx.required,
          step: _ctx.step,
          style: _normalizeStyle(_ctx.type !== 'STRING'
          ? 'text-align: right'
          : 'text-align: left'
      ),
          value: _ctx.currentValue(),
          class: "form-input",
          name: "input-type-text",
          autocomplete: "off",
          onFocusout: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.checkIfChanged($event.target.value, 'focusout')), ["self","prevent"])),
          onInput: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.checkIfChanged($event.target.value, 'input')), ["self","prevent"])),
          onChange: _cache[2] || (_cache[2] = _withModifiers(($event: any) => {_ctx.checkIfChanged($event.target.value, 'change'); _ctx.emitUpdate($event.target.value)}, ["self","prevent"])),
          onKeydown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateLocalizedNumberInput($event, _ctx.type)))
        }, null, 44, _hoisted_6))
      : _createCommentVNode("", true),
    ((_ctx.type === 'DROPDOWN' || _ctx.type === 'UNIT' || _ctx.type === 'DROPDOWN_DEPENDENT') && !_ctx.currentPossibleValues?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (!_ctx.currentPossibleValues?.length)
            ? (_openBlock(), _createElementBlock("select", {
                key: 0,
                id: _ctx.id,
                ref: "input",
                "data-error": _ctx.validationError,
                required: _ctx.required,
                class: "form-select",
                disabled: "",
                name: "units"
              }, [
                _createElementVNode("option", _hoisted_9, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.select" })
                ])
              ], 8, _hoisted_8))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    ((_ctx.type === 'DROPDOWN' || _ctx.type === 'UNIT') && _ctx.currentPossibleValues)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_ctx.currentPossibleValues.length > 1)
            ? (_openBlock(), _createElementBlock("select", {
                key: 0,
                id: _ctx.id,
                ref: "input",
                "data-error": _ctx.validationError,
                disabled: _ctx.disabled,
                required: _ctx.required,
                value: _ctx.modelValue,
                class: "form-select",
                name: "units",
                onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
              }, [
                _createElementVNode("option", {
                  selected: !_ctx.modelValue,
                  value: ""
                }, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.select" })
                ], 8, _hoisted_12),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedPossibleValues, (item) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: item.id,
                    disabled: item.disabled,
                    value: _ctx.constants.FIELD_ENUM_ID_PREFIX + item.id
                  }, [
                    (!item.disabled)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, " "))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(item.name), 1)
                  ], 8, _hoisted_13))
                }), 128))
              ], 40, _hoisted_11))
            : _createCommentVNode("", true),
          (_ctx.currentPossibleValues.length === 1)
            ? (_openBlock(), _createElementBlock("select", {
                key: 1,
                id: _ctx.id,
                ref: "input",
                "data-error": _ctx.validationError,
                disabled: true,
                required: _ctx.required,
                value: _ctx.modelValue,
                class: "form-select",
                name: "units",
                title: _ctx.currentPossibleValues[0].name
              }, [
                _createElementVNode("option", {
                  selected: true,
                  value: _ctx.constants.FIELD_ENUM_ID_PREFIX + _ctx.currentPossibleValues[0].id
                }, _toDisplayString(this.parseFieldNameFromDb(_ctx.currentPossibleValues[0].name, _ctx.currentPossibleValues[0].contentfulId)), 9, _hoisted_16)
              ], 8, _hoisted_15))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.type === 'DROPDOWN_DEPENDENT')
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          (_ctx.currentPossibleValues && _ctx.currentPossibleValues.length > 1)
            ? (_openBlock(), _createElementBlock("select", {
                key: 0,
                id: _ctx.id,
                ref: "input",
                "data-error": _ctx.validationError,
                disabled: _ctx.disabled,
                required: _ctx.required,
                value: _ctx.modelValue,
                class: "form-select",
                name: "units",
                onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.emitWithDependents($event)))
              }, [
                _createElementVNode("option", {
                  selected: !_ctx.modelValue,
                  value: ""
                }, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.select" })
                ], 8, _hoisted_19),
                (!_ctx.isSpecialUnitList)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.sortedCurrentPossibleValues, (item) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: item.id,
                        "data-dependents": JSON.stringify(item.dependent),
                        disabled: item.disabled,
                        value: _ctx.constants.FIELD_ENUM_ID_PREFIX + item.id
                      }, [
                        (!item.disabled)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_21, " "))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(_ctx.parseFieldNameFromDb(item.name, item.contentfulId)), 1)
                      ], 8, _hoisted_20))
                    }), 128))
                  : _createCommentVNode("", true),
                (_ctx.isSpecialUnitList)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.currentPossibleValues, (item) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: item.id,
                        "data-dependents": JSON.stringify(item.dependent),
                        disabled: item.disabled,
                        value: _ctx.constants.FIELD_ENUM_ID_PREFIX + item.id
                      }, [
                        (!item.disabled)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_23, " "))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(_ctx.parseFieldNameFromDb(item.name, item.contentfulId)), 1)
                      ], 8, _hoisted_22))
                    }), 128))
                  : _createCommentVNode("", true)
              ], 40, _hoisted_18))
            : _createCommentVNode("", true),
          (_ctx.currentPossibleValues && _ctx.currentPossibleValues.length === 1)
            ? (_openBlock(), _createElementBlock("select", {
                key: 1,
                id: _ctx.id,
                ref: "input",
                "data-error": _ctx.validationError,
                disabled: true,
                required: true,
                value: _ctx.modelValue,
                class: "form-select",
                name: "units"
              }, [
                _createElementVNode("option", {
                  value: _ctx.constants.FIELD_ENUM_ID_PREFIX + _ctx.currentPossibleValues[0].id,
                  selected: ""
                }, _toDisplayString(_ctx.parseFieldNameFromDb(_ctx.currentPossibleValues[0].name, _ctx.currentPossibleValues[0].contentfulId)), 9, _hoisted_25)
              ], 8, _hoisted_24))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
<template>
  <div
      v-if="formValidation.formErrors.size"
      aria-atomic="true"
      class="alert alert-error"
      role="alert"
  >
    <div class="alert-body">
      <p class="alert-heading"><SimpleText text-key="sharedContent.formErrorTitle"/></p>
      <ul class="alert-text">
        <li
            v-for="[key, value] of formValidation.formErrors"
            :key="key"
            :data-cy="'error_' + key"
        >
          {{ value }}
        </li>
      </ul>
    </div>
  </div>

  <h1 class="h2"><SimpleText text-key="purpose.title"/></h1>
  <form
      id="purpose"
      v-if="calculation.calculationPurpose"
      @change="
      clearDateErrors($event);
      formValidation.clearValidField($event);
    "
      @submit.prevent="submitForm"
      @invalid.capture.prevent="
      formValidation.handleFormValidity($event, true);
      validateDates();
    "
  >
    <!-- Felt start: Navn -->
    <div class="form-group mt-6">
      <label class="form-label " for="name">
        <SimpleText text-key="calculation.masterdata.text.calculation-name"/>
      </label>

      <input
          id="name"
          v-model="calculation.calculationPurpose.name"
          :data-error="this.nameOfCalculationError"
          :disabled="isReadOnly"
          class="form-input"
          maxlength="40"
          name="name"
          :pattern="findRegexPattern('regex.validation.calculation-name')"
          required
          type="text"
      />
    </div>
    <!-- Felt slut: Navn -->

    <!-- Felt start: Formål med beregningen -->
    <div class="form-group mt-7" id="purpose-radio-buttons">
      <fieldset>
        <legend class="form-label">
          <SimpleText text-key="calculation.masterdata.text.about-calculation"/>
          <span class="ml-2"
          ><Tooltip :text="getContentfulString('calculation.masterdata.text.purpose.tooltip')"
          /></span>
        </legend>

        <span id="purpose-field-hint" class="form-hint"><SimpleText text-key="purpose.purposeHint"/></span>
        <ul class="nobullet-list">
          <li>
            <input
                id="purpose-first"
                v-model="calculation.calculationPurpose.purpose"
                :disabled="isPrivateUser || isReadOnly"
                class="form-radio radio-large"
                name="purpose"
                type="radio"
                value="realClimateFootprint"
            />
            <label
                id="form-label-purpose-first"
                for="purpose-first"
                v-text="this.purposeOption1"
            ></label>
          </li>
          <li>
            <input
                id="purpose-second"
                v-model="calculation.calculationPurpose.purpose"
                :disabled="isPrivateUser || isReadOnly"
                class="form-radio radio-large"
                name="purpose"
                type="radio"
                value="CO2reducingMeasures"
            />
            <label id="form-label-purpose-second" for="purpose-second">{{
                this.purposeOption2
              }}</label>
          </li>
          <li>
            <input
                id="purpose-third"
                v-model="calculation.calculationPurpose.purpose"
                :disabled="isPrivateUser || isReadOnly"
                class="form-radio radio-large"
                name="purpose"
                type="radio"
                value="forecast"
            />
            <label id="form-label-purpose-third" for="purpose-third">{{
                this.purposeOption3
              }}</label>
          </li>
          <li>
            <input
                id="purpose-fourth"
                v-model="calculation.calculationPurpose.purpose"
                :disabled="isReadOnly"
                class="form-radio radio-large"
                name="purpose"
                type="radio"
                value="studyOrOther"
            />
            <label id="form-label-purpose-fourth" for="purpose-fourth">{{
                this.purposeOption4
              }}</label>
          </li>
        </ul>
      </fieldset>
    </div>
    <!-- Felt slut: Formål med beregningen -->

    <div :class="{ 'form-error': showStartDateError }" class="form-group mt-7">
      <fieldset>
        <legend class="form-label"><SimpleText text-key="calculation.masterdata.text.start-date"/></legend>
        <span id="start-date-field-hint" class="form-hint"><SimpleText text-key="purpose.startDateHint"/></span>
        <div class="date-group mt-3">
          <div class="form-group form-group-day">
            <label
                :aria-label="this.day"
                class="form-label"
                for="start-date-day"
            >
              <SimpleText text-key="purpose.day"/>
            </label>
            <input
                id="start-date-day"
                v-model="startDate.day"
                :data-error="this.startDayError"
                :disabled="isReadOnly"
                aria-placeholder="dd"
                class="form-input"
                maxlength="2"
                :pattern="findRegexPattern('regex.validation.date.day-of-month')"
                placeholder="dd"
                required
                type="tel"
                inputmode="numeric"
                autocomplete="off"
            />
          </div>

          <div class="form-group form-group-month extra-space">
            <label
                :aria-label="this.month"
                class="form-label"
                for="start-date-month"
            >
              <SimpleText text-key="purpose.month"/>
            </label>
            <input
                id="start-date-month"
                v-model="startDate.month"
                :data-error="this.startMonthError"
                :disabled="isReadOnly"
                aria-placeholder="mm"
                class="form-input js-calendar-month-input"
                maxlength="2"
                :pattern="findRegexPattern('regex.validation.date.month-of-year')"
                placeholder="mm"
                required
                type="tel"
                inputmode="numeric"
                autocomplete="off"
            />
          </div>

          <div class="form-group form-group-year ">
            <label
                :aria-label="this.year"
                class="form-label"
                for="start-date-year"
            ><SimpleText text-key="purpose.year"/></label
            >
            <input
                id="start-date-year"
                v-model="startDate.year"
                :data-error="this.startYearAfter2015error"
                :disabled="isReadOnly"
                aria-placeholder="yyyy"
                class="form-input js-calendar-year-input"
                maxlength="4"
                :pattern="findRegexPattern('regex.validation.date.year')"
                placeholder="yyyy"
                required
                type="tel"
                inputmode="numeric"
                autocomplete="off"
                @input="checkYear"
                @focusout="isDefferedAccounts()"
            />
          </div>
        </div>
      </fieldset>
    </div>

    <div
        v-if="startDate.year.length === 4 && illegalYear(startDate.year)"
        aria-atomic="true"
        class="alert alert-warning"
        role="alert"
        style="max-width: 49rem"
    >
      <div class="alert-body">
        <p class="alert-text">
          <SimpleText text-key="purpose.noEmissioncoefficientsWarning"/>
          <strong>{{ yearToUse }}</strong>
        </p>
      </div>
    </div>
    <!-- This should be adjusted, when more emissioncoefficients are implemented - END -->

    <div :class="{ 'form-error': showEndDateError }" class="form-group mt-7">
      <fieldset>
        <legend class="form-label"><SimpleText text-key="calculation.masterdata.text.end-date"/></legend>
        <span id="end-date-field-hint" class="form-hint"><SimpleText text-key="purpose.endDateHint"/></span>
        <div class="date-group mt-3">
          <div class="form-group form-group-day">
            <label class="form-label" for="end-date-day"><SimpleText text-key="purpose.day"/>
              </label>
            <input
                id="end-date-day"
                v-model="endDate.day"
                :data-error="this.endDayError"
                :disabled="isReadOnly"
                aria-placeholder="dd"
                class="form-input"
                maxlength="2"
                :pattern="findRegexPattern('regex.validation.date.day-of-month')"
                placeholder="dd"
                required
                type="tel"
                inputmode="numeric"
                autocomplete="off"
            />
          </div>

          <div class="form-group form-group-month extra-space">
            <label class="form-label" for="end-date-month"><SimpleText text-key="purpose.month"/></label>
            <input
                id="end-date-month"
                v-model="endDate.month"
                :data-error="this.endMonthError"
                :disabled="isReadOnly"
                aria-placeholder="mm"
                class="form-input js-calendar-month-input"
                maxlength="2"
                :pattern="findRegexPattern('regex.validation.date.month-of-year')"
                placeholder="mm"
                required
                type="tel"
                inputmode="numeric"
                autocomplete="off"
            />
          </div>
          <div class="form-group form-group-year">
            <label class="form-label" for="end-date-year"><SimpleText text-key="purpose.year"/></label>
            <input
                id="end-date-year"
                v-model="endDate.year"
                :data-error="this.endYearAfter2015error"
                :disabled="isReadOnly"
                aria-placeholder="yyyy"
                class="form-input js-calendar-year-input"
                maxlength="4"
                :pattern="findRegexPattern('regex.validation.date.year')"
                placeholder="yyyy"
                required
                type="tel"
                inputmode="numeric"
                autocomplete="off"
                @focusout="isDefferedAccounts()"
            />
          </div>
        </div>
      </fieldset>
    </div>

    <div v-if="displayPeriodInfoBanner"
         aria-atomic="true"
         class="alert alert-info"
         role="alert"
         style="max-width: 49rem"
    >
      <p><strong>
        <SimpleText :textKey="'purpose.period-extends-multiple-years.info.header'"/>
      </strong></p>
      <p v-text="multipleYearPeriodInfoText"></p>
    </div>

    <div v-if="this.startDate.getDate.year() && this.endDate.getDate.year()" class="form-group mt-9" data-cy-name="EmissionsfactorDropdown">
      <label class="form-label" for="input-area" id="purpose-emf-update-dropdown">
        <SimpleText :textKey="'purpose.emf.update.dropdown.label'"/>
      </label>
      <select
          id="emf-year"
          aria-labelledby="purpose-emf-update-dropdown"
          v-if="sortedVersions?.length > 1"
          class="form-select"
          v-model="emissionFactorYear"
          name="versions"
          @focusout="isDefferedAccounts">
        <option v-for="item in [...this.versions?.versions].sort((a, b) => b.year - a.year)" :key="item.year" :value="item.year">
          {{ item.year }}
        </option>
      </select>

    </div>

    <div v-if="isNewECVersion"
         aria-atomic="true"
         class="alert alert-warning"
         role="alert"
         style="max-width: 49rem"
    >
      <p style="display: flex">
        <svg aria-hidden="true" class="icon-svg-warning" focusable="false">
          <use xlink:href="#report-problem"></use>
        </svg>
        <strong>
          <SimpleText :textKey="'purpose.emf.update.warning.text'"/>
        </strong></p>
      <p>
        <SimpleText :textKey="'purpose.emf.update.warning.text2'"/>
      </p>
      <button class="button button-secondary" @click="updateVersionConfirm(calculation)">
        <SimpleText :textKey="'purpose.emf.update.button'"/>
      </button>
    </div>

    <div v-if="showNewECYearInfo"
         aria-atomic="true"
         class="alert alert-info"
         role="alert"
         style="max-width: 49rem"
    >
      <p><strong>
        <SimpleText :textKey="'purpose.emf.update.warning.year.text'"/>
      </strong></p>
      <p>{{ getNewECYearWarningText }}</p>
    </div>

    <div class="form-group mt-9">
      <label class="form-label" for="input-area">
        <SimpleText text-key="calculation.masterdata.text.calculation-note"/>
        <span class="weight-normal ml-2"><SimpleText text-key="sharedContent.optionalLabel"/></span>
      </label>

      <textarea
          id="input-area"
          v-model="calculation.calculationPurpose.comment"
          :data-error="this.purposeDescriptionError"
          :disabled="isReadOnly"
          class="form-input"
          maxlength="500"
          minlength="2"
          name="input-area"
          placeholder=""
          rows="5"
      ></textarea>
    </div>

    <button
        id="submit-button"
        :disabled="isReadOnly"
        class="button button-primary mt-705"
        type="submit"
    >
      <SimpleText text-key="sharedContent.next"/>
    </button>
  </form>

  <!-- update confirmation modal-->
  <template v-if="openVersionDialog">
    <div aria-hidden="false" class="fds-modal">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">
            <SimpleText text-key="purpose.emf.update.dialog.title"/>
          </h2>
          <button aria-label="Luk" class="modal-close button button-unstyled" @click="resetVersion()">
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#close"></use>
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <p v-dompurify-html="updateVersionText(calculation)"/>
        </div>
        <div class="modal-footer">
          <button class="button button-primary" @click="updateVersionSubmit()">
            <SimpleText text-key="purpose.emf.update.dialog.button.ok"/>
          </button>
          <button class="button button-secondary" @click="resetVersion()">
            <SimpleText text-key="purpose.emf.update.dialog.button.cancel"/>
          </button>
        </div>
      </div>
    </div>
    <div id="modal-backdrop" class="modal-backdrop"/>
  </template>

  <template v-if="openYearDialog">
    <div aria-hidden="false" class="fds-modal">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">
            <SimpleText text-key="purpose.emf.update.dialog.year.title"/>
          </h2>
          <button aria-label="Luk" class="modal-close button button-unstyled" @click="resetYear()">
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#close"></use>
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <p v-dompurify-html="updateYearText(calculation)"/>
        </div>
        <div class="modal-footer">
          <button class="button button-primary" @click="updateYearSubmit()">
            <SimpleText text-key="purpose.emf.update.dialog.button.ok"/>
          </button>
          <button class="button button-secondary" @click="resetYear()">
            <SimpleText text-key="purpose.emf.update.dialog.button.cancel"/>
          </button>
        </div>
      </div>
    </div>
    <div id="modal-backdrop" class="modal-backdrop"/>
  </template>

</template>

<script lang="ts">
import DateInput from "@/types/DateInput"
import {useRouter} from "vue-router"
import {FormValidation} from "@/shared/FormValidation"
import {
  Calculation,
  CalculationPurpose,
  CalculationPurposePurposeEnum,
  CalculationStateEnum, Company,
  Versions
} from "openapi/generated-clients/climatecompass"
import {Cloneable} from "@/shared/DeepCopyHelper"
import {calculationClient, companyClient, versionClient} from "@/shared/BackendService"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import SimpleText from "@/components/SimpleText.vue";
import {Options} from "vue-class-component";
import RichText from "@/components/RichText.vue";

@Options({
  components: {
    RichText,
    SimpleText,
  }
})

export default class Purpose extends BaseComponent {
  router = useRouter()
  versions = {} as Versions
  yearToUse = ""
  currentYear = 0
  currentVersion = 0
  selectedYear = 0
  orgEmissionFactorYear = 0

  formValidation = new FormValidation()
  startDate = new DateInput("", "", "")
  endDate = new DateInput("", "", "")
  isReadOnly = this.calculation.state === "readonly"
  showStartDateError = false
  showEndDateError = false
  updateVersion = false
  updateYear = false
  openYearDialog = false
  openVersionDialog = false
  displayPeriodInfoBanner = false

  get isPrivateUser() {
    return (this.store.getters.isPrivateUser) || (this.thirdpartyInfo?.behalfOfIsPrivate)
  }

  get purposeDescriptionError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.purposeDescriptionError')
  }

  get endYearAfter2015error() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.endYearAfter2015error')
  }

  get endMonthError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.endMonthError')
  }

  get endDayError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.endDayError')
  }

  get startYearAfter2015error() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.startYearAfter2015error')
  }

  get year() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.year')
  }

  get startMonthError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.startMonthError')
  }

  get month() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.month')
  }

  get startDayError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.startDayError')
  }

  get startDateError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.startDateError')
  }

  get day() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.day')
  }

  get purposeOption4() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.purposeOption4')
  }

  get purposeOption3() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.purposeOption3')
  }

  get startDateEndDateNotSameYearError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.startDateEndDateNotSameYearError')
  }

  get purposeOption1() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.purposeOption1')
  }

  get purposeOption2() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.purposeOption2')
  }

  get nameOfCalculationError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.nameOfCalculationError')
  }

  get endDateError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.endDateError')
  }

  get endDateAfterStartDateError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.endDateAfterStartDateError')
  }

  get illegalAccountingPeriodError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.illegal-accounting-period.error.message')
  }

  get sortedVersions() {
    if (this.versions) {
      return this.versions?.versions
    } else {
      return []
    }
  }

  isDefferedAccounts() {
    if(this.startDate.getDate.year() && this.endDate.getDate.year()) {
      this.displayPeriodInfoBanner = (this.startDate.getDate.year() != this.endDate.getDate.year())
    }
  }

  illegalYear(year: string) {
    const yy = Number(year)
    if (this.versions && this.versions.versions) {
      for (const ver of this.versions.versions) {
        if (ver.year === yy) {
          return false;
        }
      }
    }
    return true;
  }

  get isNewECVersion() {
        if (this.calculation && this.calculation.availableEmissionCoefficientsVersion) {
      if (this.calculation.availableEmissionCoefficientsVersion.year === this.calculation.emissionCoefficientsVersion?.year) {
        if (this.calculation.availableEmissionCoefficientsVersion.version !== this.calculation.emissionCoefficientsVersion.version) {
          return true
        }
      }
    }
    return false
  }

  get isNewECYear() {
    const { calculation, selectedYear } = this
    if (calculation) {
      const startYear = this.getStartYear(calculation)
      const endYear = this.getEndYear(calculation)

      //Checks if selected year is different from both start and end years and if it is outside the period range
      return startYear !== selectedYear && endYear !== selectedYear &&
          (selectedYear < startYear || selectedYear > endYear)
    }
    return false
  }



  get showNewECYearInfo() {
    const startYear = Number(this.startDate.year)
    const selectedVsStartYearDiff = Math.abs(startYear - this.selectedYear)
        //    this.versions.versions?.find(v => Math.abs(startYear - v.year) < selectedVsStartYearDiff))
    return this.isNewECYear && this.emissionFactorYear && this.versions.versions?.find(v => Math.abs(startYear - v.year) < selectedVsStartYearDiff)
  }

  get calculation() {
    //const calcData = Object.assign({} as Calculation, store.getters.getCurrentCalculation);
    const calcData = Cloneable.deepCopy<Calculation>(
        this.store.getters.getCurrentCalculation
    )
    if (calcData.calculationPurpose) {
      return calcData
    }
    return ({
      calculationPurpose: {} as CalculationPurpose
    } as Calculation) as Calculation
  }

  setCalculationPurpose() {
    this.calculation.calculationPurpose!.purpose = this.isPrivateUser
        ? ("studyOrOther" as CalculationPurposePurposeEnum)
        : ("realClimateFootprint" as CalculationPurposePurposeEnum) //Default for radiobuttons
  }

  get emissionFactorYear() {
    return this.selectedYear
  }

  set emissionFactorYear(emissionFactorYear: number) {
        this.selectedYear = emissionFactorYear
  }

  async created() {
    if (!this.calculation.calculationPurpose?.purpose) {
      this.setCalculationPurpose()
    } else {
      const _startDate = this.calculation.calculationPurpose!.startDate.split(
          "-"
      )
      const _endDate = this.calculation.calculationPurpose!.endDate.split("-")

      // day-mm-year
      this.startDate = new DateInput(
          _startDate[2],
          _startDate[1],
          _startDate[0]
      )
      this.endDate = new DateInput(_endDate[2], _endDate[1], _startDate[0])
      if (_startDate[0] !== _endDate[0]) {
        this.endDate = new DateInput(_endDate[2], _endDate[1], _endDate[0])
      }
    }
    this.isDefferedAccounts()
  }

  async beforeMount() {
    //xdocument.title = "Formål | Klimakompasset"
    try {
      this.versions = (await versionClient.fetchVersions()).data
    } catch (e) {
      console.error("Unable to fetch version",)
    }
    this.checkYear()
    if (this.calculation && this.calculation.emissionCoefficientsVersion && this.calculation.emissionCoefficientsVersion?.year) {
      this.orgEmissionFactorYear = this.calculation.emissionCoefficientsVersion.year
      this.selectedYear = this.calculation.emissionCoefficientsVersion.year
    }
  }

  validateDates(): boolean {
    let valid = true
    this.showStartDateError = false
    this.showEndDateError = false

    if (!this.startDate.isRealDate) {
      this.showStartDateError = true
      this.formValidation.formErrors.set(
          "invalid-start-date",
          this.startDateError
      )
      valid = false
    }
    if (!this.endDate.isRealDate) {
      this.showEndDateError = true
      this.formValidation.formErrors.set(
          "invalid-end-date",
          this.endDateError
      )
      valid = false
    }

    if (!valid) {
      return valid
    }

    if (this.endDate.getDate.isBefore(this.startDate.getDate.add(1, "day"))) {
      this.showEndDateError = true
      this.formValidation.formErrors.set(
          "invalid-end-date-beginning",
          this.endDateAfterStartDateError
      )
      valid = false
    }

    this.displayPeriodInfoBanner = this.startDate.getDate.year() != this.endDate.getDate.year();

    //difference between startDate and endDate is more than 18 months - set error
    const startDate = this.startDate.getDate.toDate();
    const endDate = this.endDate.getDate.toDate();

    //Check if the total days difference exceeds 18 months
    if (!this.areDatesWithin18Months(startDate, endDate) ) {
      this.showEndDateError = true;
      this.formValidation.formErrors.set(
          "invalid-date-range",
          this.illegalAccountingPeriodError
      );
      valid = false;
    }

    return valid
  }

  areDatesWithin18Months(startDate: Date, endDate: Date): boolean {
    let maximumEndDate = new Date(startDate);
    maximumEndDate.setMonth(maximumEndDate.getMonth() + 18)
    maximumEndDate.setDate(maximumEndDate.getDate() - 1) // Both start date and end date are included in the time range
    maximumEndDate.setHours(maximumEndDate.getHours() + 2) // Add 2 hours slag to account for daylight saving time

    //console.log("date compare", startDate, endDate, maximumEndDate, endDate.getTime() <= maximumEndDate.getTime());
    return endDate.getTime() <= maximumEndDate.getTime();
  }

  clearDateErrors(e: Event) {
    const element = e.target as HTMLInputElement
    if (!element.validity.valid) {
      return
    }
    if (element.id.substring(0, 8) === "end-date") {
      this.formValidation.formErrors.delete("invalid-end-date")
      this.formValidation.formErrors.delete("invalid-end-date-beginning")
    }
    if (element.id.substring(0, 10) === "start-date") {
      this.formValidation.formErrors.delete("invalid-start-date")
    }
    this.formValidation.formErrors.delete("invalid-end-date-year")
  }

  updateVersionConfirm(calculation: Calculation) {
    this.openVersionDialog = true
    this.updateVersion = false
  }

  resetYear() {
    this.updateYear = false
    this.openYearDialog = false
  }

  resetVersion() {
    this.updateVersion = false
    this.openVersionDialog = false
  }


  updateYearSubmit() {
    this.updateYear = true
    this.openYearDialog = false
    this.submitForm()
  }

  updateVersionSubmit() {
    this.updateVersion = true
    this.openVersionDialog = false
    this.submitForm()
  }

  async submitForm(e?: Event) {
    this.formValidation.formErrors.clear()

    if (!this.validateDates()) {
      if (e) {
        const element = e.target as HTMLElement
        await this.$nextTick(() =>
            this.formValidation
                .getParentContainerElement(element)
                .scrollIntoView(true)
        )
      }
      return
    }
    /** if purpose 1 or 2 then check if company information must be updated to certificates companyinformation */
    if (this.calculation && this.calculation.calculationPurpose) {
            if (this.calculation.calculationPurpose?.purpose === "realClimateFootprint" || this.calculation.calculationPurpose?.purpose === "CO2reducingMeasures") {
        const cvrInCertificate = await this.store.getters.getCvrCertificate
                if (this.calculation.companyInformation?.vatNo !== cvrInCertificate) {
          if (this.calculation.companyInformation !== undefined) {
            if (this.thirdpartyInfo) {
              const vNo = (this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : "")
              const vName = (this.thirdpartyInfo.behalfOfCompanyName ? this.thirdpartyInfo.behalfOfCompanyName : "")
              this.calculation.companyInformation = this.getCompanyInformation(vNo, vName, false, false)
            } else {
              // update calculation with companyInformation of certificate
              const companyInfo = await companyClient.fetchCompany(cvrInCertificate)
              const vNo = (companyInfo.data.vatNo ? companyInfo.data.vatNo : "")
              const vName = (companyInfo.data.name ? companyInfo.data.name : "")
              this.calculation.companyInformation = this.getCompanyInformation(vNo, vName, true, false)
            }
          }
        }
      }
    }
    //This is a workaround for the cypress-bug (https://github.com/cypress-io/cypress/issues/6678)
    if (this.calculation.calculationPurpose?.comment) {
      if (
          this.calculation.calculationPurpose!.comment!.length < 2 ||
          this.calculation.calculationPurpose!.comment!.length >= 500
      ) {
        this.formValidation.formErrors.set(
            "input-area",
            this.purposeDescriptionError
        )
        return
      }
    }
    this.calculation.calculationPurpose!.startDate = this.startDate.toIsoString
    //this.calculation.calculationPurpose!.endDate = `${this.endDate.month}-${this.endDate.day}`
    this.calculation.calculationPurpose!.endDate = this.endDate.toIsoString
    this.calculation.privateCalculation = false
    this.calculation.state = "draft" as CalculationStateEnum
    if (!this.calculation.id) {
      // If calculation has not already been created, set electricityEnvironmentDeclaration and RFI to default values
      this.calculation.electricityEnvironmentDeclaration = true
      this.calculation.rfi = true
    }
    const yearChanged = this.selectedYear != this.orgEmissionFactorYear
    if (this.openVersionDialog && !this.updateVersion) {
      return
    }

    if (this.calculation.id && this.calculation.emissionCoefficientsVersion && (this.updateVersion || yearChanged)) {
      // update if changed year and version, BE will check this
      if (this.updateVersion) {
        //
      }
      if (yearChanged) {
          this.openYearDialog = true
          if (!this.updateYear) {
            return
          }
          if (this.updateYear && yearChanged) {
            this.openYearDialog = false
          }
          this.calculation.emissionCoefficientsVersion.year = this.selectedYear
          this.calculation.emissionCoefficientsVersion.version = this.getVersionByYear(this.versions, this.selectedYear)
      }
      if (this.updateVersion) {
          this.calculation.emissionCoefficientsVersion.version = this.getVersionByYear(this.versions, this.selectedYear)
      }
              await calculationClient.updateCalculationWithEmissionCoefficientVersion(
            this.calculation.id,
            this.calculation.emissionCoefficientsVersion.year,
            this.calculation.emissionCoefficientsVersion.version,
            this.thirdpartyInfo && this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null'
      )
    }
    //console.log("submitform, val EMF version calcId=" + this.calculation.id, this.orgEmissionFactorYear)
    if (!this.orgEmissionFactorYear || this.orgEmissionFactorYear === 0) {
      //If not saved, Set EMF to selected value from dropdown
      const selectedEmfVersion = this.versions?.versions?.find(version => version.year === this.selectedYear)
      this.calculation.emissionCoefficientsVersion = selectedEmfVersion
      //console.log("submitform, set EMF version calcId=" + this.calculation.id, selectedEmfVersion)
    }

    await this.store.dispatch("setCalculation", this.calculation)

    if ((yearChanged && this.orgEmissionFactorYear != undefined && this.orgEmissionFactorYear != 0) || this.updateVersion) {
      this.addEmissionUpdateToast()
    }
    if (this.updateVersion) {
      this.updateVersion = false
      await this.router.push("/calculator/master-data/purpose")
    } else {
      await this.router.push("/calculator/master-data/company-information")
    }
  }

  getCompanyInformation(newVatNo: string, newCompanyName: string, clearKeyValues: boolean, clearPo: boolean) {
    const newCompanyInformation = {
      ...this.calculation.companyInformation
    }
    newCompanyInformation.vatNo = newVatNo
    newCompanyInformation.companyName = newCompanyName
    if (clearKeyValues) {
            newCompanyInformation.turnover = undefined
      newCompanyInformation.numberOfEmployees = undefined
      newCompanyInformation.buildingSquareMeterArea = undefined
    }
    if (clearPo) {
            newCompanyInformation.productionUnits = []
    }
    // note fetchCompany assumes DK country, this could be a problem is non dk companys in future can login to kk-2 then fetchCompany() must be extended
    newCompanyInformation.country = "DK"
    return newCompanyInformation
  }

  get thirdpartyInfo() {
    return this.store.getters.getThirdpartyInfo
  }

  get getNewECYearWarningText() {
    return this.getContentfulString('purpose.emf.update.warning.year.text2')
        .replace("#SELECTED_YEAR#", this.selectedYear)
        .replace("#CURRENT_YEAR#", this.currentYear)
  }

  get multipleYearPeriodInfoText() {
    return this.getContentfulString('purpose.period-extends-multiple-years.info.text')
        .replace("#EMF_YEAR#", `<strong>${String(this.emissionFactorYear)}</strong>`)
  }

  updateVersionText(calculation: Calculation) {
    if (calculation) {
      return this.store.getters.getContentfulContent.renderRichText('purpose.emf.update.dialog.text')
          .replace('#YEAR#', this.getStartYear(calculation))
          .replace('#CALCULATION#', calculation.calculationPurpose.name)
    } else {
      return ""
    }
  }

  updateYearText(calculation: Calculation) {
    if (calculation) {
      return this.store.getters.getContentfulContent.renderRichText('purpose.emf.update.dialog.text')
          .replace('#YEAR#', this.selectedYear)
          .replace('#CALCULATION#', calculation.calculationPurpose.name)
    } else {
      return ""
    }
  }

  checkYear() {
    // if year before minYear then use minYear, if year after maxYear the use maxYear
        if (
        this.startDate.year.length == 4 &&
        this.versions &&
        this.versions &&
        this.versions.versions
    ) {
      const maxYear = Math.max(...this.versions.versions.map(o => o.year), 0)
      const minYear = Math.min(
          ...this.versions.versions.map(o => o.year),
          10000
      )
      for (const v of this.versions.versions) {
        if (parseInt(this.startDate.year) === v.year) {
          // all good
          this.yearToUse = ""
          this.currentYear = v.year
          this.currentVersion = v.version
          this.emissionFactorYear = parseInt(this.startDate.year)
                    return
        }
      }
      // no hit, check if minYear or maxYear is used
      if (parseInt(this.startDate.year) < minYear) {
        this.yearToUse = minYear.toString()
        this.currentYear = parseInt(this.yearToUse)
        this.currentVersion = this.getVersionByYear(this.versions, this.currentYear)
                // https://jira.erst.dk/browse/KK2-1720 - Set EF dropdown to the selected year
        this.emissionFactorYear = minYear
        return
      }
      // no hit
      if (parseInt(this.startDate.year) > maxYear) {
        this.yearToUse = maxYear.toString()
        this.currentYear = parseInt(this.yearToUse)
        this.currentVersion = this.getVersionByYear(this.versions, this.currentYear)

        // https://jira.erst.dk/browse/KK2-1720 - Set EF dropdown to the selected year
        this.emissionFactorYear = maxYear
        return
      }


    }
  }
}
</script>

<style scoped>
div.form-group.form-group-month.extra-space {
  width: calc(2px + 16px + 25px + 2ch);
}

.icon-svg-warning {
  display: inline-flex;
  align-self: flex-start;
  max-width: 24px;
  max-height: 24px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.form-input {
  max-width: 49rem !important;
}
</style>

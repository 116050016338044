import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45098870"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-tooltip"]
const _hoisted_2 = {
  key: 0,
  "xlink:href": "#tooltip-icon-white"
}
const _hoisted_3 = {
  key: 1,
  "xlink:href": "#tooltip-icon"
}
const _hoisted_4 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.tooltipText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          "data-tooltip": _ctx.tooltipText,
          style: _normalizeStyle(_ctx.style),
          "aria-label": "on-hover-tool-tip-button",
          class: "popover js-tooltip button-unstyled",
          "data-tooltip-position": "top",
          role: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.focusTooltip($event))),
          onKeyDown: "handleKeyDown(event)",
          tabindex: "0"
        }, [
          (_openBlock(), _createElementBlock("svg", {
            class: _normalizeClass([{
        'icon-svg-lg': _ctx.size === 'lg',
        'icon-svg-lg-h3': _ctx.size === 'lg-h3',
        'icon-svg-md': _ctx.size === 'md',
        'icon-svg-md-gr': _ctx.size === 'md-gr'
      }, "icon-svg"]),
            "aria-hidden": "true",
            focusable: "false"
          }, [
            (_ctx.size === 'md')
              ? (_openBlock(), _createElementBlock("use", _hoisted_2))
              : (_openBlock(), _createElementBlock("use", _hoisted_3))
          ], 2)),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.text), 1)
        ], 12, _hoisted_1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "text-after", {}, undefined, true)
  ], 64))
}
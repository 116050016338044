<template>
  <h2><SimpleText text-key="calculation.subresult-ghg.tab.title" aria-live="polite"/></h2>
  <div class="full-width w-100" v-dompurify-html="this.getContentfulString('calculation.text.partial-result.description')"/><br>

  <table class="table results-table w-percent-100" v-if="results?.groupingResults" :aria-label="getContentfulString('calculation.subresult-ghg.tab.title')">
    <thead>
    <tr class="bg-primary text-color-white">
      <th id="partial-resultghg-table-header1" colspan="5" v-dompurify-html="this.getContentfulString('calculation.partial-result-ghg.text.table.include-scopes.description')" scope="colgroup"/>
    </tr>
    </thead>
    <thead>
    <tr style="font-weight: 600">
      <th id="partial-resultghg-subheader-1" headers="partial-resultghg-table-header1" style="width: 30%" class="py-405 align-text-left bg-alternative" v-dompurify-html="getContentfulString('calculation.mainresult.text.mainresult.table1.maincategory')"></th>
      <th id="partial-resultghg-subheader-2" headers="partial-resultghg-table-header1" style="width: 18%" class="py-405 align-text-right bg-alternative" v-dompurify-html="getContentfulString('calculation.partial-result.text.table1.scope1')"></th>
      <th id="partial-resultghg-subheader-3" headers="partial-resultghg-table-header1" style="width: 14%" class="py-405 align-text-right bg-alternative" v-dompurify-html="getContentfulString('calculation.partial-result.text.table1.scope2')"></th>
      <th id="partial-resultghg-subheader-4" headers="partial-resultghg-table-header1" style="width: 18%" class="py-405 align-text-right bg-alternative" v-dompurify-html="getContentfulString('calculation.partial-result.text.table1.scope3')"></th>
      <th id="partial-resultghg-subheader-5" headers="partial-resultghg-table-header1" style="width: 20%" class="py-405 align-text-right bg-alternative" v-dompurify-html="getContentfulString('calculation.partial-result.text.table1.scope1-3')"></th>
    </tr>
    </thead>
    <tbody>
    <template style="display: contents" v-for="result in results.groupingResults" :key="result.groupingName">
      <tr class="bg-primary text-color-white">
        <td headers="partial-resultghg-subheader-1" class="align-text-left">{{ publicCategoryTheme(result.groupingName) }}</td>
        <td headers="partial-resultghg-subheader-2" class="align-text-right">{{ prettify(result.calculatedResult?.scope1Result, 2) || 0 }}</td>
        <td headers="partial-resultghg-subheader-3" class="align-text-right">{{ prettify(result.calculatedResult?.scope2Result, 2) || 0 }}</td>
        <td headers="partial-resultghg-subheader-4" class="align-text-right">{{ prettify(result.calculatedResult?.scope3Result, 2) || 0 }}</td>
        <td headers="partial-resultghg-subheader-5" class="align-text-right">{{ prettify(result.calculatedResult?.scopesResult, 2) || 0 }}</td>
      </tr>


      <template role="rowgroup" style="display: contents" v-for="subResult in result.mergedSubgroupingsCalculated" :key="subResult.mergedSubgroupingName">
        <tr role="row">
          <td headers="partial-resultghg-subheader-1" class="align-text-left">{{ publicCategoryGroup(subResult.mergedSubgroupingName, result.groupingName) }}</td>
          <td headers="partial-resultghg-subheader-2" class="align-text-right">{{ prettify(subResult.calculatedResult?.scope1Result, 2) || 0 }}</td>
          <td headers="partial-resultghg-subheader-3" class="align-text-right">{{ prettify(subResult.calculatedResult?.scope2Result, 2) || 0 }}</td>
          <td headers="partial-resultghg-subheader-4" class="align-text-right">{{ prettify(subResult.calculatedResult?.scope3Result, 2) || 0 }}</td>
          <td headers="partial-resultghg-subheader-5" class="align-text-right">{{ prettify(subResult.calculatedResult?.scopesResult, 2) || 0 }}</td>
        </tr>

        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_EL">
          <ResultRowGHG :result="result" :id="constants.GROUP_FORBRUG_AF_ELEKTRICITET" :subCategoryName="publicSubCategoryGroup(constants.GROUP_FORBRUG_AF_ELEKTRICITET)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER" :subResult="subResult" :results="results" :subCategoryName="publicSubCategoryGroup(constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>

        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_VARME_OG_PROCESENERGI">
          <ResultRowGHG :result="result" :id="constants.GROUP_FJERNVARME" :subCategoryName="publicSubCategoryGroup(constants.GROUP_FJERNVARME)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_BRÆNDSLER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_BRÆNDSLER)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>
        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_PROCESUDLEDNING">
          <ResultRowGHG :result="result" :id="constants.GROUP_PROCESUDLEDNING" :subCategoryName="publicSubCategoryGroup(constants.GROUP_PROCESUDLEDNING)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>
        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_EGNE_OG_LEASEDE_TRANSPORTFORM">
          <ResultRowGHG :result="result" :id="constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_FLY_OG_SKIBE" :subCategoryName="publicSubCategoryGroup(constants.GROUP_FLY_OG_SKIBE)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>

        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_MEDARBEJDERTRANSPORT">
          <ResultRowGHG :result="result" :id="constants.GROUP_PENDLING" :subCategoryName="publicSubCategoryGroup(constants.GROUP_PENDLING)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK" :subCategoryName="publicSubCategoryGroup(constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>
        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_VARETRANSPORT_TIL_VIRKSOMHED">
          <ResultRowGHG :result="result" :id="constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>
        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDEN">
          <ResultRowGHG :result="result" :id="constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>
        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_AFFALD">
          <ResultRowGHG :result="result" :id="constants.GROUP_AFFALD" :subCategoryName="publicSubCategoryGroup(constants.GROUP_AFFALD)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>
        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER">
          <ResultRowGHG :result="result" :id="constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>
        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_END_OF_LIGE_BEHANDLING">
          <ResultRowGHG :result="result" :id="constants.GROUP_END_OF_LIFE_BEHANDLING" :subCategoryName="publicSubCategoryGroup(constants.GROUP_END_OF_LIFE_BEHANDLING)"/>
          <ResultRowGHG :result="result" :id="constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>
      </template>

      <template style="display: contents" v-if="result.purchaseGroupingCalculatedResults">
        <template style="display: contents" v-for="subResult in result.purchaseGroupingCalculatedResults" :key="subResult.mergedSubgroupingName">
          <tr style="font-weight: 600">
            <td class="align-text-left">{{ publicCategoryGroup(subResult.mergedSubgroupingName, result.groupingName) }}</td>
            <td class="align-text-right">{{ prettify(subResult.calculatedResult?.scope1Result, 2) || 0 }}</td>
            <td class="align-text-right">{{ prettify(subResult.calculatedResult?.scope2Result, 2) || 0 }}</td>
            <td class="align-text-right">{{ prettify(subResult.calculatedResult?.scope3Result, 2) || 0 }}</td>
            <td class="align-text-right">{{ prettify(subResult.calculatedResult?.scopesResult, 2) || 0 }}</td>
          </tr>

          <template style="display: contents" v-if="subResult.mergedSubgroupingsCalculated">
            <template role="rowgroup" style="display: contents" v-for="purchaseResult in subResult.mergedSubgroupingsCalculated" :key="purchaseResult.mergedSubgroupingName">
              <tr role="row">
                <td class="pl-5 align-text-left">{{ publicCategoryPurchaseGroup(subResult.mergedSubgroupingName, purchaseResult.mergedSubgroupingName) }}</td>
                <td class="align-text-right">{{ prettify(purchaseResult.calculatedResult?.scope1Result, 2) || 0 }}</td>
                <td class="align-text-right">{{ prettify(purchaseResult.calculatedResult?.scope2Result, 2) || 0 }}</td>
                <td class="align-text-right">{{ prettify(purchaseResult.calculatedResult?.scope3Result, 2) || 0 }}</td>
                <td class="align-text-right">{{ prettify(purchaseResult.calculatedResult?.scopesResult, 2) || 0 }}</td>
              </tr>

              <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_MATERIALER">
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER)"/>
              </template>
              <template
                  v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES">
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER)"/>
              </template>
              <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_ANDET">
                <ResultRowGHG :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER)"/>
              </template>

              <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_MATERIALER">
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II)"/>
              </template>
              <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES">
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II)"/>
              </template>
              <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_ANDET">
                <ResultRowGHG :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER)"/>
              </template>
            </template>
          </template>
        </template>
      </template>
    </template>

    <tr style="font-weight: 600" class="bg-primary text-color-white">
      <td class="align-text-left"><SimpleText text-key="sharedContent.total"/></td>
      <td class="align-text-right">{{ prettify(results.ghgCalculatedResult?.scope1Result, 2) || 0 }}</td>
      <td class="align-text-right">{{ prettify(results.ghgCalculatedResult?.scope2Result, 2) || 0 }}</td>
      <td class="align-text-right">{{ prettify(results.ghgCalculatedResult?.scope3Result, 2) || 0 }}</td>
      <!-- NOTE: This should NOT be scopesResult, as this is taken from the GHGCalculatedResult -->
      <td class="align-text-right">{{ prettify(results.ghgCalculatedResult?.totalResult, 2) || 0 }}</td>
    </tr>
    </tbody>
  </table>
  <!-- udenfor scope -->
  <h2><SimpleText text-key="calculation.mainresult-ghg.text.table.outside-scope" aria-live="polite"/></h2>
  <div class="full-width w-100" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.table.outside-scope.description')"/><br>

  <table class="table results-table w-percent-100" v-if="results?.groupingResults" :aria-label="this.getContentfulString('calculation.mainresult-ghg.text.table.outside-scope')">
    <thead>
    <tr class="bg-primary text-color-white">
      <th id="partial-resultghg-table-header2" colspan="5" v-dompurify-html="getContentfulString('calculation.partial-result-ghg.text.table.outside-scope.description')" scope="colgroup"/>
    </tr>
    </thead>
    <thead>
    <tr style="font-weight: 600">
      <th id="partial-resultghg-table2-subheader-1" style="width: 30%" class="py-405 align-text-left bg-alternative" v-dompurify-html="getContentfulString('calculation.data.text.main-category')"></th>
      <th id="partial-resultghg-table2-subheader-2" style="width: 20%" class="py-405 align-text-right bg-alternative" v-dompurify-html="getContentfulString('calculation.data.text.partial-result.outside-scope')"></th>
    </tr>
    </thead>
    <tbody>
    <template style="display: contents" v-for="result in results.groupingResults" :key="result.groupingName">
      <tr class="bg-primary text-color-white">
        <td headers="partial-resultghg-table2-subheader-1" class="align-text-left">{{ publicCategoryTheme(result.groupingName) }}</td>
        <td headers="partial-resultghg-table2-subheader-2" class="align-text-right">{{ prettify(result.calculatedResult?.outsideScopeResult, 2) || 0 }}</td>
      </tr>
      <template style="display: contents" v-if="result.mergedSubgroupingsCalculated">
        <template role="rowgroup" style="display: contents" v-for="subResult in result.mergedSubgroupingsCalculated" :key="subResult.mergedSubgroupingName">
          <tr role="row">
            <td headers="partial-resultghg-table2-subheader-1" class="align-text-left">{{ publicCategoryGroup(subResult.mergedSubgroupingName, result.groupingName) }}</td>
            <td headers="partial-resultghg-table2-subheader-2" class="align-text-right">{{ prettify(subResult.calculatedResult?.outsideScopeResult, 2) || 0 }}</td>
          </tr>

          <template v-if="subResult.mergedSubgroupingName ===constants.GROUP_NAME_EL">
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_FORBRUG_AF_ELEKTRICITET" :subCategoryName="publicSubCategoryGroup(constants.GROUP_FORBRUG_AF_ELEKTRICITET)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER)"/>
          </template>
          <template v-if="subResult.mergedSubgroupingName ===constants.GROUP_NAME_VARME_OG_PROCESENERGI">
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_FJERNVARME" :subCategoryName="publicSubCategoryGroup(constants.GROUP_FJERNVARME)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_BRÆNDSLER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_BRÆNDSLER)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER)"/>
          </template>
          <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_PROCESUDLEDNING">
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_PROCESUDLEDNING" :subCategoryName="publicSubCategoryGroup(constants.GROUP_PROCESUDLEDNING)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER)"/>
          </template>
          <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_EGNE_OG_LEASEDE_TRANSPORTFORM">
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_FLY_OG_SKIBE" :subCategoryName="publicSubCategoryGroup(constants.GROUP_FLY_OG_SKIBE)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER)"/>
          </template>
          <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_MEDARBEJDERTRANSPORT">
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_PENDLING" :subCategoryName="publicSubCategoryGroup(constants.GROUP_PENDLING)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK" :subCategoryName="publicSubCategoryGroup(constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER)"/>
          </template>
          <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_VARETRANSPORT_TIL_VIRKSOMHED">
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER)"/>
          </template>
          <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDEN">
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER)"/>
          </template>
          <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_AFFALD">
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_AFFALD" :subCategoryName="publicSubCategoryGroup(constants.GROUP_AFFALD)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER)"/>
          </template>
          <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER">
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER)"/>
          </template>
          <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_END_OF_LIGE_BEHANDLING">
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_END_OF_LIFE_BEHANDLING" :subCategoryName="publicSubCategoryGroup(constants.GROUP_END_OF_LIFE_BEHANDLING)"/>
            <ResultRowGHG2Col :result="result" :id="constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER)"/>
          </template>
        </template>
      </template>

      <template style="display: contents" v-if="result.purchaseGroupingCalculatedResults">
        <template style="display: contents" v-for="subResult in result.purchaseGroupingCalculatedResults" :key="subResult.mergedSubgroupingName">
          <tr style="font-weight: 600">
            <td headers="partial-resultghg-table2-subheader-1" class="align-text-left">{{ publicCategoryGroup(subResult.mergedSubgroupingName, result.groupingName) }}</td>
            <td headers="partial-resultghg-table2-subheader-2" class="align-text-right">{{ prettify(subResult.calculatedResult?.outsideScopeResult, 2) || 0 }}</td>
          </tr>
          <template style="display: contents" v-if="subResult.mergedSubgroupingsCalculated">
            <template role="rowgroup" style="display: contents" v-for="purchaseResult in subResult.mergedSubgroupingsCalculated" :key="purchaseResult.mergedSubgroupingName">
              <tr role="row">
                <td headers="partial-resultghg-table2-subheader-1" class="pl-5 align-text-left">{{ publicCategoryPurchaseGroup(subResult.mergedSubgroupingName, purchaseResult.mergedSubgroupingName) }}</td>
                <td headers="partial-resultghg-table2-subheader-2" class="align-text-right">{{ prettify(purchaseResult.calculatedResult?.outsideScopeResult, 2) || 0 }}</td>
              </tr>

              <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_MATERIALER">
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER)"/>
              </template>
              <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES">
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER)"/>
              </template>
              <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_ANDET">
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER)"/>
              </template>
              <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_MATERIALER">
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II)"/>
              </template>
              <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES">
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II" :subCategoryName="publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II)"/>
              </template>
              <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_ANDET">
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER)"/>
                <ResultRowGHG2Col :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER" :subCategoryName="publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER)"/>
              </template>
            </template>
          </template>
        </template>
      </template>
    </template>

    <tr style="font-weight: 600" class="bg-primary text-color-white">
      <td headers="partial-resultghg-table2-subheader-1" class="align-text-left"><SimpleText text-key="sharedContent.total"/></td>
      <td headers="partial-resultghg-table2-subheader-2" class="align-text-right">{{ prettify(results.ghgCalculatedResult?.outsideScopeResult, 2) || 0 }}</td>
    </tr>
    </tbody>
  </table>

</template>
<script lang="ts">
import {Options} from 'vue-class-component'
import BaseResults from "@/components/calculator/results/BaseResults";
import {GroupingCalculatedResults} from 'openapi/generated-clients/climatecompass'
import {prettify} from '@/shared/PrettifyNumbers'
import {Prop} from 'vue-property-decorator'
import ResultRowGHG from './ResultRowGHG.vue'
import {Constants} from "@/shared/Constants";
import ResultRowGHG2Col from "@/components/calculator/results/ResultRowGHG2Col.vue";


@Options({
  components: {
    ResultRowGHG2Col,
    ResultRowGHG
  }
})
export default class PartialResultsGHG extends BaseResults {
  @Prop()
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults

  @Prop()
  printMode?: boolean

  get constants() {
    return Constants
  }

  get prettify() {
    return prettify
  }

  async beforeMount() {
    this.init()

    if (!this.printMode) {
      this.setDocumentTitleByKey('calculation.subresult-ghg.tab.title')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

* {
  -webkit-print-color-adjust: exact;
}

.table {
  border: 1px solid $color-primary;
}

.table td,
.table th {
  border-left: 1px solid $color-primary;
  border-right: 1px solid $color-primary;
}

.table tr:last-child > td,
.table tr:last-child > th {
  border-bottom: 1px solid #bfbfbf;
}

.table td,
.table th,
.table tbody tr:last-child td,
.table tbody tr:last-child th {
  padding: 0.8rem 1.2rem;
}

.text-align-right * {
  text-align: right;
}

.text-align-left * {
  text-align: left;
}

.grouping-result {
  background-color: $color-primary;
  color: white;
  font-weight: 600;
}

tr > .py-405.align-text-left.bg-alternative,
tr > .py-405.align-text-right.bg-alternative, {
  //Highly specific in order to override standard application inheritance structure for headers.
  border-right: 1px solid $color-primary !important;
  color: black;
}


</style>

<template>
  <SharedHeader>
    <template #firstColumn>
      <div id='guides-first-column-header'>
        <h1>{{ currentPageHeader.title }}</h1>
        <span v-dompurify-html='currentPageHeader.description'></span>
      </div>
      <div class='mt-5'>
        <!--a v-if='currentPageHeader.buttonPrimaryLink' :href='currentPageHeader.buttonPrimaryLink'
           class='button button-primary mr-4' target='_blank'>{{ currentPageHeader.buttonPrimaryText }}</a>
        <a v-if='currentPageHeader.buttonSecondaryLink' :href='currentPageHeader.buttonSecondaryLink'
           class='button button-secondary' target='_blank'>{{ currentPageHeader.buttonSecondaryText }}</a-->
        <!-- convert from href to button due to SQ security errors -->
        <button v-if='currentPageHeader.buttonPrimaryLink'
                type="button"
                class="button button-primary mr-4"
                @click="openLink(currentPageHeader.buttonPrimaryLink)">{{ currentPageHeader.buttonPrimaryText }}
        </button>
        <button v-if='currentPageHeader.buttonSecondaryLink'
                type="button"
                class="button button-secondary"
                @click="openLink(currentPageHeader.buttonSecondaryLink)">{{ currentPageHeader.buttonSecondaryText }}
        </button>
      </div>
    </template>
    <template #secondColumn>
      <img v-if='currentPageHeader.imageUrl' :src='currentPageHeader.imageUrl'
           alt=''/>
    </template>
  </SharedHeader>
  <div class='container px-0 pb-6'>
    <h2>
      {{ pageTitle }}
    </h2>
    <span v-if='pageDescription !== ""' v-dompurify-html='pageDescription'></span>
    <div class='w-percent-100 w-percent-100-child'>
      <div v-for='column in sortedNews' :key='column.fields.title' class='news-wrapper'
           :class="[( column.fields.type === 'informationsbesked' ? 'info' : ''), (column.fields.type === 'advarselsbesked' ? 'warning' : ''), (column.fields.type === 'succesbesked' ? 'success' : '')]">

        <div class='news-content'>
          <span class='news-label tag'>
            {{ formatDate(column.sys.createdAt) }} {{ newsTypeLabelText(column.fields.type) }}
          </span>
          <h4>{{ column.fields.title }}</h4>
          <p v-dompurify-html='documentToHtmlString(column.fields.text)'/>
        </div>
      </div>
    </div>
  </div>

</template>

<script lang='ts'>
import {getPage} from '@/shared/ContentfulService'
import {MARKS} from '@contentful/rich-text-types'
import {documentToHtmlString} from '@contentful/rich-text-html-renderer'
import NewsHeader from '@/types/contentful-types/NewsHeader'
import {Options, Vue} from 'vue-class-component'
import {GuideHeader} from '@/components/guides/Guides.vue'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import SharedHeader from '@/components/calculator/shared/SharedHeader.vue'
import {formatDate} from "@/shared/DateFormatHelper"

@Options({
  methods: {formatDate},
  components: {
    SharedHeader
  }
})
export default class News extends BaseComponent {
  currentPageHeader: GuideHeader = {} as GuideHeader

  pageTitle = ''
  pageDescription = ''

  pageProperties = {} as NewsHeader
  documentToHtmlStringOptions = {
    renderMark: {
      [MARKS.BOLD]: (text: string) => `<custom-bold>${text}<custom-bold>`
    }
  }

  get documentToHtmlString() {
    return documentToHtmlString
  }

  newsTypeLabelText(type: string) {
    switch (type) {
      case 'advarselsbesked':
        return this.warning
      case 'succesbesked':
        return this.succes
      case 'informationsbesked':
        return this.information
    }
  }

  async beforeMount() {
    // Still needs Key/value refactoring!
    this.pageProperties = await getPage<NewsHeader>('47pvRfLbOqFO652ePnFTxn')

    this.initPage()
  }

  initPage() {
    this.pageTitle = this.getContentfulString('guides.news.page.title')
    if (this.Contentful.findRichText('guides.news.page.description', false)) {
      this.pageDescription = this.getRenderedContentfulRT('guides.news.page.description')
    }

    this.currentPageHeader.title = this.getContentfulString('guides.news.header.title')
    this.currentPageHeader.description = this.getRenderedContentfulRT('guides.news.header.description')
    this.currentPageHeader.buttonPrimaryLink = this.Contentful.findSimpleTextOrNull('guides.news.header.buttonprimary.link')
    this.currentPageHeader.buttonPrimaryText = this.Contentful.findSimpleTextOrNull('guides.news.header.buttonprimary.text')
    this.currentPageHeader.buttonSecondaryLink = this.Contentful.findSimpleTextOrNull('guides.news.header.buttonsecondary.link')
    this.currentPageHeader.buttonSecondaryText = this.Contentful.findSimpleTextOrNull('guides.news.header.buttonsecondary.text')
    this.currentPageHeader.imageUrl = this.Contentful.findSimpleTextOrNull('guides.news.header.imageurl')
  }

  get warning() {
    return this.store.getters.getContentfulContent.findSimpleText('news.warning')
  }

  get succes() {
    return this.store.getters.getContentfulContent.findSimpleText('news.succes')
  }

  get information() {
    return this.store.getters.getContentfulContent.findSimpleText('news.information')
  }

  get sortedNews() {
    if (this.pageProperties && this.pageProperties.news) {
      return this.pageProperties.news.sort((a, b) => {
        if (a.sys.createdAt && b.sys.createdAt && a.sys.createdAt! < b.sys.createdAt!) {
          return 1
        } else if (a.sys.createdAt && b.sys.createdAt && a.sys.createdAt! > b.sys.createdAt!) {
          return -1
        }
        return 0
      })
    } else {
      return null
    }
  }

  openLink(url: string) {
    window.open(url)
  }


}
</script>

<style scoped lang='scss'>
.border-bottom {
  border-bottom: 1px solid #747474;
}

.row {
  max-width: 1200px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.news-wrapper {
  h4 {
    margin-top: 0;
  }
}

.news-wrapper.info, .news-wrapper.warning, .news-wrapper.success {
  width: 100%;
  padding: 0 0 10px 0;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #1a1a1a;
}

.news-label {
  color: #1a1a1a;
  text-transform: capitalize;
}

.news-wrapper.info {
  .tag {
    background-color: #e2f2fb;
  }
}

.news-wrapper.warning {
  .tag {
    background-color: #ffeecc;
  }
}

.news-wrapper.success {
  .tag {
    background-color: #eeffe2;
  }
}

.news-icon {
  display: inline;
  margin-right: 5px;

  strong {
    margin-top: 1px;
  }
}

.news-header {
  position: relative;
  top: 1px;
}

.news-content {
}

</style>

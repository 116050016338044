<template>
  <div
      v-if="tooltipText"
      :data-tooltip="tooltipText"
      :style="style"
      aria-label="on-hover-tool-tip-button"
      class="popover js-tooltip button-unstyled" data-tooltip-position="top"
      role="button"
      @click="focusTooltip($event)"
      onKeyDown="handleKeyDown(event)"
      tabindex="0"
  >
    <!--sonar-ignore-->
    <svg
      :class="{
        'icon-svg-lg': size === 'lg',
        'icon-svg-lg-h3': size === 'lg-h3',
        'icon-svg-md': size === 'md',
        'icon-svg-md-gr': size === 'md-gr'
      }"
      aria-hidden="true"
      class="icon-svg"
      focusable="false"
    >
      <use v-if="size === 'md'" xlink:href="#tooltip-icon-white"></use>
      <use v-else xlink:href="#tooltip-icon"></use>
    </svg>
    <span class="sr-only">{{ text }}</span>
  </div>
  <slot name="text-after" />
</template>

<script lang="ts">
import { Vue } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import * as DKFDS from "dkfds"
import {useStore} from "vuex";

export default class Tooltip extends Vue {
  @Prop()
  size?: string
  @Prop()
  text?: string
  @Prop()
  textKey?:string
  @Prop()
  style?: string
  @Prop()
  initDkfds? = true

  store = useStore()

  focusTooltip(event: Event) {
    const target = event.target as HTMLElement;
    //Avoids disruption of normal TAB behavior
    if (event instanceof KeyboardEvent && event.key === 'Tab') return;
    target.focus();
  }

  handleKeyDown(event: KeyboardEvent) {
    //Prevent default only for Enter or Space key to avoid interrupting tabbing functionality
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();//prevents default behavior for Enter/Space
      this.focusTooltip(event);
    }
  }

  get tooltipText() {
    if (this.textKey) {
      return this.store.getters.getContentfulContent.findSimpleText(this.textKey)
    } else {
      return this.text
    }
  }

  mounted() {
    //console.log('Tooltip mounted()', this.text, this.textKey, this.initDkfds)
    if (this.initDkfds == undefined || this.initDkfds == true) {
      //console.log("ttm -> init dkfds")
      DKFDS.init()
    }
  }

  updated() {
    //console.log('Tooltip updated()')
    if (this.initDkfds == undefined || this.initDkfds == true) {
      DKFDS.init()
    }
  }

}
</script>

<style scoped>
button {
  padding: 0 !important;
  width: initial !important;
}

button:hover {
  background-color: initial !important;
}

.icon-svg {
  height: 22.25px;
  width: 22.25px;
  float: initial !important;
  margin-bottom: 2px;
}

.icon-svg-md {
  height: 24.25px;
  width: 24.25px;
  padding-bottom: 2.25px;
  margin-left: 6px;
  overflow: visible !important;
}

.icon-svg-lg {
  height: 27.75px;
  width: 27.75px;
  padding-bottom: 3.75px;
  vertical-align: bottom;
  margin-left: 2px;
}

.icon-svg-lg-h3 {
  height: 25.25px;
  width: 25.25px;
  padding-bottom: 1.25px;
  vertical-align: bottom;
  margin-left: 5px;
}
</style>

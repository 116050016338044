<template>
  <div v-if="results.groupingResults" style="text-align: center;">
    <p class="title">
      {{ title }}
    </p>
    <div v-bind:class="{ 'print-graph-container': printMode }">
      <canvas ref="chart"></canvas>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from "vue-property-decorator"
import Chart from "chart.js"
import "chartjs-plugin-labels"
import { prettify } from "@/shared/PrettifyNumbers"
import { GroupingCalculatedResults } from "openapi/generated-clients/climatecompass"
import { DecimalConfiguration } from "@/shared/DecimalConfiguration"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import {Constants} from "@/shared/Constants";
import {chain} from "lodash";


export default class BarChart extends BaseComponent {
  @Prop()
  title?: string
  @Prop({ required: true })
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults
  @Prop()
  showOnlyOutsideScopes?: boolean
  @Prop()
  showOnlyScopes?: boolean
  @Prop()
  printMode?: boolean
  chart = {} as HTMLCanvasElement
  context: CanvasRenderingContext2D = {} as CanvasRenderingContext2D
  decimalConfiguration = new DecimalConfiguration()

  wordWrap(value?: string) {
    if (!value) {
      return ""
    }
    let result: string | string[] = value
    if (value.length >= 10 && !value.includes(" ")) {
      result = [value.substr(0, 10) + "-", value.substr(10, value.length)]
    }
    if (value.length >= 10 && value.includes(" ")) {
      result = ["", ""]
      const splitValue = value.split(" ")
      for (let i = 0; i < splitValue.length - 1; i++) {
        result[0] += splitValue[i] + " "
      }
      result[1] = splitValue[splitValue.length - 1]
    }
    return result
  }

  publicCategoryTheme(themeName: number, groupName: string) {
    switch (themeName) {
      case Constants.THEME_NUMBER_ENERGY:         return this.getContentfulString('theme.text.category.energy');
      case Constants.THEME_NUMBER_PURCHASES:      return this.getContentfulString('theme.text.category.purchase');
      case Constants.THEME_NUMBER_TRANSPORT:      return this.getContentfulString('theme.text.category.transport');
      case Constants.THEME_NUMBER_TRASH:          return this.getContentfulString('theme.text.category.waste');
      case Constants.THEME_NUMBER_SOLDPRODUCTS:   return this.getContentfulString('theme.text.category.soldproducts');
      default:                                  return groupName;
    }
  }

  buildChart() {
    const dataLabels = this.results.groupingResults?.map((e: any) => this.wordWrap(this.publicCategoryTheme(e.groupingId, e.groupingName)))

    let data

    if (this.showOnlyScopes) {
      data = this.results.groupingResults?.map((e: any) => Number(this.formatNumber(e.calculatedResult?.scopesResult)))
    } else if (this.showOnlyOutsideScopes) {
      data = this.results.groupingResults?.map((e: any) => Number(this.formatNumber(e.calculatedResult?.outsideScopeResult)))
    } else {
      data = this.results.groupingResults?.map((e: any) => Number(this.formatNumber(e.calculatedResult?.totalResult)))
    }

    const animationOptions: Chart.ChartAnimationOptions | undefined = this.printMode ? { duration: 0 } : {
      duration: 1000,
      easing: "easeOutQuart"
    }

    /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
    this.context = this.chart?.getContext("2d")!
    new Chart(this.context, {     // NOSONAR
      type: "bar",
      data: {
        labels: dataLabels,
        datasets: [
          {
            data: data,
            backgroundColor: [this.constants.GROUPING1_COLOR_HEX, this.constants.GROUPING2_COLOR_HEX, this.constants.GROUPING3_COLOR_HEX, this.constants.GROUPING4_COLOR_HEX, this.constants.GROUPING5_COLOR_HEX],
            barPercentage: 0.5,
            categoryPercentage: 1
          }
        ]
      },
      options: {
        tooltips: { enabled: false },
        hover: { mode: undefined },
        animation: animationOptions,
        layout: {
          padding: {
            top: 20
          }
        },
        plugins: {
          labels: {
            render: function(args: any) {
              return prettify(args.value)
            },
            fontColor: "#000",
            fontSize: 11,
            textMargin: 5,
            position: "outside",
            segment: true,
            segmentColor: "auto"
          }
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.getContentfulString('shared.ton-co2e.no-format'),
              },
              gridLines: {
                borderDashOffset: 2,
                borderDash: [10, 8]
              },
              ticks: {
                callback: function(label, index, labels) {
                  return label !== 0 ? prettify(label) : "0"
                },
                fontSize: 14,
                maxTicksLimit: 8,
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
                drawOnChartArea: false
              }
            }
          ]
        }
      }
    })
  }

  mounted() {
    this.buildChart()
  }

  @Watch("results")
  watchResults(newValue: GroupingCalculatedResults, oldValue: GroupingCalculatedResults) {
    this.buildChart()
  }
}
</script>

<style scoped>
.title {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 550;
}
</style>

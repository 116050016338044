<template>
  <div class="container py-9">
    <h1 class="mt-5"><SimpleText text-key="acceptterms.title"/></h1>
    <div class="full-width" v-dompurify-html="documentToHtmlString(this.text)" />
    <div class="py-5">
      <input id="consent-checkbox" v-model="userAccepted" class="form-checkbox  checkbox-large" name="checkbox-large[]"
             type="checkbox" />
      <label for="consent-checkbox"
      ><strong><SimpleText text-key="acceptterms.acceptTerms"/></strong></label
      >

      <router-link :to="`/terms-for-use`" target="_blank"><SimpleText text-key="acceptterms.acceptTermsLink"/></router-link>
    </div>
    <div class="py-5">
      <button id="submit-consent" :disabled="!userAccepted" class="button button-primary px-8" @click="acceptTerms()">
        <SimpleText text-key="acceptterms.acceptButton"/>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import { Vue, Options, mixins } from "vue-class-component"
import SimpleText from "@/components/SimpleText.vue";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";

@Options({
  components: {
    SimpleText,
  }
})
export default class AcceptTermsComponent extends BaseComponent {
  userAccepted = false
  router = useRouter()
  store = useStore()

  get documentToHtmlString() {
    return documentToHtmlString
  }

  async beforeMount() {
    this.setDocumentTitleByKey("title.accept-terms")
  }

  async acceptTerms() {
    await this.store.dispatch("setConsent", true)
    await this.router.push("/redirect")
  }

  get text() {
    return this.store.getters.getContentfulContent.findSimpleText('acceptterms.text')
  }

}
</script>

<template>
  <div>
    <ApexChart
      type="bar"
      :options="chartOptions"
      :series="chartOptions.series"
      width="100%"
      height="400"
      :class="additionalClass"
      ref="vueApexChartRef"
    ></ApexChart>
  </div>
  <ReductionCategoryChartLegend :selected-categories="selCatArray" :possible-upstream-categories="possibleUpstreamCategories" :possible-downstream-categories="possibleDownstreamCategories"/>
</template>

<script lang="ts">
import VueApexCharts, { VueApexChartsComponent } from 'vue3-apexcharts'
import { Options } from 'vue-class-component'
import CypressTestElement from '@/components/testhelpers/CypressTestElement.vue'
import SimpleText from '@/components/SimpleText.vue'
import {
  CategoryResult,
  ReductionResultByCategory, SelectedCategory
} from '../../../openapi/generated-clients/climatecompass'
import ComparisonBase from '@/components/comparison/ComparisonBase'
import { graphPrettify } from '@/shared/PrettifyNumbers'
import { Prop } from 'vue-property-decorator'
import { Constants } from '@/shared/Constants'
import ReductionCategoryChartLegend from "@/components/forecast/ReductionCategoryChartLegend.vue";

class CategoriesForSelect {
  categoryId: string
  categoryPresentationName: string

  constructor(categoryId: string, categoryPresentationName: string) {
    this.categoryId = categoryId
    this.categoryPresentationName = categoryPresentationName
  }
}

@Options({
  components: {
    ReductionCategoryChartLegend,
    CypressTestElement,
    ApexChart: VueApexCharts,
    SimpleText
  },
  watch: {
    possibleUpstreamCategories: {
      handler(newVal: CategoriesForSelect[]) {
        this.upstreamCategories = newVal.map(category => category.categoryId);
      },
      immediate: true,
      deep: true
    },
    possibleDownstreamCategories: {
      handler(newVal: CategoriesForSelect[]) {
        this.downstreamCategories = newVal.map(category => category.categoryId);
      },
      immediate: true,
      deep: true
    }
  }
})
export default class Scope3CategoryReductionChart extends ComparisonBase {

  @Prop()
  possibleUpstreamCategories = [] as CategoriesForSelect[]

  @Prop()
  possibleDownstreamCategories = [] as CategoriesForSelect[]

  plotData = {} as ReductionResultByCategory
  selCatArray = [] as SelectedCategory[]
  targetLastYear = 0
  chartOptions: any
  additionalClass = ''
  allCatSelected = false
  isScope3MAEC = false

  $refs!: {
    vueApexChartRef: VueApexChartsComponent
  }

  /** LIFECYCLE HOOKS **/
  beforeMount() {
    this.initChart()
    this.getChart()
    console.log('Scope3CategoryReductionChart chart initialized beforeMount')
  }

  mounted() {
    this.$nextTick(() => {
      console.log('Scope3CategoryReductionChart emit mounted')
      this.$emit('mounted')
    })
  }


  /** CHART COMMANDS **/
  updateChart(updatedPlotData : ReductionResultByCategory, selCatArray : SelectedCategory[], targetLastYear : number,
              allCatSelected: boolean, isScope3MAEC: boolean) {
    //console.log('Scope3CategoryReductionChart updateChart: update graph', targetLastYear)
    this.additionalClass = 'scope3_category_chart'
    this.plotData = updatedPlotData
    this.selCatArray = selCatArray
    this.targetLastYear = targetLastYear
    this.allCatSelected = allCatSelected
    this.isScope3MAEC = isScope3MAEC
    this.getChart()
  }

  scopeDefaultChartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60%',
        endingShape: 'rounded',
        dataLabels: {
          total: {
            enabled: true,
            formatter: function (val : any, {seriesIndex, dataPointIndex, w}: any) {
              if (dataPointIndex === 2) {
                //console.log("w:", w.config, "sIndex", seriesIndex)
                for (var i=0; i<w.config.series.length; i++) {
                  if (w.config.series[i].name === 'Offset') {
                    if (w.config.series[i].data[5]) {
                      //console.log("hit", w)
                      return w.config.series[i].data[5].toFixed(0)
                    } else {
                      return ''
                    }
                  }
                }
              } else if (dataPointIndex === 4 || dataPointIndex === 5) {
                return ''; // Return an empty string to hide the total label
              }
              return val.toFixed(0)

            },
            style: {
              fontSize: '11px',
              fontWeight: 600
            },
            decimalsInFloat: 2
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      title: {
        text: this.getContentfulString('shared.ton-co2e.no-format')
      },
      decimalsInFloat: 2
    },
    legend: {
      position: 'bottom'
    },
    fill: {
      opacity: 1
    },
  }

  getChart() {
    if (this.plotData
      && this.plotData.basisCalculation && this.plotData.basisCalculation.results
      && this.plotData.targetCalculation && this.plotData.targetCalculation.results) {
      const newSeries = [] as any[]
      let data : number[][] = []
      let sumTargetSelected = 0
      for (let i = 0; i <= 15; i++) {
        data[i] = []
      }
      // stacked barchart for basis
      if (Array.isArray(this.plotData.basisCalculation?.results)) {
        for (const result of this.plotData.basisCalculation.results) {
          this.populateCategories(data, result);
        }
      }
      // stacked barchart for target
      if (Array.isArray(this.plotData.targetCalculation?.results)) {
        for (const result of this.plotData.targetCalculation.results) {
          this.populateCategories(data, result);
          sumTargetSelected += result.categoryResult ?? 0;
        }
      }
      //console.log('data', data)
      for (let i = 1; i <= 15; i++) {
        if (data[i].length > 0) {
            newSeries.push({ name: this.getName(i), data: data[i] ?? 0, color: this.getColor(i)})
        }
      }
      let nonSelectedScope3Elevated = sumTargetSelected
      let outside = this.plotData.targetCalculation.scope3resultsOutsideCategories ?? 0
      // used to indicate where y-axis start
      if (this.isScope3MAEC && this.allCatSelected) {
        nonSelectedScope3Elevated = Math.abs(outside - sumTargetSelected)
      }
      const upperResultLabel = '' + this.plotData.targetCalculation.year + ': ' + graphPrettify(nonSelectedScope3Elevated) + ' ' + this.getContentfulString('forecast.columnchart.common.unit')
      const lowerResultLabel = '' + this.plotData.basisCalculation.year + ': ' + graphPrettify(this.targetLastYear) + ' ' + this.getContentfulString('forecast.columnchart.common.unit')
      let percentage = this.targetLastYear > 0 ? ((sumTargetSelected - outside - this.targetLastYear) * 100 / this.targetLastYear).toFixed(0) : '0'
      let diffElevated = this.targetLastYear
      if (Number(percentage) < 0) {
        diffElevated = sumTargetSelected
      }
      //console.log("diff info, diffElevated:", diffElevated, "percentage:", percentage, "allSelected:", this.allCatSelected, "isMac:", this.isScope3MAEC)
      let offset: number[] = [0, 0, nonSelectedScope3Elevated, 0, diffElevated, outside]
      newSeries.push({ name: 'Offset', data: offset, color: 'transparent', enableMouseTracking: false, showInLegend: false, tooltip:{enabled: false}})
      // special handling of non catagories items
      let scope3NonBar: number[] = [0, 0, outside, 0, 0]
      newSeries.push({ name: this.getName(16), data: scope3NonBar, color: this.getColor(16)})
      let targetBar: number[] = [0, 0, 0, this.targetLastYear, 0]
      newSeries.push({ name: this.getName(17), data: targetBar, color: this.getColor(17)})
      let diffBar: number[] = [0, 0, 0, 0, Math.abs(nonSelectedScope3Elevated - this.targetLastYear)]
      newSeries.push({ name: this.getName(18), data: diffBar, color: this.getColor(18)})

      if (newSeries.length === 0) {
        newSeries.push({ name: 'NA', data: [] })
      }
      const midResultLabel = (Number(percentage) > 0 ? '+' + Number(percentage) : percentage) + '%'
      const differenceIsLargerThan20Percent = Number(percentage) >= 20 || Number(percentage) <= -20

      const optionsUpdate = {
        xaxis: {
          categories: [
            this.plotData.basisCalculation.year,
            this.plotData.targetCalculation.year,
            this.getContentfulString('forecast.columnchart.label.not-in-target-scope3'),
            this.getContentfulString('forecast.columnchart.label.target') + ' ' + this.plotData.targetCalculation?.year,
            this.getContentfulString("forecast.columnchart.label.difference"),
            ''
          ],
          labels: {
            rotate: 0,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
             style: {
              fontSize: '11px'
            },
          }
        },
        series: newSeries,
        annotations: {
          position: 'front',
          points: [
            {
              x: this.getContentfulString("forecast.columnchart.label.difference"),
              y: this.targetLastYear,
              marker: {
                size: 2,
                fillColor: "#fff",
                strokeColor: "#333",
                strokeWidth: 3,
                shape: "circle",
                radius: 2,
                offsetY: 0,
                offsetX: 30,
                cssClass: '',
              },
              label: {
                textAnchor: 'start',
                offsetY: 15,
                offsetX: 45,
                borderColor: '#c2c2c2',
                borderWidth: 0,
                borderRadius: 2,
                text: lowerResultLabel,
                style: {
                  background: 'none',
                  color: '#777',
                  fontSize: '14px',
                  fontWeight: 600,
                  fontFamily: undefined,
                  cssClass: 'apexcharts-point-annotation-label',
                  textAlign: 'left',
                  padding: {
                    left: 5,
                    right: 5,
                    top: 0,
                    bottom: 2,
                  }
                },
              }
            },
            {
              x: this.getContentfulString("forecast.columnchart.label.difference"),
              y: nonSelectedScope3Elevated,
              marker: {
                size: 2,
                fillColor: "#fff",
                strokeColor: "#333",
                strokeWidth: 3,
                shape: "circle",
                radius: 2,
                offsetY: 0,
                offsetX: 30,
                cssClass: '',
              },
              label: {
                textAnchor: 'start',
                offsetY: 15,
                offsetX: 45,
                borderColor: '#c2c2c2',
                borderWidth: 0,
                borderRadius: 2,
                text: upperResultLabel,
                style: {
                  textAlign: 'left',
                  background: 'none',
                  color: '#777',
                  fontSize: '14px',
                  fontWeight: 600,
                  fontFamily: undefined,
                  cssClass: 'apexcharts-point-annotation-label',
                  padding: {
                    left: 5,
                    right: 5,
                    top: 0,
                    bottom: 2,
                  }
                },
              }
            },
            {
              x: this.getContentfulString("forecast.columnchart.label.difference"),
              y: (nonSelectedScope3Elevated - this.targetLastYear) / 2 + this.targetLastYear,
              marker: {
                size: 0,
                fillColor: "#fff",
                strokeColor: "#333",
                strokeWidth: 3,
                shape: "circle",
                radius: 2,
                offsetY: 0,
                offsetX: 30,
                cssClass: '',
              },
              label: {
                textAnchor: 'start',
                offsetY: differenceIsLargerThan20Percent ? 16 : -6,
                offsetX: 45,
                borderColor: '#c2c2c2',
                borderWidth: 0,
                borderRadius: 2,
                text: differenceIsLargerThan20Percent ? midResultLabel : [upperResultLabel, midResultLabel, lowerResultLabel],
                style: {
                  background: 'none',
                  color: '#777',
                  fontSize: differenceIsLargerThan20Percent ? '18px' : '14px',
                  fontWeight: 800,
                  fontFamily: undefined,
                  cssClass: 'apexcharts-point-annotation-label',
                  padding: {
                    left: 5,
                    right: 5,
                    top: 0,
                    bottom: 2,
                  }
                },
              }
            }

          ]
        }
      }
      let optionsForChart = Object.assign(optionsUpdate, this.scopeDefaultChartOptions)
      console.log('Scope3CategoryReductionChart newSeries', newSeries, optionsForChart)
      this.$refs.vueApexChartRef.updateOptions(optionsForChart, true, true, true)
    }
  }

  populateCategories(data : number[][], result : CategoryResult) {
    for (let i = 0; i <= 15; i++) {
      if (result.categoryName === 'CATEGORY_' + i) {
        data[i].push(result.categoryResult ?? 0)
      }
    }
  }

  getName(index: number) {
    if (index === 0) {
      return ''
    } else if (index === 1) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category1')
    } else if (index === 2) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category2')
    } else if (index === 3) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category3')
    } else if (index === 4) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category4')
    } else if (index === 5) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category5')
    } else if (index === 6) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category6')
    } else if (index === 7) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category7')
    } else if (index === 8) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category8')
    } else if (index === 9) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category9')
    } else if (index === 10) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category10')
    } else if (index === 11) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category11')
    } else if (index === 12) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category12')
    } else if (index === 13) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category13')
    } else if (index === 14) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category14')
    } else if (index === 15) {
      return this.getContentfulString('calculation.dropdown.possiblevalue.unit.category15')
    } else if (index === 16) {
      return this.getContentfulString('forecast.columnchart.label.not-in-target-scope3')
    } else if (index === 17) {
      return this.getContentfulString('forecast.columnchart.label.target') + ' ' + this.plotData.targetCalculation?.year
    } else if (index === 18) {
      return this.getContentfulString("forecast.columnchart.label.difference")
    } else {
      return ''
    }
  }

  getColor(catIndex: number) {
    if (catIndex === 1) {
      return Constants.GROUPING1_COLOR_HEX
    } else if (catIndex ===2) {
      return Constants.GROUPING2_COLOR_HEX
    } else if (catIndex ===3) {
      return Constants.GROUPING3_COLOR_HEX
    } else if (catIndex ===4) {
      return Constants.GROUPING4_COLOR_HEX
    } else if (catIndex ===5) {
      return Constants.GROUPING5_COLOR_HEX
    } else if (catIndex ===6) {
      return Constants.GROUPING6_COLOR_HEX
    } else if (catIndex ===7) {
      return Constants.GROUPING7_COLOR_HEX
    } else if (catIndex ===8) {
      return Constants.GROUPING8_COLOR_HEX
    } else if (catIndex ===9) {
      return Constants.GROUPING9_COLOR_HEX
    } else if (catIndex ===10) {
      return Constants.GROUPING10_COLOR_HEX
    } else if (catIndex ===11) {
      return Constants.GROUPING11_COLOR_HEX
    } else if (catIndex ===12) {
      return Constants.GROUPING12_COLOR_HEX
    } else if (catIndex ===13) {
      return Constants.GROUPING13_COLOR_HEX
    } else if (catIndex ===14) {
      return Constants.GROUPING14_COLOR_HEX
    } else if (catIndex ===15) {
      return Constants.GROUPING15_COLOR_HEX
    } else if (catIndex ===16) {
      return Constants.COLOR_GRAY_HEX
    } else if (catIndex ===17) {
      return Constants.COLOR_PUPLE_HEX
    } else if (catIndex ===18) {
      return Constants.COLOR_ROSE_HEX
    }
  }

  initChart() {
    this.chartOptions = {
      chart: {
        type: 'bar',
        stacked: false,
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '40%',
          endingShape: 'rounded',
          total: {
            decimalsInFloat: 2
          }
        }
      },
      xaxis: {
        categories: ['x']
      },
      yaxis: {
        title: {
          text: this.getContentfulString('shared.ton-co2e.no-format')
        },
        decimalsInFloat: 2
      },
      legend: {
        show: false,
        position: 'bottom'
      },
      fill: {
        opacity: 1
      },
      series: [
        {
          name: this.getContentfulString('comparison.resultview.scope1.text'),
          data: [0]
        }
      ]
    }
  }

}

</script>

<style lang="scss">
//Ugly css hack to hide dummy series legend
.scope3_category_chart {
  div.apexcharts-legend {
    div.apexcharts-legend-series:nth-of-type(3), div.apexcharts-legend-series:nth-of-type(6) {
      display: none;
    }
  }
}
</style>
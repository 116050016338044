import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, withModifiers as _withModifiers, resolveDirective as _resolveDirective, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container px-0 py-9" }
const _hoisted_2 = {
  key: 0,
  id: "success-box",
  class: "alert alert-success"
}
const _hoisted_3 = { class: "alert-body" }
const _hoisted_4 = { class: "alert-heading" }
const _hoisted_5 = { class: "alert-text" }
const _hoisted_6 = {
  key: 1,
  "aria-atomic": "true",
  class: "alert alert-error",
  role: "alert"
}
const _hoisted_7 = { class: "alert-body" }
const _hoisted_8 = { class: "alert-heading" }
const _hoisted_9 = { class: "alert-text" }
const _hoisted_10 = ["data-cy"]
const _hoisted_11 = { class: "py-1" }
const _hoisted_12 = { class: "py-3" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  key: 0,
  class: "py-4 my-0 form-group"
}
const _hoisted_15 = {
  class: "form-label",
  for: "input-type-company-email"
}
const _hoisted_16 = ["data-error", "pattern"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "py-4 my-0 form-group" }
const _hoisted_19 = {
  class: "form-label",
  for: "input-type-first-name"
}
const _hoisted_20 = ["data-error", "pattern"]
const _hoisted_21 = { class: "py-4 my-0 form-group" }
const _hoisted_22 = {
  class: "form-label",
  for: "input-type-last-name"
}
const _hoisted_23 = ["data-error", "pattern"]
const _hoisted_24 = { class: "py-4 my-0 form-group" }
const _hoisted_25 = {
  class: "form-label",
  for: "input-type-email"
}
const _hoisted_26 = ["data-error", "pattern"]
const _hoisted_27 = { class: "py-4 my-0 form-group" }
const _hoisted_28 = {
  class: "form-label",
  for: "input-type-country"
}
const _hoisted_29 = ["data-error"]
const _hoisted_30 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_31 = ["value", "id"]
const _hoisted_32 = {
  key: 2,
  class: "py-6"
}
const _hoisted_33 = { for: "checkbox-terms-consent" }
const _hoisted_34 = ["disabled"]
const _hoisted_35 = { class: "modal-body" }
const _hoisted_36 = { class: "full-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_RichText = _resolveComponent("RichText")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AcceptModal = _resolveComponent("AcceptModal")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Navigation),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, [
                _createVNode(_component_SimpleText, { "text-key": "contactInformation.successTitle" })
              ]),
              _createElementVNode("p", _hoisted_5, [
                _createVNode(_component_SimpleText, { "text-key": "contactInformation.successText" })
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.formValidation.formErrors.size)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, [
                _createVNode(_component_SimpleText, { "text-key": "sharedContent.formErrorTitle" })
              ]),
              _createElementVNode("ul", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formValidation.formErrors, ([key, value]) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: key,
                    "data-cy": 'error_' + key
                  }, _toDisplayString(value), 9, _hoisted_10))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("h1", null, _toDisplayString(_ctx.isPrivateUser ? _ctx.titlePrivateUser : _ctx.titleCorporateUser), 1),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", null, [
          _createVNode(_component_RichText, { "text-key": "contactInformation.text" })
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("form", {
          onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.formValidation.clearValidField && _ctx.formValidation.clearValidField(...args))),
          onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
          onInvalidCapture: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.formValidation.handleFormValidity && _ctx.formValidation.handleFormValidity(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_13, [
            (!_ctx.isPrivateUser)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("label", _hoisted_15, [
                    _createVNode(_component_SimpleText, { "text-key": "calculation.contactinfo.text.company-email" })
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    id: "input-type-company-email",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contactPerson.corporateEmail) = $event)),
                    "data-error": _ctx.emailError,
                    class: "form-input",
                    name: "input-type-email",
                    pattern: _ctx.findRegexPattern('regex.validation.e-mail'),
                    maxlength: "50",
                    minlength: "5",
                    required: "",
                    type: "email"
                  }, null, 8, _hoisted_16), [
                    [_vModelText, _ctx.contactPerson.corporateEmail]
                  ])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.isPrivateUser)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_17, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.contactinfo.text.contactperson" })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("label", _hoisted_19, [
                _createVNode(_component_SimpleText, { "text-key": "calculation.contactinfo.text.first-name" })
              ]),
              _withDirectives(_createElementVNode("input", {
                id: "input-type-first-name",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contactPerson.firstName) = $event)),
                "data-error": _ctx.firstNameError,
                class: "form-input",
                name: "input-type-first-name",
                pattern: _ctx.findRegexPattern('regex.validation.person-name'),
                required: "",
                type: "text"
              }, null, 8, _hoisted_20), [
                [_vModelText, _ctx.contactPerson.firstName]
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("label", _hoisted_22, [
                _createVNode(_component_SimpleText, { "text-key": "calculation.contactinfo.text.last-name" })
              ]),
              _withDirectives(_createElementVNode("input", {
                id: "input-type-last-name",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contactPerson.lastName) = $event)),
                "data-error": _ctx.lastNameError,
                class: "form-input",
                name: "input-type-last-name",
                pattern: _ctx.findRegexPattern('regex.validation.person-name'),
                required: "",
                type: "text"
              }, null, 8, _hoisted_23), [
                [_vModelText, _ctx.contactPerson.lastName]
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("label", _hoisted_25, [
                _createVNode(_component_SimpleText, { "text-key": "calculation.contactinfo.text.email" })
              ]),
              _withDirectives(_createElementVNode("input", {
                id: "input-type-email",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contactPerson.email) = $event)),
                "data-error": _ctx.emailError,
                class: "form-input",
                name: "input-type-email",
                pattern: _ctx.findRegexPattern('regex.validation.e-mail'),
                maxlength: "100",
                minlength: "5",
                required: "",
                type: "email"
              }, null, 8, _hoisted_26), [
                [_vModelText, _ctx.contactPerson.email]
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("label", _hoisted_28, [
                _createVNode(_component_SimpleText, { "text-key": "calculation.contactinfo.text.country" })
              ]),
              _withDirectives(_createElementVNode("select", {
                class: "form-select",
                name: "options",
                id: "input-type-country",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contactPerson.country) = $event)),
                "data-error": _ctx.countryError,
                required: ""
              }, [
                _createElementVNode("option", _hoisted_30, [
                  _createVNode(_component_SimpleText, { "text-key": "generic.input.dropdown.option-select.name" })
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryList, (country) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: country.code,
                    value: country.code,
                    id: country.code
                  }, _toDisplayString(country.name), 9, _hoisted_31))
                }), 128))
              ], 8, _hoisted_29), [
                [_vModelSelect, _ctx.contactPerson.country]
              ])
            ]),
            (!_ctx.hideConsentCheck)
              ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                  _withDirectives(_createElementVNode("input", {
                    id: "checkbox-terms-consent",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.termsEnabled) = $event)),
                    class: "form-checkbox checkbox-large",
                    name: "checkbox-terms-consent",
                    type: "checkbox"
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.termsEnabled]
                  ]),
                  _createElementVNode("label", _hoisted_33, [
                    _createElementVNode("strong", null, [
                      _createVNode(_component_SimpleText, { "text-key": "acceptterms.acceptTerms" })
                    ])
                  ]),
                  _createVNode(_component_router_link, {
                    to: `/terms-for-use`,
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SimpleText, { "text-key": "acceptterms.acceptTermsLink" })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", null, [
                    _createVNode(_component_SimpleText, { "text-key": "acceptterms.acceptTermsText" })
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("button", {
            id: "submit-button",
            class: "button button-primary px-8 my-4 saveButton",
            type: "submit",
            disabled: _ctx.disableSaveButton
          }, [
            _createVNode(_component_SimpleText, { "text-key": "sharedContent.save" })
          ], 8, _hoisted_34)
        ], 32)
      ])
    ]),
    (_ctx.showTermsModal)
      ? (_openBlock(), _createBlock(_component_AcceptModal, {
          key: 0,
          "title-text": _ctx.isPrivateUser ? _ctx.withdrawPrivateTitle : _ctx.withdrawCompanyTitle,
          "buttons-disabled": _ctx.disableTermsModalButtons,
          "button-ok-text-key": 'contact-information.terms-modal.button.ok',
          "button-reject-text-key": 'contact-information.terms-modal.button.cancel',
          onSelectOk: _ctx.yes,
          onSelectReject: _ctx.no
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_35, [
              _withDirectives(_createElementVNode("div", _hoisted_36, null, 512), [
                [_directive_dompurify_html, _ctx.documentToHtmlString(_ctx.isPrivateUser ? _ctx.withdrawPrivateText : _ctx.withdrawCompanyText)]
              ])
            ])
          ]),
          _: 1
        }, 8, ["title-text", "buttons-disabled", "button-ok-text-key", "button-reject-text-key", "onSelectOk", "onSelectReject"]))
      : _createCommentVNode("", true)
  ], 64))
}
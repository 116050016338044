<template>
  <SharedHeader :showCalculationRow="true" :tabMenu="menu">
    <template #firstColumn>
      <div v-dompurify-html="documentToHtmlString(pageProperties.headerText)"></div>
    </template>
    <template #secondColumn>
      <div aria-atomic="true" aria-label="Beskedbox der viser information" class="alert alert-info alert--show-icon"
           role="alert">
        <div class="alert-body">
          <p class="alert-heading">{{ pageProperties.importantInfoTitle }}</p>
          <p class="alert-text" v-dompurify-html="documentToHtmlString(pageProperties.importantInfoText)" />
        </div>
      </div>
    </template>
  </SharedHeader>
  <Breadcrumbs :scrollPosition="scrollPosition" :tabMenu="menu" :tabsAreVisible="tabsAreVisible" />
  <div :class="!tabsAreVisible && 'pt-905'" class="container">
    <div class="row py-6">
      <div class="col-12 px-0">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue"
import { Menu } from "@/shared/MenuStructure"
import CalculatorPage from "@/types/contentful-types/CalculatorPage"
import Breadcrumbs from "@/components/calculator/shared/Breadcrumbs.vue"
import { getScrollPosition } from "@/shared/ScrollPosition"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import { Prop, Watch } from "vue-property-decorator"
import SwitchToggle from "@/components/SwitchToggle.vue"

@Options({
  components: {
    SharedHeader,
    Breadcrumbs,
    SwitchToggle
  }
})
export default class CalculatorPageView extends BaseComponent {
  @Prop()
  menuSysId?: string
  @Prop()
  pagePropertiesSysId?: string

  menu = {} as Menu
  pageProperties = {} as CalculatorPage
  tabsAreVisible = true
  scrollPosition = [0, 0]
  tabsOutOfView = false
  methodSelection = true

  async beforeMount() {
    if(!this.menuSysId || !this.pagePropertiesSysId) {
      if(!this.menuSysId) console.warn('[KK ERROR] Missing contentful Menu SysId for routed page')
      if(!this.pagePropertiesSysId) console.warn('[KK ERROR] Missing contentful PageProperties SysId for routed page')
      return
    }
    this.updateMenu()
    this.pageProperties = await this.getPage<CalculatorPage>(this.pagePropertiesSysId)
  }

  tabsInViewPortEvent(visible: boolean) {
    this.tabsAreVisible = !visible
  }

  handleScroll() {
    const tabs = document.querySelector(".tabnav-kk")
    if (tabs) {
      const bottom = tabs.getBoundingClientRect().bottom
      if (bottom <= 0 && !this.tabsOutOfView) {
        this.tabsOutOfView = true
        this.tabsInViewPortEvent(true)
      } else if (bottom > 10 && this.tabsOutOfView) {
        this.tabsOutOfView = false
        this.tabsInViewPortEvent(false)
      }
    }
    this.scrollPosition = getScrollPosition()
  }


  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  }

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  @Watch("menuSysId")
  async watchMenu() {
    this.updateMenu()
  }

  private updateMenu() {
    if (this.menuSysId) {
      const foundMenu = this.store.getters.getMenuStructure.menuStructureMap.get(this.menuSysId)
      if (foundMenu) {
        this.menu = foundMenu
      } else {
        this.menu = {} as Menu
      }
    }
  }

  @Watch("pagePropertiesSysId")
  async watchPageProperties() {
    if(this.pagePropertiesSysId) {
      this.pageProperties = await this.getPage<CalculatorPage>(this.pagePropertiesSysId)
    }
  }
}
</script>

<template>
  <div class='container px-0 py-9' v-if="pageAccess">
    <h1>Statistik</h1>
    <form @submit.prevent='getStatistics()'>
      <fieldset>
        <legend>Statistik</legend>
        <div class='row'>
          <div class='col-md-2 form-button'>
            <button id='submit-button-forecast' type='submit' class='button button-primary adjust-button-size'>
              Opdater
            </button>
          </div>
        </div>
        <div v-if="recalculateErrors.length > 0">
          {{ recalculateErrors }}
        </div>
      </fieldset>
    </form>
    <section>
      <div class='table--responsive-scroll'>
        <table class='table table--selectable' id='selectable-table1'>
          <caption>statistik</caption>
          <thead>
          <tr>
            <th scope='col'>Navn</th>
            <th scope='col'>Beskrivelse</th>
            <th scope='col'>Værdi</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='stat in statistics' :key='stat.name'>
            <td>{{ stat.name }}</td>
            <td>{{ stat.description }}</td>
            <td>{{ stat.value }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
  <div class='container px-0 py-9' v-else>Din brugerprofil har ikke adgang til dette skærmbillede</div>
</template>

<script lang='ts'>
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import {Statistic, Company} from '../../../openapi/generated-clients/climatecompass'
import * as DKFDS from 'dkfds'
import {
  calculationClient,
  configurationClient,
  emissionsfactorImportClient,
  statisticsClient
} from '@/shared/BackendService'

export default class Statistics extends BaseComponent {
  statistics: Statistic[] = []
  recalculateErrors = ''
  pageAccess = false

  async getStatistics() {

    await this.loadStatistics().then(async (cname) => {
      this.eventHub.emit('start-loading', 'transfer-load-from')
      await this.store.dispatch('loadStatistics').finally(() => {
        this.eventHub.emit('stop-loading', 'transfer-load-from')
      }).then(() => {
        this.statistics = this.store.getters.getStatistics
      })
    })
  }

  async loadStatistics() {
    return {}
  }


  async beforeMount() {
    DKFDS.init()
    await configurationClient
        .getImportAccess()
        .then((response) => {
          this.pageAccess = response.data as unknown as boolean
        })
        .catch((error) => {
          console.error(error)
        })
  }

  updated() {
    DKFDS.init()
  }

}
</script>

<style scoped>
form {
  margin-top: 24px;
}

.calc-summary li:first-of-type {
  margin-top: 0;
}
</style>
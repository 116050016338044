<template>
  <div
      v-if="formValidation.formErrors.size"
      aria-atomic="true"
      class="alert alert-error"
      role="alert"
  >
    <div class="alert-body">
      <p class="alert-heading"><SimpleText text-key="sharedContent.formErrorTitle"/></p>
      <ul class="alert-text">
        <li
            v-for="[key, value] of formValidation.formErrors"
            :key="key"
            :data-cy="'error_' + key"
        >
          {{ value }}
        </li>
      </ul>
    </div>
  </div>

  <h2 class="h2"><SimpleText text-key="calculation.contactinfo.title"/></h2>
  <form
      v-if="companyInformation"
      @change="formValidation.clearValidField($event)"
      @submit.prevent="submitForm"
      @invalid.capture.prevent="formValidation.handleFormValidity($event, true)"
  >
    <div
        v-if="showAdressValidationWarning"
        aria-atomic="true"
        class="alert alert-warning"
        role="alert"
    >
      <div class="alert-body">
        <p class="alert-heading">Advarsel</p>
        <p class="alert-text">{{ adressValidationWarning }}</p>
      </div>
    </div>

    <!-- Felt start: Land -->
    <div
        :class="{
        'form-error': formValidation.formErrors.has('unknown-country')
      }"
        class="form-group mt-6"
    >
      <DataList
          v-model:value-selected="proposedSelectedCountry"
          :disabled="isPurpose1or2 || isReadOnly"
          :label="this.country"
          :options="countryList"
          @input-clicked="clearSelectedCountry()"
          @optionFoundSuccess="countrySelectOnBlur"
      />
      <div
          v-if="showCountryEmissionWarning"
          aria-atomic="true"
          class="alert alert-warning"
          role="alert"
      >
        <div class="alert-body">
          <p class="alert-heading">Advarsel</p>
          <p class="alert-text">{{ countryEmissionWarning }}</p>
        </div>
      </div>
    </div>
    <!-- modal - confirm change country -->
    <div class="fds-modal" id="modal-acceptedCountryChange" aria-hidden="true"
         role="dialog" aria-modal="true" aria-labelledby="modal-id-1-title"
         data-modal-forced-action>
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" id="modal-acceptedCountryChange-title"><SimpleText text-key="masterdatacompanyInformation.changeCountryModalTitle"/></h2>
        </div>
        <div class="modal-body">
          <p v-dompurify-html="changeCountryModalReplacedBodyText"></p>
        </div>
        <div class="modal-footer">
          <button class="button button-primary"
                  id="modal-acceptedCountryChange-yes"
                  @click="changeCountry"><SimpleText text-key="masterdatacompanyInformation.changeCountryModalButtonChange"/>
          </button>
          <button
              class="button button-secondary"
              id="modal-acceptedCountryChange-no"
              @click="revertChangeCountry"><SimpleText text-key="masterdatacompanyInformation.changeCountryModalButtonCancel"/>
          </button>
        </div>
      </div>
    </div>

    <!-- Felt slut: Land -->

    <!-- Felt start: Registreringsnummer -->
    <div class="form-group">
      <div v-if="selectedCountry.code === 'DK'" class="form-group">
        <label :for="isPurpose4 ? 'cvr' : 'cvr2'" class="form-label">
          <SimpleText text-key="calculation.masterdata.text.vat-no"/>
          <span class="ml-2"><Tooltip :text="this.cvrTooltip"/></span>
        </label>
        <span v-if="isPurpose1or2" id="cvr-hint" class="form-hint"><SimpleText text-key="masterdatacompanyInformation.cvrHint"/></span>
        <input
            v-if="isPurpose4"
            :id="isPurpose4 ? 'cvr' : 'cvr2'"
            v-model="companyInformation.vatNo"
            :disabled="isReadOnly"
            class="form-input"
            maxlength="8"
            name="cvr"
            type="number"
            @keyup="selectNoPNumbers(); loadPNumbers(); loadCompanyName();"
        />
        <input
            v-else
            :id="isPurpose4 ? 'cvr' : 'cvr2'"
            v-model="companyInformation.vatNo"
            :data-error="this.errorVatNr"
            :disabled="isPurpose1or2 || isReadOnly"
            :required="selectedCountry.code === 'DK'"
            class="form-input"
            name="cvr2"
            :pattern="findRegexPattern('regex.validation.vat-no')"
            type="number"
            @keyup="selectNoPNumbers(); loadPNumbers(); loadCompanyName();"
        />
      </div>
      <div v-else class="form-group">
        <label class="form-label" for="registration-number">
          <SimpleText text-key="masterdatacompanyInformation.registrationNumber"/>
          <span class="ml-2"
          ><Tooltip :text="this.registrationNumberTooltip"
          /></span>
        </label>
        <input
            id="registration-number"
            v-model="companyInformation.vatNo"
            :data-error="this.errorRegistrationNumber"
            :disabled="isReadOnly"
            class="form-input"
            maxlength="50"
            min="1"
            name="registration-number"
            type="text"
        />
      </div>
    </div>
    <!-- Felt slut: Registreringsnummer -->

    <!-- Felt start: Navn -->
    <div class="form-group">
      <label class="form-label" for="name">
        <SimpleText text-key="calculation.masterdata.text.company-name"/>
      </label>
      <input
          id="name"
          v-model="companyInformation.companyName"
          :data-error="this.errorCompanyName"
          :disabled="isPurpose1or2 || isReadOnly"
          class="form-input"
          name="name"
          :pattern="findRegexPattern('regex.validation.company-name')"
          type="text"
      />
    </div>
    <!-- Felt slut: Navn -->
    <CompanyInformationAddresses
        v-if="selectedCountry.code !== 'DK'"
        :addresses="calculationAddresses"
        :pCountryCode="selectedCountry.code"
        :pCountryName="selectedCountry.name"
        @addressValidationWarning="onAddressValidationWarning"
        @deleteAddress="onDeleteCalculationAddress"
        @saveAddress="onSaveCalculationAddress"
    />
    <div
        v-if="selectedCountry.code === 'DK'"
        :class="{
        'form-error':
          formValidation.formErrors.has('p-number') ||
          formValidation.formErrors.has('no-p-numbers')
      }"
        class="form-group"
    >
      <fieldset>
        <legend class="h5">
          <SimpleText text-key="calculation.masterdata.text.p-number"/>
          <span class="ml-2"
          ><Tooltip :text="this.pNumberTooltip"
          /></span>
        </legend>

        <a
            v-if="!isReadOnly"
            id="select-all-p-numbers"
            class="function-link"
            href="#"
            @click.prevent="selectAllPNumbers()"
        >
          <SimpleText text-key="sharedContent.selectAll"/>
        </a>

        <a
            v-if="!isReadOnly"
            id="deselect-all-p-numbers"
            class="function-link ml-305"
            href="#"
            @click.prevent="selectNoPNumbers()"
        >
          <SimpleText text-key="sharedContent.deselectAll"/>
        </a>

        <ul class="nobullet-list p-number-list">
          <div v-if="showLoader" class="spinner"/>
          <li></li>
          <li v-for="(pNumber) in pNumbers" :key="pNumber.pNumber">
            <input
                :id="`checkbox-${pNumber.pNumber}`"
                :aria-checked="isPNumberSelected(pNumber)"
                :checked="isPNumberSelected(pNumber)"
                :disabled="isReadOnly"
                :value="pNumber"
                class="form-checkbox"
                name="checkbox-small[]"
                type="checkbox"
                @input="onPNumberCheckChanged(pNumber,$event.target.checked)"
            />
            <label :for="`checkbox-${pNumber.pNumber}`">{{
                pNumber.pNumber + " - " + formatAddressDK(pNumber.address)
              }}</label>
          </li>
        </ul>
      </fieldset>
    </div>


    <h2 class="h2"><SimpleText text-key="calculation.mainresult.text.keynumbers"/></h2>
    <p><SimpleText text-key="calculation.mainresult.text.keynumbers.description2"/></p>
    <!-- Felt start: Ansatte -->
    <GenericInput
        id="employees"
        v-model="this.numberOfEmployees"
        :disabled="isReadOnly"
        :hintText="this.employeesHint"
        :lable="getContentfulString('calculation.masterdata.text.number-employees')"
        :max="9999999"
        :min="0"
        :showOptionalLabel="true"
        :step="0.01"
        :validationError="this.errorEmployees"
        :regex="findRegexPattern('regex.validation.nummeric.unsigned.max-9-digits.max-2-decimals')"
        @value-invalid="(arg1, arg2) => applyCompanyInformationValidationError(arg1, arg2)"
        @value-valid="(arg1) => applyCompanyInformationValidationOk(arg1)"
        type="DECIMAL"
        :avoid-auto-formatting-decimals="true"
    />

    <!-- Felt slut: Ansatte -->

    <!-- Felt start: Omsætning -->
    <GenericInput
        id="revenue"
        v-model="this.turnover"
        :disabled="isReadOnly"
        :hintText="this.turnoverHint"
        :lable="getContentfulString('calculation.masterdata.text.revenue')"
        :max="9999999999999.99"
        :min="0"
        :showOptionalLabel="true"
        :step="0.01"
        :validationError="this.errorTurnover"
        :regex="findRegexPattern('regex.validation.nummeric.unsigned.max-17-digits.max-2-decimals')"
        @value-invalid="(arg1, arg2) => applyCompanyInformationValidationError(arg1, arg2)"
        @value-valid="(arg1) => applyCompanyInformationValidationOk(arg1)"
        type="DECIMAL"
        inputmode="numeric"
        :avoid-auto-formatting-decimals="true"
    />
    <!-- Felt slut: Omsætning -->

    <!-- Felt start: bygningsareal -->
    <GenericInput
        id="area"
        v-model="this.buildingSquareMeterArea"
        :disabled="isReadOnly"
        :hintText="this.buildingAreaHint"
        :lable="getContentfulString('calculation.masterdata.text.building-area')"
        :max="999999999.99"
        :min="0"
        :showOptionalLabel="true"
        :step="0.01"
        :validationError="this.errorArea"
        :regex="findRegexPattern('regex.validation.nummeric.unsigned.max-11-digits.max-2-decimals')"
        @value-invalid="(arg1, arg2) => applyCompanyInformationValidationError(arg1, arg2)"
        @value-valid="(arg1) => applyCompanyInformationValidationOk(arg1)"
        type="DECIMAL"
        inputmode="numeric"
        :avoid-auto-formatting-decimals="true"
    />
    <!-- Felt slut: bygningsareal -->

    <button
        v-if="!isReadOnly"
        id="submit-button"
        :disabled="isReadOnly"
        class="button button-primary mt-705"
        type="submit"
    >
      <SimpleText text-key="sharedContent.next"/>
    </button>
    <button
        v-if="isReadOnly"
        id="submit-button"
        :disabled="!isReadOnly"
        class="button button-primary mt-705"
        to="/calculator/energy-and-processes/electricity"
    >
      <SimpleText text-key="sharedContent.next"/>
    </button>
    <div class="mt-6">
      <router-link
          id="back-link"
          class="back-link"
          to="/calculator/master-data/purpose"
      >
        <SimpleText text-key="sharedContent.back"/>
      </router-link>
    </div>
  </form>

</template>

<script lang="ts">
import {Options} from "vue-class-component"
import {Watch} from "vue-property-decorator"
import {
  Address,
  AddressInfo,
  Calculation,
  CompanyInformation,
  Country,
  ProductionUnit
} from "openapi/generated-clients/climatecompass"
import {Cloneable} from "@/shared/DeepCopyHelper"
import {FormValidation} from "@/shared/FormValidation"
import {useRouter} from "vue-router"
import {addressClient, calculationClient, companyClient, countryClient, pNumberClient} from "@/shared/BackendService"
import GenericInput from "@/components/calculator/GenericInput.vue"
import DataList from "@/components/DataList.vue"
import CompanyInformationAddresses from "@/components/calculator/master-data/CompanyInformationAddresses.vue"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import DKFDS from "dkfds";
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import Chart from "chart.js";
import SimpleText from "@/components/SimpleText.vue";
import {
  prettify,
  simpleLocaleSpecificStringOrNumberToNumber
} from "@/shared/PrettifyNumbers";

interface SubmitEvent extends Event {
  submitter: HTMLElement;
}

@Options({
  components: {
    SimpleText,
    DataList,
    GenericInput,
    CompanyInformationAddresses
  }
})
export default class CompanyInformationComponent extends BaseComponent {
  router = useRouter()
  pNumbers: ProductionUnit[] = [] as ProductionUnit[]
  showLoader = false
  showCountryEmissionWarning = false
  showAdressValidationWarning = false
  adressValidationWarning = ""
  isReadOnly = this.calculation.state === "readonly"
  formValidation = new FormValidation()
  companyInformation = {
    country: this.calculation.companyInformation?.country
        ? this.calculation.companyInformation?.country
        : "DK"
  } as CompanyInformation
  selectedPNumbers: ProductionUnit[] = [] as ProductionUnit[]
  isPurpose4 = this.calculation.calculationPurpose?.purpose === "studyOrOther"
  calculationAddresses: Address[] = []
  countryEmissionWarning = ""
  isPurpose1or2 = (
      this.calculation.calculationPurpose?.purpose === "realClimateFootprint" ||
      this.calculation.calculationPurpose?.purpose === "CO2reducingMeasures"
  )
  isPurpose1or2or3 = (
      this.calculation.calculationPurpose?.purpose === "realClimateFootprint" ||
      this.calculation.calculationPurpose?.purpose === "CO2reducingMeasures" ||
      this.calculation.calculationPurpose?.purpose === "forecast"
  )
  isPurpose3or4 = (
      this.calculation.calculationPurpose?.purpose === "forecast" ||
      this.calculation.calculationPurpose?.purpose === "studyOrOther"
  )

  selectedCountry = {name: "", code: ""} as Country
  originalSelectedCountry?: Country
  newSelectedCountry = {name: "", code: ""} as Country
  changeCountryModalReplacedBodyText = ""
  acceptedCountryChange = true

  countryList: Country[] = []

  clearSelectedCountry() {
    if (!this.originalSelectedCountry) {
      this.originalSelectedCountry = this.selectedCountry
    }
    this.selectedCountry = {name: "", code: ""} as Country
  }

  set proposedSelectedCountry(country: Country) {
    if (country.code != this.originalSelectedCountry?.code) {
      this.newSelectedCountry = country
      this.changeCountryModalReplacedBodyText = documentToHtmlString(this.changeCountryModalBody)
          .replaceAll("#countryNameFrom#", (this.originalSelectedCountry?.name ? this.originalSelectedCountry.name : ""))
          .replaceAll("#countryNameTo#", (this.newSelectedCountry?.name ? this.newSelectedCountry.name : ""))
      const modal = new DKFDS.Modal(document.getElementById("modal-acceptedCountryChange"))
      modal.init()
      modal.show()
    } else {
      this.selectedCountry = country
      this.originalSelectedCountry = undefined
    }
  }

  get proposedSelectedCountry() {
    return (this.newSelectedCountry?.name ? this.newSelectedCountry : this.selectedCountry)
  }

  changeCountry() {
    this.selectedCountry = this.newSelectedCountry || {name: "", code: ""} as Country
    this.newSelectedCountry = {name: "", code: ""} as Country
    this.originalSelectedCountry = undefined
    const modal = new DKFDS.Modal(document.getElementById("modal-acceptedCountryChange"))
    modal.hide()
    this.selectNoPNumbers()
    this.clearAdresses()
    if (this.selectedCountry.code === "DK") {
      this.fetchDKCvr()
      this.loadCompanyName()
      this.loadPNumbers()
    } else {
      this.selectNoVatAndCompanyName()
    }
  }

  revertChangeCountry() {
    this.selectedCountry = this.originalSelectedCountry || {name: "", code: ""} as Country
    this.newSelectedCountry = {name: "", code: ""} as Country
    this.originalSelectedCountry = undefined
    const modal = new DKFDS.Modal(document.getElementById("modal-acceptedCountryChange"))
    modal.hide()
  }

  get calculation() {
    if (this.store.getters.getCurrentCalculation) {
      //Deep copy the current calculation if it exist
      return Cloneable.deepCopy<Calculation>(
          this.store.getters.getCurrentCalculation
      )
    }
    return ({
      companyInformation: {} as CompanyInformation
    } as Calculation) as Calculation
  }

  async loadPNumbers() {
    if (this.isPurpose3or4 && this.selectedCountry.code) {
      this.companyInformation.country = this.selectedCountry.code
    }
    if (this.companyInformation.country === "DK") {
      const vatnr = String(this.companyInformation.vatNo)
      if (vatnr?.length !== 8) {
        // clear pnumbers
        this.pNumbers = []
        if (!this.isPurpose4) {
          this.companyInformation.companyName = ""
        }
        return
      }
      let data
      try {
        this.showLoader = true
        data = await pNumberClient.getProductionUnits(vatnr.valueOf())
        this.formValidation.formErrors.delete("no-p-numbers")
      } catch (error) {
        this.formValidation.formErrors.set(
            "no-p-numbers",
            this.errorNoPNumbers
        )
      } finally {
        this.pNumbers = data?.data.productionUnits || []

        /*pNumbers.value.forEach((element) => {
          element.address.id = undefined;
          element.id = undefined;
        });*/

        this.showLoader = false
      }
    }
  }

  async loadCalculationAddresses() {
    if (this.calculation.id) {
      if (this.thirdpartyInfo.loggedInVatNr) {
        const data = (await addressClient.getAddresses(this.calculation.id, this.thirdpartyInfo.loggedInVatNr)).data
        const addresses = data.addresses
        this.calculationAddresses = addresses ? addresses : []
      } else {
        const data = (await addressClient.getAddresses(this.calculation.id, 'null')).data
        const addresses = data.addresses
        this.calculationAddresses = addresses ? addresses : []
      }

    }
  }

  async loadCompanyName() {
    if (this.companyInformation.vatNo?.toString().length !== 8) {
      this.companyInformation.companyName = ""
      return
    }
    let data
    try {
      data = await companyClient.fetchCompany(this.companyInformation.vatNo)
      this.formValidation.formErrors.delete("no-company-name")
    } catch (error) {
      this.formValidation.formErrors.set(
          "no-company-name",
          this.errorNoCompanyName
      )
    } finally {
      this.companyInformation.companyName = data?.data.name || ""
    }
  }

  selectAllPNumbers() {
    this.selectedPNumbers = [...this.pNumbers]
  }

  selectNoPNumbers(): void {
    this.selectedPNumbers = []
  }

  selectNoVatAndCompanyName(): void {
    this.companyInformation.vatNo = ""
    this.companyInformation.companyName = ""
    this.formValidation.formErrors.delete("no-company-name")
  }

  clearAdresses(): void {
    this.calculationAddresses.forEach((address: Address) => {
      addressClient.deleteAddress(address.id!, this.extraVatNo())
    })
    this.calculationAddresses = []
  }

  extraVatNo() {
    if (this.thirdpartyInfo && this.thirdpartyInfo.loggedInVatNr) {
      return this.thirdpartyInfo.loggedInVatNr
    } else {
      return this.calculation.vatNo ? this.calculation.vatNo : 'null'
    }
  }

  async beforeMount() {
    if (!this.calculation?.id) {
      this.$router.push("/calculator/master-data/purpose") // Hop direkte til opret beregning-siden, hvis der ikke er valgt en beregning, da der så ikke kan oprettes virksomhedsoplysninger.
    } else {
      const calc = (await calculationClient.getCalculation(this.calculation.id, this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null')).data || {}
      if (calc && this.calculation && this.calculation.companyInformation) {
        // kk2-2812 not sure why this is needed, po is saved fine in db but the currentCalculation in session is not updated so a db access fixes this,
        // but should not be needed
        this.calculation.companyInformation.productionUnits = calc.companyInformation?.productionUnits
      }

      if (this.calculation.companyInformation) {
        this.companyInformation = this.calculation.companyInformation
        this.calculation.companyInformation.vatNo = this.calculation.companyInformation.vatNo?.trim()

        this.selectedPNumbers =
            this.calculation.companyInformation.productionUnits || []

        /*
        selectedPNumbers.value.forEach((element) => {
          element.address.id = undefined;
          element.id = undefined;
        });
        */
      }

      if (
          !this.store.getters.isPrivateUser &&
          !this.companyInformation.vatNo &&
          !this.isPurpose4
      ) {
        this.fetchDKCvr()
      }
      await this.loadPNumbers()

      if (!this.companyInformation.companyName) {
        await this.loadCompanyName()
      }

      try {
        const countries = await countryClient.fetchCountries()
        if (countries.data.countries) {
          for (let i=0; i < countries.data.countries.length; i++) {
            const name = this.store.getters.getContentfulContent.findSimpleText(countries.data.countries[i].contentfulId)
            countries.data.countries[i].name = name
          }
          this.countryList = countries.data.countries.sort((a, b) =>
              ("" + a.name).localeCompare("" + b.name)
          )
        }
      } catch (error) {
        console.error(error)
      }
      const country = this.countryList.find(
          country => country.code === this.companyInformation.country
      )
      if (country) {
        this.selectedCountry = country
      }

      await this.loadCalculationAddresses()

      this.setDocumentTitleByKey("menu.masterdatatabs.item.company-information")
    }
  }

  fetchDKCvr() {
    if (this.thirdpartyInfo.behalfOfVatNr) {
      this.companyInformation.vatNo = this.thirdpartyInfo.behalfOfVatNr
    } else {
      this.companyInformation.vatNo = this.store.getters.getCurrentUser.profile["dk:gov:cvr"]
    }
  }

  get thirdpartyInfo() {
    return this.store.getters.getThirdpartyInfo
  }

  async mounted() {
    await this.$nextTick(() =>
        document
            .getElementsByClassName("tabnav-kk")
            .item(0)
            ?.scrollIntoView(true)
    )
  }

  scrollToFormErrors(element: HTMLElement) {
    this.$nextTick(() =>
        this.formValidation
            .getParentContainerElement(element)
            .scrollIntoView(true)
    )
  }

  applyCompanyInformationValidationError(element: HTMLInputElement, errortext: string) {
    this.formValidation.formErrors.set(element.id, errortext)
    this.scrollToFormErrors(element)
  }

  applyCompanyInformationValidationOk(element: HTMLInputElement) {
    this.formValidation.formErrors.delete(element.id)
  }

  set numberOfEmployees(employees: string) {
    this.companyInformation.numberOfEmployees = simpleLocaleSpecificStringOrNumberToNumber(employees)
  }

  get numberOfEmployees() {
    return prettify(this.companyInformation.numberOfEmployees) ?? ""
  }

  set turnover(turnover: string) {
    this.companyInformation.turnover = simpleLocaleSpecificStringOrNumberToNumber(turnover)
  }

  get turnover() {
    return prettify(this.companyInformation.turnover) ?? ""
  }

  set buildingSquareMeterArea(buildingSquareMeterArea: string) {
    this.companyInformation.buildingSquareMeterArea = simpleLocaleSpecificStringOrNumberToNumber(buildingSquareMeterArea)
  }

  get buildingSquareMeterArea() {
    return prettify(this.companyInformation.buildingSquareMeterArea) ?? ""
  }

  async submitForm(e: Event) {
    const submitter = e as SubmitEvent
    if (submitter.submitter === document.getElementById("modal-acceptedCountryChange-yes")
        || submitter.submitter === document.getElementById("modal-acceptedCountryChange-no")) {
            return
    }
    let hasFormValidationErrors = false
    if (this.formValidation.formErrors.has("unknown-country")) {
      this.scrollToFormErrors(e.target as HTMLElement)
      return
    }
    this.formValidation.formErrors.clear()

    if (this.isPurpose1or2or3 && !this.selectedPNumbers.length && this.selectedCountry.code === "DK") {
      this.formValidation.formErrors.set("p-number", this.errorPNumber)
      this.scrollToFormErrors(e.target as HTMLElement)
      return
    }
    const sVatNo = String(this.companyInformation.vatNo)
    if (this.selectedCountry.code === "DK" &&
        ((!this.isPurpose4 && sVatNo.length !== 8) ||
            (this.isPurpose4 && !(!this.companyInformation.vatNo || sVatNo.length === 8)))) {
      this.formValidation.formErrors.set("cvr", this.errorVatNr)
      const element = e.target as HTMLElement
      await this.$nextTick(() =>
          this.formValidation
              .getParentContainerElement(element)
              .scrollIntoView(true)
      )
      return
    } else if (this.selectedCountry.code !== "DK" && this.companyInformation.vatNo && this.companyInformation.vatNo?.length > 50) {
      this.formValidation.formErrors.set("cvr", this.errorRegistrationNumber)
      this.scrollToFormErrors(e.target as HTMLElement)
      return
    } else if (!sVatNo) { //&& !this.isPurpose4) {
      this.formValidation.formErrors.set("registration-number", this.errorRegistrationNumber)
      hasFormValidationErrors = true
    }

    if (!this.companyInformation.companyName) { // && !this.isPurpose4) {
      this.formValidation.formErrors.set("name", this.errorCompanyName)
      hasFormValidationErrors = true
    }

    if (hasFormValidationErrors) {
      this.scrollToFormErrors(e.target as HTMLElement)
      return
    }

    // assign the selected country to the CompanyInformation object so that it can be assigned to the calculation
    this.companyInformation.country = this.selectedCountry.code
    this.calculation.companyInformation = this.companyInformation

    // don't add the P-numbers if not DK otherwise they will duplicate since they already exist as "fakes"
    if (this.selectedCountry.code === "DK") {
      this.calculation.companyInformation.productionUnits = this.selectedPNumbers
      // if address is null we get a bad_request from setCalculation. We could let address be null in climate-compass-api but this gave a lot of other
      // problems so this fix seems the best. If cannot be fixed in BE
      for (const po of this.calculation.companyInformation.productionUnits) {
        po.address.calculationActive = true
        if (po.address.city === null) {
          po.address.city = ""
        }
      }
    } else {
      this.calculation.companyInformation.productionUnits = []
    }

    if (this.thirdpartyInfo.behalfOfVatNr) {
      this.calculation.vatNo = this.thirdpartyInfo.behalfOfVatNr
      this.calculation.companyInformation.vatNo = this.thirdpartyInfo.behalfOfVatNr
    }
    if (this.thirdpartyInfo.behalfOfContactpersonRid) {
      this.calculation.contactPersonRID = this.thirdpartyInfo.behalfOfContactpersonRid
    }

    //console.log("submit1 cal", this.calculation.companyInformation.productionUnits, "sel", this.selectedPNumbers)
    await this.store.dispatch("setCalculation", this.calculation)
    //console.log("submit2 cal", this.calculation.companyInformation.productionUnits, "sel", this.selectedPNumbers)
    await this.store.getters.getCurrentCalculation
    this.router.push("/calculator/energy-and-processes")

    this.$nextTick(() =>
        this.formValidation
            .getParentContainerElement(
                document.getElementsByClassName("nav-primary").item(0) as HTMLElement
            )
            .scrollIntoView(true)
    )
  }

  @Watch("selectedCountry", {deep: false})
  async onSelectedCountry(newValue: Country, oldValue: Country) {
    if (newValue.code) {
      const emissionWarning = !(
          await calculationClient.checkEmission(
              newValue.code!,
              new Date(this.calculation.calculationPurpose.startDate).getFullYear()
          )
      ).data.all
      this.showCountryEmissionWarning = emissionWarning!
      this.countryEmissionWarning = this.countryWarning.replaceAll(
          "%",
          newValue.name + ""
      )
    }
  }

  formatAddress(addressInfo: AddressInfo) {
    return `
        ${addressInfo.address?.street},
        ${addressInfo.address?.placeName ? addressInfo.address.placeName + "," : ""}
        ${addressInfo.address?.zipCode ? addressInfo.address.zipCode : ""}
        ${addressInfo.address?.city ? addressInfo.address.city + "," : ""}
        ${addressInfo.address?.state ? addressInfo.address.state + "," : ""}
        ${addressInfo.country}
    `
  }

  async onAddressValidationWarning(warningObject: any) {
    const warningString = warningObject + ""

    if (warningString.length === 0) {
      this.showAdressValidationWarning = false
    } else {
      this.showAdressValidationWarning = true
    }
    this.adressValidationWarning = warningObject
  }

  async onSaveCalculationAddress(address: Address) {
    address.calculationId = this.calculation.id
    let response
    const addressInfo: AddressInfo = {
      address,
      country: this.selectedCountry.code,
      vatNo: this.calculation.companyInformation?.vatNo,
      companyName: this.calculation.companyInformation?.companyName,
      calculationVatNo: this.calculation.vatNo,
      calculationBehalfOfVatNo: this.thirdpartyInfo.loggedInVatNr
    }

    this.formValidation.formErrors.delete("unknown-address")

    if (address.id) {
      response = await addressClient.updateAddress(addressInfo)
    } else {
      response = await addressClient.createAddress(addressInfo)
    }
    if (response.status === 201) {
      this.loadCalculationAddresses()
    }
  }

  async onDeleteCalculationAddress(address: Address) {
    await addressClient.deleteAddress(address.id!, this.extraVatNo())
    await this.loadCalculationAddresses()
    // @Johnny: hvis du inkluderer updateCalculation duplikerer den alle "fake" p-numre
    //await calculationClient.updateCalculation(calculation.value);
    const calculation2 = (await calculationClient.getCalculation(this.calculation.id!, this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null')).data || {}
    this.store.dispatch("setCurrentCalculation", calculation2)
  }

  countrySelectOnBlur(success: boolean) {
    if (success) {
      this.formValidation.formErrors.delete("unknown-country")
    } else {
      this.formValidation.formErrors.set("unknown-country", "Ukendt land")
    }
  }

  isPNumberSelected(pNumber: ProductionUnit) {
    return Boolean(this.selectedPNumbers.find(
        selectedPNumber => selectedPNumber.pNumber === pNumber.pNumber
    ))
  }

  onPNumberCheckChanged(pNumber: ProductionUnit, checked: boolean) {
    if (checked) {
      this.selectedPNumbers.push(pNumber)
    } else {
      this.selectedPNumbers.splice(this.selectedPNumbers.findIndex(pu => pu.pNumber === pNumber.pNumber), 1)
    }
  }

  get changeCountryModalBody() {
    return this.store.getters.getContentfulContent.findRichText('masterdatacompanyInformation.changeCountryModalBody')
  }

  get country() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.country')
  }

  get cvrTooltip() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.cvrTooltip')
  }

  get errorVatNr() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.errorVatNr')
  }

  get registrationNumberTooltip() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.registrationNumberTooltip')
  }

  get errorRegistrationNumber() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.errorRegistrationNumber')
  }

  get errorCompanyName() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.errorCompanyName')
  }

  get pNumberTooltip() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.pNumberTooltip')
  }

  get employeesHint() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.employeesHint')
  }

  get errorEmployees() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.errorEmployees')
  }

  get turnoverHint() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.turnoverHint')
  }

  get errorTurnover() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.errorTurnover')
  }

  get buildingAreaHint() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.buildingAreaHint')
  }

  get errorArea() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.errorArea')
  }

  get errorNoPNumbers() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.errorNoPNumbers')
  }

  get errorNoCompanyName() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.errorNoCompanyName')
  }

  get errorPNumber() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.errorPNumber')
  }

  get countryWarning() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdatacompanyInformation.countryWarning')
  }

}
</script>

<style>
.form-input,
.form-select {
  max-width: 49rem !important;
}

.p-number-list {
  max-height: 32rem;
  overflow-y: auto;
  max-width: 49rem !important;
  padding-left: 5px !important;
}
</style>

<template>
  <div class='container px-0 py-9' v-if="pageAccess">
    <h1>Overførsel af beregning</h1>
    <p>Angiv fra CVR/CPR eller beregningsnavn/virksomhedsnavn/email. Er CVR/CPR angivet ignores det andet søgefelt.
    '%' tegn kan angives som wildcard i start og/eller slut af 2. fra input felt</p>
    <p>Bemærk at hvis en beregning har fremskrivninger flyttes disse også</p>
    <h2>Fra og til</h2>
    <form @submit.prevent='getCompanies()'>
      <fieldset>
        <legend>Fra og til</legend>
        <div class='row'>
          <div class='col-md-3' id='choose-forecastname-div'>
            <div class='form-group'>
              <label class='form-label' for='input-choose-transferfrom'>
                Fra (CVR/CPR):
              </label>
              <input class='form-input' id='input-choose-transferfrom' type='number' v-model='transferFromVat' />
            </div>
            <div class='form-group'>
              <label class='form-label' for='input-choose-transfersearch'>
                Fra (beregn.navn/virk.navn/email):
              </label>
              <input class='form-input' id='input-choose-transfersearch' type='string' v-model='transferFromSearch' />
            </div>
          </div>
          <div class='col-md-3'>
            <div class='form-group'>
              <label class='form-label' for='input-choose-transferto'>
                Til virksomhed (CVR):
              </label>
              <input class='form-input' id='input-choose-transferto' type='number' v-model='transferToVat' />
            </div>
          </div>
          <div class='col-md-2 form-button'>
            <button id='submit-button-forecast' type='submit' class='button button-primary adjust-button-size'>
              Find
            </button>
          </div>
        </div>
      </fieldset>
    </form>
    <section>
      <h2>Beregninger</h2>
      <div class='table--responsive-scroll'>
        <table class='table table--selectable' id='selectable-table1'>
          <caption>Beregninger</caption>
          <thead>
          <tr>
            <th>
              <input id='selectable-table1-check-all' type='checkbox' class='form-checkbox'
                     :aria-controls="getAriaControls">
              <label for='selectable-table1-check-all'><span class='sr-only'>Vælg alle rækker</span></label>
            </th>
            <th scope='col'>Beregningsnavn</th>
            <th scope='col'>Firmanavn</th>
            <th scope='col'>CVR/Pogoident</th>
            <th scope='col'>Startdato</th>
            <th scope='col'>Slutdato</th>
            <th scope='col'>Oprettet</th>
            <th scope='col'>Status</th>
            <th scope='col'>Basis for fremskrivninger</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='calculation in userCalculations' :key='calculation.id'>
            <td>
              <input :id="'input_' + calculation.id" type='checkbox' name='selectable-table1-check[]'
                     class='form-checkbox' :value="'input_' + calculation.id" @change='getSelectedCalculations'>
              <label :for="'input_' + calculation.id"><span class='sr-only'>Vælg række</span></label>
            </td>
            <td>{{ calculation.calculationPurpose.name }}</td>
            <td>{{ calculation.companyInformation.companyName }}</td>
            <td>{{ calculation.vatNo }}</td>
            <td>{{ formatDate(calculation.calculationPurpose.startDate) }}</td>
            <td>
              {{ formatDate(new Date(calculation.calculationPurpose.startDate).getFullYear() + calculation.calculationPurpose.endDate) }}
            </td>
            <td>{{ formatDate(calculation.createdTime) }}</td>
            <td>{{ calculation.state.toString() }}</td>
            <td>{{ calculation.isBasisForForecast ? 'Ja' : 'Nej' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>
    <form  @submit.prevent='initTransfer()'>
      <fieldset>
        <legend>Start overførsel</legend>
        <div class='row'>
        <div class='col-md-5 form-button'>
          <button
              id='submit-button-transfer' type='submit'
              class='button button-primary adjust-button-size'
              :disabled='isDisabledButton()'>

            Påbegynd overførsel
          </button>
        </div>
        </div>
      </fieldset>
    </form>
    <div v-if='showConfirm' class='spinner-overlay'>
      <div aria-hidden='false' class='fds-modal'>
        <div class='modal-content'>
          <div class='modal-header'>
            <h2 class='modal-title'>
              Bekræft overførsel
            </h2>
          </div>
          <div class='modal-body'>
            <div class='row' data-cy='load-forecast-wrapper-div'>
              <div class='col-md-4' id='choose-forecastname-div'>
                <strong>Fra:</strong><br />
                {{ transferFromCompany.vatNo }}<br />
                {{ transferFromCompany.name }}
              </div>
              <div class='col-md-4'>
                <strong>Beregninger:</strong>
                <ul style='margin-top: 0' class='calc-summary'>
                  <li v-for='calculation in calculationsToTransfer' :key='calculation.id'>
                    {{ calculation.calculationPurpose?.name }}
                  </li>
                </ul>
              </div>
              <div class='col-md-4'>
                <strong>Til:</strong><br />
                {{ transferToCompany.vatNo }}<br />
                {{ transferToCompany.name }}
              </div>
            </div>
          </div>

          <div class='modal-footer'>
            <button class='button button-primary' @click='doTransfer()'>
              Bekræft og overfør
            </button>
            <button class='button button-primary' @click='doCancel()'>
              Afbryd
            </button>
          </div>
        </div>
      </div>
      <div id='modal-backdrop' class='modal-backdrop'></div>
    </div>
  </div>
  <div class='container px-0 py-9' v-else>Din brugerprofil har ikke adgang til dette skærmbillede</div>
</template>

<script lang='ts'>
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import { Calculation, Company } from '../../../openapi/generated-clients/climatecompass'
import * as DKFDS from 'dkfds'
import { calculationClient, companyClient, configurationClient } from '@/shared/BackendService'

export default class Transfer extends BaseComponent {
  transferFromVat = ''
  transferToVat = ''
  transferFromSearch = ''
  userCalculations: Calculation[] = []
  transferFromCompany: Company = {}
  transferToCompany: Company = {}
  calculationsToTransfer: Calculation[] = []
  showConfirm = false
  isActive = true
  pageAccess = false


  isDisabledButton() {
    console.log("isDisbaled", this.calculationsToTransfer.length, this.transferFromCompany.name, this.transferToCompany.name)
    return this.calculationsToTransfer.length === 0 || !this.transferFromCompany.name || !this.transferToCompany.name
  }

  getAriaControls() {
    return this.userCalculations.map(calculation => 'input_' + calculation.id).join(' ');
  }
  async getCompanies() {
    console.log("getCompanies from:", this.transferFromVat, "to:", this.transferToVat)
    if (this.transferToVat) {
      await this.loadCompany(Number(this.transferToVat)).then(async (cname) => {
        console.log("loadCompany to", this.transferToVat, cname)
        this.transferToCompany = cname
      })
    }

    await this.loadCompany(Number(this.transferFromVat)).then(async (cname) => {
      console.log("loadCompany from:", this.transferFromVat, cname)
      this.eventHub.emit('start-loading', 'transfer-load-from')
      this.transferFromCompany = cname
      if (this.isEmpty(this.transferFromCompany)) {
        const fake = {} as Company
        fake.vatNo = this.transferFromVat
        fake.name = "Privat"
        this.transferFromCompany = fake
      }
      let from = this.transferFromVat
      if (from === undefined || from === '') {
        from = 'null'
      }
      let search = this.transferFromSearch
      if (search === undefined || search === '') {
        search = 'null'
      }
      console.log("getCalculationsForAdmin", from, search)
      await calculationClient.getCalculationsForAdmin(from, search).then((response) => {
        this.userCalculations = []
        if (response.data.calculations) {
          response.data.calculations.forEach((cal: Calculation) => {
              this.userCalculations.push(cal)
          })
        }
        this.eventHub.emit('stop-loading', 'transfer-load-from')
      })
    })
  }

  isEmpty(value : any) {
    return value && Object.keys(value).length === 0;
  }

  async loadCompany(vatId: number) {
    let result = {}

    await companyClient.fetchCompany(vatId.toString().trim()).then((response) => {
      if (response.data.name) {
        result = response.data
      }
    })
    return result
  }

  getSelectedCalculations() {
    this.calculationsToTransfer = []
    this.userCalculations.forEach((cal: Calculation) => {
      const calcInput = document.getElementById('input_' + cal.id) as HTMLInputElement
      if (calcInput && calcInput.checked && cal) {
        this.calculationsToTransfer.push(cal)
      }
    })
  }

  doTransfer() {
    const calculationIdsToTransfer = this.calculationsToTransfer.map((x) => x.id)
    console.log('Transfering: ' + calculationIdsToTransfer)
    console.log('From: ' + this.transferFromVat)
    console.log('To: ' + this.transferToVat)
    for (const item of this.calculationsToTransfer) {
      if (item.id) {
        calculationClient.updateCalculationWithCVR(item.id, this.transferToVat);
      }
    }
    this.userCalculations = []
    this.calculationsToTransfer = []
    this.showConfirm = false
  }

  doCancel() {
    this.showConfirm = false
  }
  initTransfer() {
    this.showConfirm = true
    this.getSelectedCalculations()
    const calculationIdsToTransfer = this.calculationsToTransfer.map((x) => x.id)
  }

  async beforeMount() {
    DKFDS.init()
    await configurationClient
        .getMoveCalculationAccess()
        .then((response) => {
          this.pageAccess = response.data as unknown as boolean
        })
        .catch((error) => {
          console.error(error)
        })

  }

  updated() {
    DKFDS.init()
  }
}
</script>

<style scoped>
form {
  margin-top: 24px;
}

.calc-summary li:first-of-type {
  margin-top: 0;
}
</style>
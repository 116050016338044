<template>

  <div v-if="accesses.some(access=>(access.expireDate>currentDate && !access.revoked) || (!access.expireDate && !access.revoked))" class="container px-0 py-9">
    <h1><SimpleText text-key="thirdparty.has-access-to.givenAccessHeaderList"/></h1>
    <table :id="idWasGivenAccessToTableCurrent" aria-label="oversigt over dine adgang" class="table w-percent-100">
      <thead>
      <tr v-if="tableHeaders" class="bg-primary text-color-white">
        <template v-for="tableHeader in tableHeaders">
          <th
              v-if="tableHeader.sortKey"
              :id="`table-header-${tableHeader.id}-${idWasGivenAccessToTableCurrent}`"
              :key="tableHeader.sortKey"
              class="clickable"
              scope="col"
              @click="sort(tableHeader.sortKey)"
          >
            {{ tableHeader.headline }}
            <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
              <path
                  :fill="currentSort === tableHeader.sortKey && currentSortDirection === 'asc' ? 'white' : ''"
                  clip-rule="evenodd"
                  d="M9.99419 4.75003L6.00003 0.755859L2.00586 4.75003H9.99419Z"
                  fill-rule="evenodd"
                  stroke="white"
              />
              <path
                  :fill="currentSort === tableHeader.sortKey && currentSortDirection === 'desc' ? 'white' : ''"
                  clip-rule="evenodd"
                  d="M2.00586 7.25003L6.00003 11.2442L9.99419 7.25003H2.00586Z"
                  fill-rule="evenodd"
                  stroke="white"
              />
            </svg>
          </th>
          <th
              v-if="!tableHeader.sortKey"
              :id="`table-header-${tableHeader.id}-${idWasGivenAccessToTableCurrent}`"
              :key="tableHeader.id"
              class="clickable"
              scope="col"
          >
            {{ tableHeader.headline }}
          </th>
        </template>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="acc in sortedAccesses.filter(x=>(x.expireDate>currentDate && !x.revoked) || (!x.expireDate && !x.revoked))" :key="acc.id" :data-cy-id="acc.vatIdSource">
        <td data-title="Cvr" style="width: 10%">
          <!-- we must not show cpr -->
          {{ acc.privateSource ? privateUserText : acc.vatId }}
        </td>
        <td data-title="CompanyName" style="width: 25%">
          {{ acc.privateSource ? acc.nameSource : acc.companyNameSource }}<br>
          <div id="access-request-status-wrapper" class="m-0">
          <span v-if="hasStep2Pending(acc)" id="step-two-pending-notice" class="access-action-pending">
            {{hasStep2Pending(acc) ? this.getContentfulString('thirdparty.step2.notification.access-awaiting-request-response') : ''}}
          </span>
          <span v-if="hasRevokedAccess(acc)" id="access-revoked-notice" class="access-action-pending">
              {{hasRevokedAccess(acc) ? this.getContentfulString('thirdparty.step2.notification.access-revoked') : ''}}
          </span>
          <span v-if="compAHasRejectedAccess(acc)" id="access-denied-notice" class="access-action-pending">
              {{compAHasRejectedAccess(acc) ? this.getContentfulString('thirdparty.step2.notification.access-request-denied') : ''}}
          </span>
          </div>
        </td>
        <td style="width: 25%">
          {{ acc.contantPersonName }} <br> {{ acc.contactPersonEmail }}
        </td>
        <td v-if="acc.expireDate"
            data-title="EndData"
            style="width: 10%"
            v-dompurify-html="formatDate(acc.expireDate)"
        />
        <td v-else
            data-title="EndData"
            style="width: 10%"
        />
        <td data-title="Calculations" style="width: 35%">
          <strong><SimpleText text-key="thirdparty.has-access-to.draft"/></strong> <br>
          <div v-for="calc in acc.calculationInfoList" :key="calc.calculationId">
            <span v-if="calc.isKladde" class="tab">{{ `${calc.calculationName}` }}</span>
          </div>
          <strong><SimpleText text-key="thirdparty.has-access-to.completed"/></strong> <br>
          <div v-for="calc in acc.calculationInfoList" :key="calc.calculationId">
            <span v-if="!calc.isKladde" class="tab">{{ `${calc.calculationName}` }}</span>
          </div>
        </td>
        <td data-title="Handlinger">
          <div class="overflow-menu overflow-menu--open-left">
            <button
                :class="`button-overflow-menu`"
                :data-js-target="`access-${acc.id}-actions-overflow`"
                aria-expanded="false"
                aria-haspopup="true"
                aria-label="handlinger"
                class="button-overflow-menu js-dropdown "
            >
              <svg aria-hidden="true" class="icon-svg" fill="white" focusable="false">
                <use xlink:href="#more-vert"></use>
              </svg>
            </button>
            <div :id="`access-${acc.id}-actions-overflow`" aria-hidden="true" class="overflow-menu-inner">
              <!-- List of possible events to apply to a row -->
              <ul class="overflow-list">
                <li>
                  <router-link v-if="hasStep2Access(acc)" class="edit-button" to="/overview" @click="useAccess(acc)">
                    <SimpleText text-key="access.utilize-access"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#people-alt"></use>
                    </svg>
                  </router-link>
                </li>
                <li>
                  <button v-if="hasStep1Access(acc) ||
                  hasRevokedAccess(acc) ||
                  hasAccessDeletedCompA(acc) ||
                  compAHasRejectedAccess(acc)" class="access-action-pending"  @click="requestAccess(acc)">
                    <SimpleText text-key="access.request-access"/>
                    <svg aria-hidden="true" class="icon-svg ml-3 " focusable="false">
                      <use xlink:href="#people-alt"></use>
                    </svg>
                  </button>
                </li>
                <li>
                  <button v-if="!hasRevokedAccess(acc)" class="delete-button" @click="confirmDeleteAccess(acc)">
                    <SimpleText text-key="access.remove-access"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#trash-can"></use>
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div id="no-access-given-message" v-else class="container px-0 py-9">
    <p><SimpleText text-key="thirdparty.has-access-to.noGivenAccess"/></p>
  </div>

    <!-- Following table filters and shows expired access only  -->

    <div v-if="accesses.filter(x=>x.expireDate<=currentDate && !x.revoked).length > 0"  class="container px-0 py-9">
      <h1><SimpleText text-key="thirdparty.has-access-to.expiredAccessTableHeader"/></h1>
      <table :id="idWasGivenAccessToTableExpired" aria-label="oversigt over dine adgang" class="table w-percent-100">
        <thead>
        <tr v-if="tableHeaders" class="bg-primary text-color-white">
          <template v-for="tableHeader in tableHeaders">
            <th
                v-if="tableHeader.sortKey"
                :id="`table-header-${tableHeader.id}-${idWasGivenAccessToTableExpired}`"
                :key="tableHeader.sortKey"
                class="clickable"
                scope="col"
                @click="sort(tableHeader.sortKey)"
            >
              {{ tableHeader.headline }}
              <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
                <path
                    :fill="currentSort === tableHeader.sortKey && currentSortDirection === 'asc' ? 'white' : ''"
                    clip-rule="evenodd"
                    d="M9.99419 4.75003L6.00003 0.755859L2.00586 4.75003H9.99419Z"
                    fill-rule="evenodd"
                    stroke="white"
                />
                <path
                    :fill="currentSort === tableHeader.sortKey && currentSortDirection === 'desc' ? 'white' : ''"
                    clip-rule="evenodd"
                    d="M2.00586 7.25003L6.00003 11.2442L9.99419 7.25003H2.00586Z"
                    fill-rule="evenodd"
                    stroke="white"
                />
              </svg>
            </th>
            <th
                v-if="!tableHeader.sortKey"
                :id="`table-header-${tableHeader.id}-${idWasGivenAccessToTableExpired}`"
                :key="tableHeader.id"
                class="clickable"
                scope="col"
            >
              {{ tableHeader.headline }}
            </th>
          </template>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="acc in sortedAccesses.filter(x=>x.expireDate<=currentDate && !x.revoked)" :key="acc.id"  :data-cy-id="acc.vatIdSource">
          <td data-title="Cvr" style="width: 10%">
            <!-- we must not show cpr -->
            {{ acc.privateSource ? privateUserText : acc.vatId }}
          </td>
          <td data-title="CompanyName" style="width: 25%">
            {{ acc.privateSource ? acc.nameSource : acc.companyNameSource }}
            <p><strong>{{ acc.revoked ? accessRejectedText + ' ' + formatDate(acc.revokedDate) : ''}}</strong></p>
          </td>
          <td style="width: 25%">
            {{ acc.contantPersonName }} <br> {{ acc.contactPersonEmail }}
          </td>
          <td v-if="acc.expireDate"
              data-title="EndData"
              style="width: 10%">

            {{ formatDate(acc.expireDate) }}
            <br/>
            <span v-if="!acc.revoked" class="badge badge-small"><SimpleText text-key="thirdparty.has-access-to.expiredTag"/></span>
            <span v-else class="badge badge-small"><SimpleText text-key="thirdparty.has-access-to.rejectedTag"/></span>
          </td>
          <td v-else
              data-title="EndData"
              style="width: 10%"
          />
          <td data-title="Calculations" style="width: 35%">
            <strong><SimpleText text-key="thirdparty.has-access-to.draft"/></strong> <br>
            <div v-for="calc in acc.calculationInfoList" :key="calc.calculationId">
              <span v-if="calc.isKladde" class="tab">{{ `${calc.calculationName}` }}</span>
            </div>
            <strong><SimpleText text-key="thirdparty.has-access-to.completed"/></strong> <br>
            <div v-for="calc in acc.calculationInfoList" :key="calc.calculationId">
              <span v-if="!calc.isKladde" class="tab">{{ `${calc.calculationName}` }}</span>
            </div>
          </td>
          <td data-title="Handlinger">
            <div class="overflow-menu overflow-menu--open-left">
              <button
                  :class="`button-overflow-menu`"
                  :data-js-target="`access-${acc.id}-actions-overflow`"
                  aria-expanded="false"
                  aria-haspopup="true"
                  aria-label="handlinger"
                  class="button-overflow-menu js-dropdown "
              >
                <svg aria-hidden="true" class="icon-svg" fill="white" focusable="false">
                  <use xlink:href="#more-vert"></use>
                </svg>
              </button>
              <div :id="`access-${acc.id}-actions-overflow`" aria-hidden="true" class="overflow-menu-inner">
                <!-- List of possible events to apply to a row -->
                <ul class="overflow-list">
                  <li>
                    <router-link v-if="!acc.revoked && !acc.expireDate>currentDate" class="edit-button" to="/overview" @click="useAccess(acc)">
                      <SimpleText text-key="access.utilize-access"/>
                      <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                        <use xlink:href="#people-alt"></use>
                      </svg>
                    </router-link>
                  </li>
                  <li>
                    <button class="delete-button" @click="confirmDeleteAccess(acc)">
                      <SimpleText text-key="access.remove-access"/>
                      <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                        <use xlink:href="#trash-can"></use>
                      </svg>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>


  </div>

  <!-- Delete confirmation modal START-->
  <template v-if="accessToDelete.id && accessToDelete.id > 0">
    <AcceptModal
        :value-object="accessToDelete"
        :title-key="'thirdparty.has-access-to.delete-modal.title'"
        :buttons-disabled="disableDeleteModalButtons"
        :button-ok-text-key="'thirdparty.has-access-to.delete-modal.button.ok'"
        :button-reject-text-key="'thirdparty.has-access-to.delete-modal.button.cancel'"
        @select-ok="deleteAccess($event)"
        @select-reject="resetAccessToDelete()"
    >
      <div class="modal-body">
        <p>
          <strong><SimpleText :text-key="this.revokeContentfulKey" /></strong>
          <br><br>{{ accessToDelete.privateSource ? accessToDelete.nameSource : accessToDelete.companyNameSource }} (CVR={{ accessToDelete.privateSource ? privateUserText : accessToDelete.vatId }})<br><br>
          <SimpleText :text-key="'thirdparty.was-given-access-to.delete-modal.text2'"/>
        </p>
      </div>
    </AcceptModal>
  </template>
  <!-- Delete confirmation modal END-->
</template>

<script lang="ts">
import {useRouter} from "vue-router"
import {Options} from "vue-class-component"
import Navigation from "@/components/thirdparty/Navigation.vue"
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue";
import {Menu} from "@/shared/MenuStructure"
import TableHeader from "@/types/TableHeader"
import {
  AccessIndividualInfo,
  AccessIndividualInfoStatusEnum,
  AccessInfo, RequestIndividualInfo
} from "openapi/generated-clients/climatecompass"
import ThirdpartyInfo from "@/types/ThirdpartyInfo"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import DKFDS from "dkfds";
import HasAccessTo from "@/components/thirdparty/HasAccessTo.vue";
import ToastMessage from "@/types/ToastMessage";
import AcceptModal from "@/components/AcceptModal.vue";
import SimpleText from "@/components/SimpleText.vue";
import {accessClient} from "@/shared/BackendService";

@Options({
  components: {
    Navigation,
    SharedHeader,
    AcceptModal,
    SimpleText
  }
})
export default class WasGivenAccessTo extends BaseComponent {
  menu = {} as Menu
  router = useRouter()
  tableHeaders = [] as TableHeader[]
  currentSort = "cvr"
  currentSortDirection = "asc"
  accessInfos: AccessInfo[] = []
  idWasGivenAccessToTableCurrent = "was-given-access-to-table-current"
  idWasGivenAccessToTableExpired = "was-given-access-to-table-expired"
  accessToDelete: AccessInfo = {} as AccessInfo
  disableDeleteModalButtons = false
  sorted: AccessInfo[] = []
  thirdPartyInfo = {} as ThirdpartyInfo
  revokeContentfulKey = ''

  async beforeMount() {
    this.menu = this.store.getters.getMenuStructure.accessTabs
    this.tableHeaders = [
      {headline: this.labelThCvr, sortKey: "cvr", id: "cvr"},
      {headline: this.labelThCompanyname, sortKey: "companyName", id: "companyName"},
      {headline: this.labelThContactinfo, sortKey: "contactInfo", id: "contactInfo"},
      {headline: this.labelThEnddate, sortKey: "endDate", id: "period"},
      {headline: this.labelThCalculations, sortKey: "", id: "calculations"}
    ]
    this.getAccessFromDb()
  }

  getAccessFromDb() {
    this.store.dispatch("loadGivenAccesses").finally(() => {
      this.eventHub.emit("stop-loading", "WasGivenAccessTo")
    })
    this.accessInfos = this.store.getters.getGivenAccesses
  }

  get expiredTag() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.expiredTag')
  }

  get privateUserText() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.private-user')
  }

  get accessRejectedText () {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.access.rejected-message')
  }

  get labelThCalculations() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.labelThCalculations')
  }

  get labelThEnddate() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.labelThEnddate')
  }

  get labelThContactinfo() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.labelThContactinfo')
  }

  get labelThCompanyname() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.labelThCompanyname')
  }

  get labelThCvr() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.labelThCvr')
  }

  get accesses() {
    this.accessInfos = this.store.getters.getGivenAccesses
    return this.accessInfos
  }

  get sortedAccesses() {
    return [...this.accessInfos].sort(this.compare)
  }

  get currentDate() {
    return new Date().toISOString().substr(0, 10)
  }

  compare(a: AccessInfo, b: AccessInfo) {
    const locale = this.store.getters.getLocale
    let sortOrderModifier = 1
    if (this.currentSortDirection === "desc") {
      sortOrderModifier = -1
    }
    if (this.currentSort === "cvr") {
      if (a.vatId && b.vatId) {
        if (a.vatId < b.vatId) {
          return 1 * sortOrderModifier
        }
        if (a.vatId > b.vatId) {
          return -1 * sortOrderModifier
        }
      }
      return 0
    }
    if (this.currentSort === "companyName") {
      if (a.companyName && b.companyName) {
        return a.companyName.localeCompare(b.companyName, locale) * sortOrderModifier
      }
      return 0
    }
    if (this.currentSort === "contactInfo") {
      if (a.contantPersonName && b.contantPersonName) {
        return a.contantPersonName.localeCompare(b.contantPersonName, locale) * sortOrderModifier
      }
      return 0
    }
    if (this.currentSort === "endDate") {
      if (a.expireDate && b.expireDate) {
        if (a.expireDate < b.expireDate) {
          return 1 * sortOrderModifier
        }
        if (a.expireDate > b.expireDate) {
          return -1 * sortOrderModifier
        }
      }
      return 0
    }
    return 0
  }

  sort(sortBy: string) {
    if (sortBy === this.currentSort) {
      this.currentSortDirection = this.currentSortDirection === "asc" ? "desc" : "asc"
    }
    this.currentSort = sortBy
  }

  requestAccess(access: AccessInfo) {
    if (access) {
      const requestInfo = {} as RequestIndividualInfo
      requestInfo.rid = this.store.getters.getCurrentUser.profile['sub']
      requestInfo.name = this.store.getters.getCurrentUser.profile.name
      requestInfo.email = this.store.getters.getCurrentUser.profile.email
      requestInfo.accessId = access.id
      if (access.individualsAccessInfoList) {
        for (const individualAccessInfo of access.individualsAccessInfoList) {
          if (individualAccessInfo.rid === requestInfo.rid) {
            requestInfo.id = individualAccessInfo.id
          }
        }
      }
      accessClient.requestIndividualAccess(requestInfo)
      this.getAccessFromDb()
    }
  }

  async useAccess(access: AccessInfo) {
    this.thirdPartyInfo.behalfOfVatNr = access.vatId
    this.thirdPartyInfo.behalfOfCompanyName = access.companyNameSource + ''
    this.thirdPartyInfo.behalfOfIsPrivate = access.privateSource ? true : false
    this.thirdPartyInfo.loggedInVatNr = this.store.getters.getCurrentUser.profile['dk:gov:cvr'] || this.store.getters.getCurrentUser.profile['dk:gov:cpr']
    this.thirdPartyInfo.loggedInCompanyName = this.store.getters.getCurrentUser.profile['name']
        if (access.calculationInfoList && access.calculationInfoList.length > 0 && access.calculationInfoList[0].contactpersonRid) {
      this.thirdPartyInfo.behalfOfContactpersonRid = access.calculationInfoList[0].contactpersonRid
    }
    this.thirdPartyInfo.loggedInContactpersonRid = this.store.getters.getCurrentUser.profile['sub']

    await this.store.dispatch("setThirdPartyInfo", this.thirdPartyInfo)
    //this.showToastAndDelayHide(access)
    //Emits toast arguments, to be caught by header, post page transition to /overview

    let message = JSON.stringify(this.onBehalfOfToastMessage)
    if (this.thirdPartyInfo.behalfOfIsPrivate) {
      const indexOfCompanyNameEnd = message.indexOf("#companyName#") + '#companyName#'.length
      const indexOfVatNoEnd = message.indexOf("#vatNo#") + '#vatNo#'.length
      if(indexOfCompanyNameEnd > 0 && indexOfVatNoEnd > 0) {
        message = message.replaceAll(message.slice(indexOfCompanyNameEnd, indexOfVatNoEnd), '')
      }
    } else {
      message = message.replaceAll('#vatNo#', this.thirdPartyInfo.behalfOfVatNr)
      message = message.replaceAll('#companyName#', this.thirdPartyInfo.behalfOfCompanyName)
    }
    if(this.thirdPartyInfo.behalfOfIsPrivate) {
      message = message.replaceAll('#companyName#', this.privateUserText)
    } else {
      message = message.replaceAll('#companyName#', this.thirdPartyInfo.behalfOfCompanyName)
    }
    message = message.replaceAll('"', '')

    this.addToast('AccessToast',
        'info',
        this.onBehalfOfToastHeader,
        message,
        true,
        true
    )
    this.eventHub.emit("start-loading", "CalculationsOverview")
    await this.store.dispatch("loadCalculations").finally(() => {
      this.eventHub.emit("stop-loading", "CalculationsOverview")
    })
    await this.router.push("/overview")
  }

  confirmDeleteAccess(access: AccessInfo) {
    this.accessToDelete = access

    if (this.getIndividuals(access) === -1) {
      this.revokeContentfulKey = 'thirdparty.was-given-access-to.delete-modal.text1b'
    } else {
      this.revokeContentfulKey = 'thirdparty.was-given-access-to.delete-modal.text1'
    }
  }

  getIndividuals(access: AccessInfo) {
    const rid = this.store.getters.getCurrentUser.profile['sub']
    if (access && access.individualsAccessInfoList) {
      for (const individualAccessInfo of access.individualsAccessInfoList) {
        if (individualAccessInfo.rid === rid && individualAccessInfo.id) {
          return individualAccessInfo.id
        }
      }
    }
    return -1

  }
  get thirdpartyInfo() {
    return this.store.getters.getThirdpartyInfo
  }

  resetAccessToDelete() {
    this.accessToDelete = {} as AccessInfo
    this.disableDeleteModalButtons = false
  }

  get isPrivateUser() {
    return this.accessToDelete?.privateSource ? true : false
  }

  hasStep2Pending(access: AccessInfo) {
    const rid = this.store.getters.getCurrentUser.profile['sub']
    if (access && access.individualsAccessInfoList) {
      for (const individualAccessInfo of access.individualsAccessInfoList) {
        if (individualAccessInfo.status === 'ACCESS_PENDING' && individualAccessInfo.rid === rid) {
          return true;
        }
      }
    }
    return false;
  }

  hasStep1Access(access: AccessInfo) {
    const rid = this.store.getters.getCurrentUser.profile['sub']
    if (access) {
      if (access.individualsAccessInfoList?.length === 0) {
        return true
      }
      if (access.individualsAccessInfoList) {
        let hit = false
        for (const individualAccessInfo of access.individualsAccessInfoList) {
          if (individualAccessInfo.rid === rid) {
            hit = true
          }
          //console.log("hasStep1Access", individualAccessInfo.status, individualAccessInfo.rid, rid)
        }
        if (!hit) {
          return true
        }
      }
    }
    return false;
  }

  hasRevokedAccess(access: AccessInfo) {
    const rid = this.store.getters.getCurrentUser.profile['sub']
    if (access && access.individualsAccessInfoList) {
      for (const individualAccessInfo of access.individualsAccessInfoList) {
        if (individualAccessInfo.status === 'ACCESS_REVOKED' && individualAccessInfo.rid === rid) {
          //console.log("hasRevokedAccess true", individualAccessInfo.status, individualAccessInfo.rid, rid)
          return true;
        }
      }
    }
    return false;
  }

  compAHasRejectedAccess(access: AccessInfo) {
    const rid = this.store.getters.getCurrentUser.profile['sub']
    if (access && access.individualsAccessInfoList) {
      for (const individualAccessInfo of access.individualsAccessInfoList) {
        if (individualAccessInfo.status === 'ACCESS_REJECTED' && individualAccessInfo.rid === rid) {
          return true;
        }
      }
    }
    return false;
  }

  hasAccessDeletedCompA(access: AccessInfo) {
    const rid = this.store.getters.getCurrentUser.profile['sub']
    if (access && access.individualsAccessInfoList) {
      for (const individualAccessInfo of access.individualsAccessInfoList) {
        if (individualAccessInfo.status === 'ACCESS_DELETED' && individualAccessInfo.rid === rid) {
          return true;
        }
      }
    }
    return false;
  }

  hasStep2Access(access: AccessInfo) {
    const rid = this.store.getters.getCurrentUser.profile['sub']
    if (access && access.individualsAccessInfoList) {
      for (const individualAccessInfo of access.individualsAccessInfoList) {
        if (individualAccessInfo.status === 'ACCESS_GRANTED' && individualAccessInfo.rid === rid) {
          //console.log("hasStep2Access true", individualAccessInfo.status, individualAccessInfo.rid, rid)
          return true;
        }
      }
    }
    //console.log("hasStep2Access false", rid)
    return false;
  }

  async deleteAccess(access?: AccessInfo) {
    if (!access?.id) {
      return
    }
    const individualAccessId = this.getIndividuals(access)

    this.disableDeleteModalButtons = true
    //await this.store.dispatch("clearThirdPartyInfo")
    this.eventHub.emit("start-loading", "HasAccessTo")
    await accessClient.revokeAccess(access.id, individualAccessId)
    this.getAccessFromDb()
    this.resetAccessToDelete()
  }

  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

  get onBehalfOfToastHeader() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.onBehalfOfToastHeader')
  }

  get onBehalfOfToastMessage() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.onBehalfOfToastMessage')
  }

}
</script>

<style lang="scss" scoped>

.tab {
  margin-left: 20px;
}

.clickable {
  cursor: pointer;
}

.table {
  border: 2px solid $color-primary;
}

.table th {
  border-right-color: white;
}

.table th,
.table td {
  border-bottom: 1px solid $color-primary;
}

.table th:nth-last-child(2),
.table td:nth-last-child(2) {
  border-right: none;
}

.table th:last-child,
.table td:last-child {
  color: white;
  background-color: $color-primary;
  border-right: none;
  border-bottom: 1px solid white;
  width: 1rem;
  padding: 8px 0;
}

.overflow-menu .button-overflow-menu {
  min-width: 0;
  min-height: 0;
  padding: 0;
}

.location .button-overflow-menu {
  text-decoration: underline;
}

.location .button-overflow-menu:focus {
  font-weight: bold;
}

.overflow-menu-inner li {
  text-align: left;
  //padding: 0.25rem 1rem;
}

.overflow-menu-inner {
  overflow-y: auto;
  max-height: 40rem;
}

.disabled {
  opacity: 0.3;
}

.access-action-pending.with-right-border {
  border-right: 1rem solid #febb30 !important;
}

div p strong {
  background-color: #f8e5be !important;
  border-color:#febb30 !important;
}

</style>


<template>
  <div id="pre-login-text" class="container py-9">
    <RichText text-key="login.loginText"/>
  </div>
</template>

<script lang="ts">
import router from "@/router"
import store from "@/store"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { computed } from "vue"
import {mapActions, useStore} from "vuex"
import { Vue, Options, mixins } from "vue-class-component"
import { Document } from "@contentful/rich-text-types"
import { version } from "../../package.json"
import { ContactPerson } from '../../openapi/generated-clients/climatecompass'
import { getCookie } from '@/shared/CookieHelper'
import RichText from "@/components/RichText.vue";

@Options({
  components: {RichText},
  methods: {
    ...mapActions("oidcStore", ["oidcSignInCallback"])
  }
})
export default class Auth extends Vue {
  oidcSignInCallback: any
  store = useStore()

  get version() {
    return version
  }
  documentToHtmlString(val: Document) {
    return documentToHtmlString(val)
  }

  async mounted() {

    const returnUrl = computed(() => store.getters.returnUrlSessionRefresh)
    if (this.oidcSignInCallback) {
      this.oidcSignInCallback()
        .then(async (redirectPath: any) => {
          const storedReturnUrl = returnUrl.value
          await store.dispatch("setReturnUrlSessionRefresh", "")
          if(this.store.getters.getUserClientLoadTimestamp === '0'){
            const timestampNow = Date.now()
            await this.store.dispatch("setUserClientLoadTimestamp", timestampNow)
            //console.log("Setting userclient load timestamp to " + timestampNow)
          }

          if(this.isTestFramework) {
            await this.store.dispatch("loadConsent")
            const consent = this.store.getters.hasConsented
            if(!consent) {
              const currentUser = this.store.getters.getCurrentUser;

              console.log("CYPRESS: Creating dummy user")

              await this.store.dispatch("setConsent", true)
              await this.store.dispatch("setContactPerson", {
                lastName: 'Testersen',
                firstName: 'Tester',
                email: currentUser && currentUser.profile.email ? currentUser.profile.email : 'tester@testvirk.dk',
                corporateEmail: currentUser && currentUser.profile.email ? currentUser.profile.email : 'info@testvirk.dk',
                termsEnabled: true,
                country: 'DK'
              } as ContactPerson)
            }
          }

          if (storedReturnUrl) {
            await router.push(storedReturnUrl)
          } else {

            await router.push("/redirect")
          }
        })
        .catch((err: any) => {
          store.dispatch("setReturnUrlSessionRefresh", "")
          console.error(err)
        })
    }
  }

  get isTestFramework() {
    return getCookie('CYPRESS-TEST').includes('true') &&
      (location.host.includes('localhost:8079') ||
        location.host.includes('kkbackend:8080') ||
        location.host.includes('localhost.erstdev.dk') ||
        location.host.includes('ci.klimakompasset.dk') ||
        location.host.includes('ci2.klimakompasset.dk'))
  }
}
</script>
<style lang="scss" scoped></style>

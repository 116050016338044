<template>
  <h2><SimpleText text-key="calculation.mainresult-ghg.text.mainresult.ghg" aria-live="polite"/></h2>
  <div class="full-width w-100" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.mainresult-ghg.description')" /><br>
  <table v-if="results?.groupingResults" :aria-label="getContentfulString('calculation.mainresult-ghg.text.mainresult-ghg')" class="table results-table">
    <thead>
    <tr class="bg-primary text-color-white py-9">
      <th id="main-resultghg-table-header1" colspan="3" scope="colgroup" v-dompurify-html="getContentfulString('calculation.mainresult.text.mainresult.table1.title')" />
    </tr>
    </thead>
    <thead>
    <tr style="font-weight: 600">
      <th id="result-ghg-sub-header-1" headers="main-resultghg-table-header1" class="align-text-left" style="width: 27%" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.mainresult.table1.scope')"></th>
      <th id="result-ghg-sub-header-2" headers="main-resultghg-table-header1" class="align-text-right" style="width: 35%" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.mainresult.table1.co2')"></th>
      <th id="result-ghg-sub-header-3" headers="main-resultghg-table-header1" class="align-text-right" style="width: 38%" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.mainresult.table1.share')"></th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td headers="result-ghg-sub-header-1">Scope 1</td>
      <td headers="result-ghg-sub-header-2" class="align-text-right">{{ doPrettify(results.ghgCalculatedResult?.scope1Result) || 0 }}</td>
      <td headers="result-ghg-sub-header-3" class="align-text-right">
        {{
          results.ghgCalculatedResult?.scope1Result && results.ghgCalculatedResult.totalResult
            ? doPrettify(((results.ghgCalculatedResult.scope1Result * 100) / results.ghgCalculatedResult.totalResult).toFixed(2))
            : 0
        }}%
      </td>
    </tr>

    <tr>
      <td headers="result-ghg-sub-header-1" class="align-text-left">Scope 2</td>
      <td headers="result-ghg-sub-header-2" class="align-text-right">{{ doPrettify(results.ghgCalculatedResult?.scope2Result) || 0 }}</td>
      <td headers="result-ghg-sub-header-3" class="align-text-right">
        {{
          results.ghgCalculatedResult?.scope2Result && results.ghgCalculatedResult.totalResult
            ? doPrettify(((results.ghgCalculatedResult.scope2Result * 100) / results.ghgCalculatedResult.totalResult).toFixed(2))
            : 0
        }}%
      </td>
    </tr>

    <tr>
      <td headers="result-ghg-sub-header-1">Scope 3</td>
      <td headers="result-ghg-sub-header-2" class="align-text-right">{{ doPrettify(results.ghgCalculatedResult?.scope3Result) || 0 }}</td>
      <td headers="result-ghg-sub-header-3" class="align-text-right">
        {{
          results.ghgCalculatedResult?.scope3Result && results.ghgCalculatedResult.totalResult
            ? doPrettify(((results.ghgCalculatedResult.scope3Result * 100) / results.ghgCalculatedResult.totalResult).toFixed(2))
            : 0
        }}%
      </td>
    </tr>

    <tr style="font-weight: 600">
      <td><SimpleText text-key="shared.text.total"/></td>
      <td class="align-text-right">{{ doPrettify(results.ghgCalculatedResult?.totalResult) || 0 }}</td>
      <td class="align-text-right">{{ doPrettify(100) }}%</td>
    </tr>
    </tbody>
  </table>

  <h2 v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.summary') || missingContent"
      aria-live="polite"
      :aria-label="getContentfulString('calculation.mainresult-ghg.text.summary') || missingContent">
    {{ getContentfulString('calculation.mainresult.text.summary') || missingContent }}
  </h2>>

  <div class="full-width w-100" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.summary.description')" />

  <div v-if="results?.groupingResults" class="row mt-605">
    <div :class="printMode ? 'col-12' : 'col-6'">
      <BarChartGHG :printMode="printMode" :results="results" :title="getContentfulString('calculation.mainresult-ghg.text.figure1')"></BarChartGHG>
    </div>
    <div :class="printMode ? 'col-12 mt-605' : 'col-6'">
      <PieChartGHG
        :negativeResultHeader="getContentfulString('calculation.mainresult-ghg.text.negative-result-messageheader')"
        :negativeResultText="getContentfulString('calculation.mainresult-ghg.text.negative-result-messagetext')"
        :printMode="printMode"
        :results="results"
        :title="getContentfulString('calculation.mainresult-ghg.text.figure2')"
      ></PieChartGHG>
    </div>
  </div>

  <h2><SimpleText text-key="calculation.mainresult.text.keynumbers" aria-live="polite"/></h2>
  <div class="full-width w-100" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.keynumbers.description')" /><br>
  <table :aria-label="getContentfulString('calculation.mainresult-ghg.text.keynumbers')" class="table" style="width: 555px">
    <thead>
    <tr class="bg-primary text-color-white py-9">
      <th scope="col" style="border-right: none">
        <SimpleText text-key="calculation.mainresult-ghg.text.keynumbers.calculations"/>
      </th>
      <th scope="col" class="align-text-right" v-dompurify-html="getContentfulString('calculation.mainresult.text.keynumbers.co2')" />
    </tr>
    </thead>
    <tbody v-if="results">
    <tr :class="{ 'missing-data': !results.resultOnNumberOfEmployees }">
      <td style="width: 55%" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.keynumbers.co2-per-employee')"></td>
      <td class="align-text-right" style="width: 45%">
        {{ doPrettify(results.resultOnNumberOfEmployees?.toFixed(2)) || getContentfulString('calculation.mainresult.text.no-master-data') }}
      </td>
    </tr>
    <tr :class="{ 'missing-data': !results.resultOnTurnover }">
      <td v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.keynumbers.co2-per-turnover')"></td>
      <td class="align-text-right"
          v-dompurify-html="doPrettify(results.resultOnTurnover?.toFixed(2)) || getContentfulString('calculation.mainresult.text.no-master-data')"></td>
    </tr>
    <tr :class="{ 'missing-data': !results.resultOnNumberOfSquareMeters }">
      <td v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.keynumbers.co2-per-area')"></td>
      <td class="align-text-right">
        {{ doPrettify(results.resultOnNumberOfSquareMeters?.toFixed(2)) || getContentfulString('calculation.mainresult.text.no-master-data') }}
      </td>
    </tr>
    </tbody>
  </table>

  <h2><SimpleText text-key="calculation.mainresult-ghg.text.outside.scopeheader" aria-live="polite"/></h2>
  <div class="full-width w-100" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.outside.scopetext')" /><br>
  <table v-if="results?.groupingResults" :aria-label="getContentfulString('calculation.mainresult-ghg.text.outside.scopeheader')"
         class="table results-table">
    <thead>
    <tr class="bg-primary text-color-white py-9">
      <th id="main-resultghg-table-header2" colspan="3" scope="colgroup" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.table.outside-scope')" />
    </tr>
    </thead>
    <thead>
    <tr style="font-weight: 600">
      <th id="result-ghg-outside-scope-subheader-1" headers="main-resultghg-table-header2" class="align-text-left" style="width: 27%" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.mainresult.table1.scope')"></th>
      <th id="result-ghg-outside-scope-subheader-2" headers="main-resultghg-table-header2" class="align-text-right" style="width: 35%" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.mainresult.table1.co2')"></th>
      <th id="result-ghg-outside-scope-subheader-3" headers="main-resultghg-table-header2" class="align-text-right" style="width: 38%" v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.mainresult.table1.share')"></th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td headers="result-ghg-outside-scope-subheader-1" class="align-text-left">
        <SimpleText text-key="calculation.mainresult-ghg.text.mainresult.table1.outside-scope"/>
      </td>
      <td headers="result-ghg-outside-scope-subheader-2" class="align-text-right">
        {{ doPrettify(results.ghgCalculatedResult?.outsideScopeResult, 2) || 0 }}
      </td>
      <td headers="result-ghg-outside-scope-subheader-3" class="align-text-right">
        {{
          results.ghgCalculatedResult?.outsideScopeResult && results.ghgCalculatedResult.totalResult
            ?
            doPrettify(((results.ghgCalculatedResult.outsideScopeResult * 100) / results.ghgCalculatedResult.totalResult), 1)
            : 0
        }}%
      </td>
    </tr>
    </tbody>
  </table>
  <h2><SimpleText text-key="calculation.mainresult.text.own-notes" aria-live="polite"/></h2>
  <div v-if="!printMode" id="text-area-description" class="full-width w-100"
       v-dompurify-html="getContentfulString('calculation.mainresult-ghg.text.own-notes.description')"></div>

  <div v-if="printMode" class="full-width w-100 preserve-linebreaks print-remarks-container">
    {{ calculation.resultComment }}
  </div>

  <form v-if="!printMode" @submit.prevent="submitComment">
    <div>
      <textarea
        id="input-area"
        v-model="comment"
        :disabled="this.calculation.state !== 'draft' && this.calculation.state !== 'duplicated'"
        aria-labelledby="text-area-description"
        class="form-input"
        maxlength="500"
        name="input-area"
        placeholder=""
        rows="5"
        style="max-width: 555px"
      ></textarea>
    </div>

    <!-- Knap start -->
    <button id="submitButton" class="button button-primary mt-5" type="submit">
      <SimpleText text-key="sharedContent.save"/>
    </button>
    <!-- Knap slut -->
  </form>
</template>

<script lang="ts">
import { Options } from "vue-class-component"
import PieChartGHG from "@/components/calculator/results/PieChartGHG.vue"
import BarChartGHG from "@/components/calculator/results/BarChartGHG.vue"
import { GroupingCalculatedResults } from "openapi/generated-clients/climatecompass"
import Toast from "../../Toast.vue"
import { Prop } from "vue-property-decorator"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import {Constants} from "@/shared/Constants";
import SimpleText from "@/components/SimpleText.vue";

@Options({
  components: {
    PieChartGHG,
    BarChartGHG,
    Toast,
    SimpleText
  }
})
export default class MainResultsGHG extends BaseComponent {
  @Prop()
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults

  @Prop()
  printMode?: boolean

  comment = ""
  missingContent = this.getContentfulString(Constants.missingContent)
  showToast = false

  async beforeMount() {
    if (this.calculation.resultComment !== null) {
      this.comment = this.calculation.resultComment
    }

    if (!this.printMode) {
      this.setDocumentTitleByKey('calculation.mainresult-ghg.text.mainresult.ghg')
    }
  }

    async submitComment() {
      const _calculation = {
        ...this.calculation,
        resultComment: this.comment
      }
      await this.store.dispatch("setCalculation", _calculation)
      //await this.store.dispatch("loadCalculations")
      this.addToast("MainResultsGHG", "success",
          this.getContentfulString('calculation.mainresult-ghg.text.toastheader'),
          this.getContentfulString('calculation.mainresult-ghg.text.toastmessage'))
      this.resizeCommentField()
    }
  }

</script>

<style lang="scss" scoped>

* {
  -webkit-print-color-adjust: exact;
}

.table {
  border: 1px solid $color-primary;
}

.table td,
.table th {
  padding: 0.8rem 1.2rem !important;
  border: 1px solid $color-primary;
}

.results-table {
  max-width: 60%;
}

.align-text-right {
  text-align: right;
}

.text-align-right * {
  text-align: right;
}

.text-align-left * {
  text-align: left !important;
}

.missing-data {
  background-color: #f5f5f5;
}

.missing-data :last-child {
  font-style: italic;
}
</style>

<template>
  <h2><SimpleText text-key="calculation.subresult.tab.title" aria-live="polite"/></h2>
  <div class="full-width w-100" v-dompurify-html="this.getContentfulString('calculation.text.partial-result.description')"/><br>

  <table class="table results-table w-percent-100" v-if="results?.groupingResults" :aria-label="getContentfulString('calculation.subresult.tab.title')">
    <thead>
    <tr class="bg-primary text-color-white">
      <th id="partial-result-table-header1" colspan="5" v-dompurify-html="getContentfulString('calculation.partial-result.text.table.include-scopes.description')" scope="colgroup"/>
    </tr>
    </thead>
    <thead>
    <tr style="font-weight: 600">
      <th id="main-category-header" headers="partial-result-table-header1" style="width: 30%" class="py-405 align-text-left text-color-black bg-alternative" v-dompurify-html="getContentfulString('calculation.mainresult.text.mainresult.table1.maincategory')"></th>
      <th id="total-scope-emission-header" headers="partial-result-table-header1" style="width: 18%" class="py-405 align-text-right text-color-black bg-alternative" v-dompurify-html="getContentfulString('calculation.mainresult.text.mainresult.table1.emission-scope1-3')"></th>
      <th id="percentage-emission-header" headers="partial-result-table-header1" style="width: 14%" class="py-405 align-text-right text-color-black bg-alternative" v-dompurify-html="getContentfulString('calculation.mainresult.text.mainresult.table1.share-emission-scope1-3')"></th>
      <th id="outside-scope-total-emission-header" headers="partial-result-table-header1" style="width: 18%" class="py-405 align-text-right text-color-black bg-alternative" v-dompurify-html="getContentfulString('calculation.mainresult.text.mainresult.table1.emission-outsidescope')"></th>
    </tr>
    </thead>
    <tbody>
    <template style="display: contents" v-for="result in results.groupingResults" :key="result.groupingName">
      <tr class="text-align-left bg-primary text-color-white">
        <td style="text-align: left" headers="main-category-header">{{ publicCategoryTheme(result.groupingName) }}</td>
        <td style="text-align: right" headers="total-scope-emission-header">{{ prettify(result.calculatedResult?.scopesResult, 2) || 0 }}</td>
        <td style="text-align: right" headers="percentage-emission-header">{{
            result.calculatedResult?.scopesResult &&
            results?.scoresTotalResult ? prettify(((result.calculatedResult?.scopesResult / results.scoresTotalResult) * 100).toFixed(1)) : 0
          }}%
        </td>
        <td style="text-align: right" headers="outside-scope-total-emission-header">{{ prettify(result.calculatedResult?.outsideScopeResult, 2, true) || 0 }}</td>
      </tr>

      <template role="rowgroup" style="display: contents" v-for="subResult in result.mergedSubgroupingsCalculated" :key="subResult.mergedSubgroupingName">
        <tr role="row" class="text-align-left">
          <td style="text-align: left">{{ publicCategoryGroup(subResult.mergedSubgroupingName, result.groupingName) }}</td>
          <td style="text-align: right">{{ prettify(subResult.calculatedResult?.scopesResult, 2) || 0 }}</td>
          <td style="text-align: right">
            {{
              subResult.calculatedResult?.scopesResult && results?.scoresTotalResult
                  ? prettify(((subResult.calculatedResult?.scopesResult / results.scoresTotalResult) * 100).toFixed(1)) : 0
            }}%
          </td>
          <td style="text-align: right">{{ prettify(subResult.calculatedResult?.outsideScopeResult, 2, true) || 0 }}
          </td>
        </tr>

        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_EL">
          <ResultRow :result="result" :id="constants.GROUP_FORBRUG_AF_ELEKTRICITET" :subResult="subResult" :results="results" :subCategoryName="publicSubCategoryGroup(constants.GROUP_FORBRUG_AF_ELEKTRICITET)"/>
          <ResultRow :result="result" :id="constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER" :subResult="subResult" :results="results" :subCategoryName="publicSubCategoryGroup(constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>

        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_VARME_OG_PROCESENERGI">
          <ResultRow :result="result" :id="constants.GROUP_FJERNVARME" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_FJERNVARME)"/>
          <ResultRow :result="result" :id="constants.GROUP_BRÆNDSLER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_BRÆNDSLER)"/>
          <ResultRow :result="result" :id="constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>

        <template v-if="subResult.mergedSubgroupingName ===constants.GROUP_NAME_PROCESUDLEDNING">
          <ResultRow :result="result" :id="constants.GROUP_PROCESUDLEDNING" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_PROCESUDLEDNING)"/>
          <ResultRow :result="result" :id="constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>

        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_EGNE_OG_LEASEDE_TRANSPORTFORM">
          <ResultRow :result="result" :id="constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER)"/>
          <ResultRow :result="result" :id="constants.GROUP_FLY_OG_SKIBE" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_FLY_OG_SKIBE)"/>
          <ResultRow :result="result" :id="constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>

        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_MEDARBEJDERTRANSPORT">
          <ResultRow :result="result" :id="constants.GROUP_PENDLING" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_PENDLING)"/>
          <ResultRow :result="result" :id="constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER)"/>
          <ResultRow :result="result" :id="constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK)"/>
          <ResultRow :result="result" :id="constants.GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>

        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_VARETRANSPORT_TIL_VIRKSOMHED">
          <ResultRow :result="result" :id="constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER)"/>
          <ResultRow :result="result" :id="constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK)"/>
          <ResultRow :result="result" :id="constants.GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>

        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDEN">
          <ResultRow :result="result" :id="constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II)"/>
          <ResultRow :result="result" :id="constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II)"/>
          <ResultRow :result="result" :id="constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>

        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_AFFALD">
          <ResultRow :result="result" :id="constants.GROUP_AFFALD" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_AFFALD)"/>
          <ResultRow :result="result" :id="constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>


        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER">
          <ResultRow :result="result" :id="constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER)"/>
          <ResultRow :result="result" :id="constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER" :subResult="subResult" :results="results"
                     :subgroupingName="constants.GROUP_NAME_SOLGTE_OG_UDLEJEDE_PRODUKTER_EGNE" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>

        <template v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_END_OF_LIGE_BEHANDLING">
          <ResultRow :result="result" :id="constants.GROUP_END_OF_LIFE_BEHANDLING" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_END_OF_LIFE_BEHANDLING)"/>
          <ResultRow :result="result" :id="constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER)"/>
        </template>

      </template>

          <template style="display: contents" v-if="result.purchaseGroupingCalculatedResults">
            <template style="display: contents" v-for="subResult in result.purchaseGroupingCalculatedResults" :key="subResult.mergedSubgroupingName">
              <tr class="text-align-left" style="font-weight: 600">
                <td style="text-align: left">{{ publicCategoryGroup(subResult.mergedSubgroupingName, result.groupingName) }}</td>
                <td style="text-align: right">{{ prettify(subResult.calculatedResult?.scopesResult, 2) || 0 }}</td>
                <td style="text-align: right">
                  {{
                    subResult.calculatedResult?.scopesResult && results?.scoresTotalResult
                        ? prettify(((subResult.calculatedResult?.scopesResult / results.scoresTotalResult) * 100).toFixed(1)) : 0
                  }}%
                </td>
                <td style="text-align: right">{{ prettify(subResult.calculatedResult?.outsideScopeResult, 2) || 0 }}</td>
              </tr>

                      <template role="rowgroup" style="display: contents" v-for="purchaseResult in subResult.mergedSubgroupingsCalculated" :key="purchaseResult.mergedSubgroupingName">
                        <tr role="row" style="text-align: left">
                          <td style="text-align: left" class="pl-5">{{ publicCategoryPurchaseGroup(subResult.mergedSubgroupingName, purchaseResult.mergedSubgroupingName) }}</td>
                          <td style="text-align: right">{{ prettify(purchaseResult.calculatedResult?.scopesResult, 2) || 0 }}</td>
                          <td style="text-align: right">
                            {{
                              purchaseResult.calculatedResult?.scopesResult && results?.scoresTotalResult
                                  ? prettify(((purchaseResult.calculatedResult?.scopesResult / results.scoresTotalResult) * 100).toFixed(1)) : 0
                            }}%
                          </td>
                          <td style="text-align: right">{{ prettify(purchaseResult.calculatedResult?.outsideScopeResult, 2) || 0 }}</td>
                        </tr>
                        <template
                            v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_MATERIALER">
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER)"/>
                        </template>
                        <template
                            v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES">
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER)"/>
                        </template>
                        <template
                            v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_ANDET">
                          <ResultRow :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER)"/>
                          <ResultRow :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER)"/>
                        </template>
                        <template
                            v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_MATERIALER">
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II)"/>
                        </template>
                        <template
                            v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES">
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II)"/>
                          <ResultRow :result="result" :id="constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II)"/>
                        </template>
                        <template
                            v-if="subResult.mergedSubgroupingName === constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER && purchaseResult.mergedSubgroupingName === constants.GROUP_NAME_ANDET">
                          <ResultRow :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER)"/>
                          <ResultRow :result="result" :id="constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER" :subResult="subResult" :results="results" :subCategoryName= "publicSubCategoryGroup(constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER)"/>
                        </template>
                      </template>
                    </template>
              </template>
    </template>
    <tr style="font-weight: 600" class="bg-primary text-color-white">
      <td class="align-text-left"><SimpleText text-key="sharedContent.total"/></td>
      <td class="align-text-right">{{ prettify(results.scoresTotalResult, 2) || 0 }}</td>
      <td class="align-text-right">{{ prettify(100) }}%</td>
      <td class="align-text-right">{{ prettify(results.outsideScoresTotalResult, 2, true) || 0 }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import {Options} from 'vue-class-component'
import BaseResults from "@/components/calculator/results/BaseResults";
import {GroupingCalculatedResults} from 'openapi/generated-clients/climatecompass'
import {prettify} from '@/shared/PrettifyNumbers'
import {Prop} from 'vue-property-decorator'
import ResultRow from './ResultRow.vue'
import {Constants} from "@/shared/Constants";



@Options({
  components: {
    ResultRow
  }
})
export default class PartialResults extends BaseResults {
  @Prop()
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults

  @Prop()
  printMode?: boolean

  get prettify() {
    return prettify
  }

  async beforeMount() {
    this.init()

    if (!this.printMode) {
      this.setDocumentTitleByKey('calculation.subresult.tab.title')
    }
  }

  get constants() {
    return Constants
  }

}
</script>

<style lang="scss" scoped>

* {
  -webkit-print-color-adjust: exact;
}

.text-align-right * {
  text-align: right;
}

.text-align-left * {
  text-align: left;
}

.grouping-result {
  background-color: $color-primary;
  color: white;
  font-weight: 600;
}

.text-color-black {
  color: #1a1a1a !important;
}

tr > .py-405.align-text-left.text-color-black.bg-alternative,
  tr > .py-405.align-text-right.text-color-black.bg-alternative, {
  //Highly specific in order to override standard application inheritance structure for headers.
  border-right: 1px solid $color-primary !important;
}

</style>

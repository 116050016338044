import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a8e047c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"center"} }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "alert alert-success"
}
const _hoisted_4 = { class: "alert-body" }
const _hoisted_5 = {
  key: 0,
  class: "alert-heading"
}
const _hoisted_6 = {
  key: 1,
  class: "alert-text"
}
const _hoisted_7 = { ref: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    (_ctx.resultIsNegative)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.negativeResultHeader)
              ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_5, null, 512)), [
                  [_directive_dompurify_html, _ctx.negativeResultHeader]
                ])
              : _createCommentVNode("", true),
            (_ctx.negativeResultText)
              ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_6, null, 512)), [
                  [_directive_dompurify_html, _ctx.negativeResultText]
                ])
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.results.groupingResults && !_ctx.resultIsNegative)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          style: _normalizeStyle([_ctx.dataSet.length > 3 ? 'padding-top: 18px;' : '', {"margin":"0 auto"}]),
          class: _normalizeClass({ 'print-graph-container': _ctx.printMode })
        }, [
          _createElementVNode("canvas", _hoisted_7, null, 512)
        ], 6))
      : _createCommentVNode("", true)
  ], 64))
}
<template>
  <SharedHeader>
    <template #firstColumn>
      <div id='guides-first-column-header'>
        <h1>{{ currentPageHeader.title }}</h1>
        <span v-dompurify-html='currentPageHeader.description'></span>
      </div>
      <div class='mt-5'>
        <!--a v-if='currentPageHeader.buttonPrimaryLink' :href='currentPageHeader.buttonPrimaryLink'
           class='button button-primary mr-4' target='_blank'>{{ currentPageHeader.buttonPrimaryText }}</a>
        <a v-if='currentPageHeader.buttonSecondaryLink' :href='currentPageHeader.buttonSecondaryLink'
           class='button button-secondary' target='_blank'>{{ currentPageHeader.buttonSecondaryText }}</a-->
        <!-- convert from href to button due to SQ security errors -->
        <button v-if='currentPageHeader.buttonPrimaryLink'
          type="button"
          class="button button-primary mr-4"
          @click="openLink(currentPageHeader.buttonPrimaryLink)">{{ currentPageHeader.buttonPrimaryText }}
        </button>
        <button v-if='currentPageHeader.buttonSecondaryLink'
                type="button"
                class="button button-secondary"
                @click="openLink(currentPageHeader.buttonSecondaryLink)">{{ currentPageHeader.buttonSecondaryText }}
        </button>
      </div>
    </template>
    <template #secondColumn>
      <img v-if='currentPageHeader.imageUrl' :src='currentPageHeader.imageUrl'
           alt=''/>
    </template>
  </SharedHeader>
  <div class='container px-0 pb-6'>
    <section>
      <h2>
        {{ pageTitle }}
      </h2>
      <span v-dompurify-html='pageDescription'></span>
    </section>
    <section>
      <div class='step-section-wrapper'>
        <div class='step-section' v-for='(section, index) in stepSectionsList' :key='section.description'>
          <div class='step-section-number'>{{ index + 1 }}</div>
          <div class='step-section-content'>
            <h3>{{ section.title }}</h3>
            <span v-if='section.description' v-dompurify-html='section.description'></span>
            <a class='button button-secondary mt-6' v-if='section.buttonLink' target='_blank'
               :href='section.buttonLink'>{{ section.buttonText }}</a>
            <div class='mt-6'>
              <AccordionComponent :accordion-list='getAccordions(section.accordionGroup)'
                                  :unique-id='getUniqueId()'></AccordionComponent>
              <hr class='mt-8'>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <section>
    <div id="extra-section-container" class="container px-0">
      <div class="row justify-content-center">
        <div v-for="(extraSection) in extraSectionList" :key="extraSection" :id="extraSection.title"
             class="col-3 extra-content">
          <img v-if="extraSection.imageUrl" :src="extraSection.imageUrl"
               alt=''/>
          <h4 v-if="extraSection.title"> {{ extraSection.title }} </h4>
          <p v-if="extraSection.description"> {{ extraSection.description }} </p>
          <a v-if="extraSection.link && extraSection.buttonText" :href="extraSection.link"
             class="button button-tertiary">
            {{ extraSection.buttonText }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>


<script lang='ts'>
import {documentToHtmlString} from '@contentful/rich-text-html-renderer'
import * as DKFDS from 'dkfds'
import TitleDescription from '@/types/contentful-types/TitleDescription'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import {GuideHeader} from '@/components/guides/Guides.vue'
import {Options} from 'vue-class-component'
import AccordionComponent from '@/components/AccordionComponent.vue'
import SharedHeader from '@/components/calculator/shared/SharedHeader.vue'
import { v4 as uuid } from 'uuid';
import DOMPurify from 'dompurify'

interface StepSection {
  title: string
  description?: string
  buttonLink?: string
  buttonText?: string
  accordionGroup?: string
}

interface ExtraSection {
  imageUrl: string
  title: string
  description: string
  link: string
  buttonText: string
}

@Options({
  components: {AccordionComponent, SharedHeader}
})
export default class GetStarted extends BaseComponent {
  accordionsList: TitleDescription[] = []
  stepSectionsList: StepSection[] = []
  extraSectionList: ExtraSection[] = []
  currentPageHeader: GuideHeader = {} as GuideHeader
  uniqueId = ''
  pageTitle = ''
  pageDescription = ''

  get documentToHtmlString() {
    return documentToHtmlString
  }

  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

  getUniqueId() {
    return uuid()
  }

  async beforeMount() {
    this.initPage()
    this.setupStepSections()
    this.setupExtraSections()

    this.setDocumentTitle(this.pageTitle)
  }

  initPage() {
    this.pageTitle = this.getContentfulString('guides.getstarted.page.title')
    this.pageDescription = this.getRenderedContentfulRT('guides.getstarted.page.description')

    this.currentPageHeader.title = this.getContentfulString('guides.faq.header.title')
    this.currentPageHeader.description = this.getRenderedContentfulRT('guides.faq.header.description')
    const pLink = this.Contentful.findSimpleTextOrNull('guides.faq.header.buttonprimary.link')
    this.currentPageHeader.buttonPrimaryLink = pLink ? DOMPurify.sanitize(pLink) : pLink
    this.currentPageHeader.buttonPrimaryText = this.Contentful.findSimpleTextOrNull('guides.faq.header.buttonprimary.text')
    const sLink = this.Contentful.findSimpleTextOrNull('guides.faq.header.buttonsecondary.link')
    this.currentPageHeader.buttonSecondaryLink = sLink ? DOMPurify.sanitize(sLink) : sLink
    this.currentPageHeader.buttonSecondaryText = this.Contentful.findSimpleTextOrNull('guides.faq.header.buttonsecondary.text')
    this.currentPageHeader.imageUrl = this.Contentful.findSimpleTextOrNull('guides.faq.header.imageurl')
  }

  setupStepSections() {
    let sectionTitle: string | null = ''
    let i = 0
    while (sectionTitle !== null) {
      i++
      sectionTitle = this.Contentful.findSimpleTextOrNull('guides.getstarted.section.title-' + i)
      const sectionDescription = this.getRenderedContentfulRT('guides.getstarted.section.description-' + i)
      const buttonText = this.getContentfulString('guides.getstarted.section.buttontext-' + i)
      const buttonLink = this.Contentful.findSimpleTextOrNull('guides.getstarted.section.buttonlink-' + i)
      const accordionGroup = this.Contentful.findSimpleTextOrNull('guides.getstarted.section.accordiongroup-' + i)
      if (sectionTitle !== null) {
        this.stepSectionsList.push({
          title: sectionTitle,
          buttonLink: buttonLink,
          buttonText: buttonText,
          accordionGroup: accordionGroup,
          description: sectionDescription
        } as StepSection)
      }
    }
  }

  setupExtraSections() {
    let description: string | null = ''
    let i = 0
    while (description !== null) {
      i++
      description = this.Contentful.findSimpleTextOrNull('guides.getstarted.extra.description-' + i)
      const imageUrl = this.Contentful.findSimpleTextOrNull('guides.getstarted.extra.image-link-' + i)
      const title = this.Contentful.findSimpleTextOrNull('guides.getstarted.extra.header-' + i)
      const buttonLink = this.Contentful.findSimpleTextOrNull('guides.getstarted.extra.buttonlink-' + i)
      const buttonText = this.Contentful.findSimpleTextOrNull('guides.getstarted.extra.buttontext-' + i)

      if (imageUrl !== null) {
        this.extraSectionList.push({
          description: description,
          imageUrl: imageUrl,
          title: title,
          link: buttonLink,
          buttonText: buttonText,
        } as ExtraSection)
      }
    }
  }
  openLink(url: string) {
    window.open(url)
  }

}
</script>

<style scoped lang='scss'>
.accordion-button {
  border: 1px solid grey;
}

.step-section-wrapper {
  position: relative;
}

.step-section-number {
  font-size: 2.5rem;
  font-weight: 600;
  border: 3px solid #0e0c0c;
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  left: -18px;
  background: white;
}

.step-section {
  position: relative;
  box-shadow: 5px 0 0 0 #ABABAB inset;
  -webkit-box-shadow: 5px 0 0 0 #ABABAB inset;
  -moz-box-shadow: 5px 0 0 0 #ABABAB inset;

  min-height: 100px;
  margin-left: 2rem;
  margin-top: 40px;

  .step-section-content {
    position: relative;
    top: 0.4rem;
    margin-left: 4rem;
    padding-bottom: 100px;

    h3 {
      margin-top: 0;
    }

    hr {
      border-top: 3px solid $color-border-grey;
    }

  }
}

.step-section:last-of-type .step-section-content {
  padding-bottom: 20px;
}


$step-height: 20px;
$step-count: 7;

@for $i from 2 through $step-count {
  .step-section:nth-of-type(#{$i}) {
    top: calc(-#{$step-height} * (#{$i} - 1));
  }
}

.extra-content {
  text-align: center;
  display: flex;
  flex-direction: column;


  img {
    margin: 0 auto;
  }

  h4 {

    margin: 20px 0 0;
  }

  p {
    flex-grow: 1;
  }

  a {
    width: fit-content;
    margin: auto auto 0;
  }
}

</style>

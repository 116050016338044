<template>
  <SharedHeader>
    <template #firstColumn>
      <h1>
        <SimpleText text-key="comparison.create-comparison.title"/>
      </h1>
      <span><RichText text-key="comparison.create-comparison.header-description"/></span>
    </template>
    <template #secondColumn>
      <div v-if="false" class="flex flex-lg-row flex-column result-buttons column-row-gap-10">
        <div class="result-buttons__button-group flex">
          <a
              id="comparison-download-excel-link"
              href="javascript:void(0)"
              class="button button-secondary"
              @click="exportComparisonToExcel"
              :data-cy-id="'klimakompasset-resultat-sammenligninger'"
          >
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#file-download"></use>
            </svg>
            <SimpleText text-key='comparison.excel.button.download-title'/>
          </a>
          <Tooltip :style="`vertical-align: middle;`" :textKey="'comparison.download-excel-tooltip'" size="lg"/>
        </div>

        <div class='result-buttons__button-group'>
          <button
              id='comparison-download-pdf-button'
              class="button button-secondary flex-grow-equally"
              aria-label="Download comparison as PDF"
              onclick='window.print()'
          >
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#file-download"></use>
            </svg>
            <SimpleText text-key='comparison.pdf.button.download-title'/>
          </button>
        </div>
      </div>
    </template>
  </SharedHeader>

  <div class="container px-0">
    <ComparisonCalculationMultiSelect
        v-if="!isFinishedLoading || !comparison"
        @comparison-selected-calculations="updateComparisonCalcList"
        @finished-loading-comparison="handleComparisonLoaded"
        :mode="mode" ref="comparison-multiselect"
        :calculations-to-compare="possibleCalculations"
        :comparison-name="comparison.comparisonMetadata?.name"
        :aria="{'aria-labelledby':'multiselect-comparison-choose-calculation-label'}"
    >
    </ComparisonCalculationMultiSelect>
  </div>
  <div id="test" v-if="isFinishedLoading">
    <!--DYNAMIC TEXT NO1 (MONETARY DIFFERENCE) -->
    <div class="container px-0 py-9">
      <!-- ERST descided not to have this warning -->
      <div v-if="monetaryDifference()"
           aria-atomic="true"
           class="alert alert-info"
           role="alert"
           style="max-width: 100%; display: flex;"
           id="info-box-declaration-wrapper-div"
      >
        <div style="flex: 0 0 3%;">
          <svg aria-hidden="true" class="info-icon-size" focusable="false">
            <use xlink:href="#info"></use>
          </svg>
        </div>
        <div style="flex: 1;">
          <p>
            <strong>
              <SimpleText textKey='comparison.create.monetary-difference.info.title'/>
            </strong>
          </p>
          <span> <SimpleText textKey='comparison.create.monetary-difference.info.message'/> </span>
        </div>
      </div>
      <!--MAIN / OVERVIEW TABLE SECTION-->
      <div v-if="comparison.calculations" class="py-4" id="comparison-table-overview-section">
        <h3 class="py-4 my-4">
          <SimpleText :text-key="'comparison.create.main.title'"></SimpleText>
        </h3>
        <div class="tableContainer">
          <!-- Left-aligned headers with data rows -->
          <table class="table w-percent-100">
            <tbody>
            <tr>
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="calculation.masterdata.tab.title"/></th>
              <th v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width" scope="col">
                {{ item.calculation?.calculationPurpose.name }}
              </th>
            </tr>
            <tr>
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="sharedContent.country"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ item.calculation?.companyInformation?.country }}
              </td>
            </tr>
            <!-- Row 2: CVR -->
            <tr>
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="sharedContent.cvr"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                <template v-if="item.calculation?.companyInformation.vatNo">
                  {{ item.calculation.companyInformation.vatNo }}
                </template>
                <template v-else>
                  <simple-text text-key="comparison.create.no-cvr-registered.message"></simple-text>
                </template>
              </td>
            </tr>
            <!-- Row 3: Lokation -->
            <tr>
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="sharedContent.location"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                <template v-if="item.calculation?.companyInformation?.productionUnits && item.calculation.companyInformation.productionUnits.length">
                  <p v-for="location in item.calculation.companyInformation.productionUnits" :key="location.id"
                     :data-cy-name="location.pNumber">
                    {{ formatAddressDK(location.address) }} {{ location.address.street }}
                  </p>
                </template>
                <template v-else>
                  <simple-text text-key="comparison.create.no-locations-registered.message"></simple-text>
                </template>
              </td>
            </tr>
            <!-- Row 4: Tidperiode -->
            <tr>
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="sharedContent.timeperiod"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{
                  formatDatesPlain(item.calculation?.calculationPurpose.startDate + '', item.calculation?.calculationPurpose.endDate + '')
                }}
              </td>
            </tr>
            <!-- Row 5: Emissionsfaktor fra -->
            <tr>
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="sharedContent.emf-from"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ item.calculation?.emissionCoefficientsVersion?.year }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--DYNAMIC TEXT NO2 (MONETARY DIFFERENCE) -->
      <div class="container px-0 py-0">
        <div v-if="timePeriodDifference()"
             aria-atomic="true"
             class="alert alert-warning"
             role="alert"
             style="max-width: 100%; display: flex; align-items: flex-start;"
             id="warning-box-declaration-wrapper-div"
        >
          <div style="flex: 0 0 3%;" class="pt-4">
            <svg aria-hidden="true" class="icon-svg-warning" focusable="false">
              <use xlink:href="#warning"></use>
            </svg>
          </div>
          <div style="flex: 1;">
            <p>
              <strong>
                <SimpleText textKey='comparison.create.time-period-difference.warning.title'/>
              </strong>
            </p>
            <span>{{timePeriodDifferenceText()}}</span>
          </div>
        </div>
      </div>
      <!--GRAPH SECTION -->
      <div class="container mb-405">
        <h3 class="py-4 my-4">
          <SimpleText :text-key="'comparison.create.graph-section.title'"></SimpleText>
        </h3>
        <span><SimpleText textKey="comparison.create.graph-section.message"></SimpleText></span>
      </div>
      <!--DYNAMIC TEXT NO3 (MONETARY DIFFERENCE) -->
      <div class="container px-0 pt-2">
        <div
             aria-atomic="true"
             class="alert alert-info"
             role="alert"
             style="max-width: 100%; display: flex;"
             id="info-box-declaration-wrapper-div"
        >
          <div style="flex: 0 0 3%;">
            <svg aria-hidden="true" class="info-icon-size" focusable="false">
              <use xlink:href="#info"></use>
            </svg>
          </div>
          <div style="flex: 1;">
            <p>
              <strong>
                <SimpleText textKey='comparison.create.scopes-info-banner.title'/>
              </strong>
            </p>
            <span> <SimpleText textKey='comparison.create.scopes-info-banner.message'/> </span>
          </div>
        </div>
      </div>
      <div class="container px-0">

        <div class="py-3">
          <form @change="formValidation.clearValidField" @submit.prevent="submitForm"
                @invalid.capture.prevent="formValidation.handleFormValidity"
                ref="formGroup">
            <div class="py-4 row form-group">

              <div class="col-md-3 form-group">
                <label class="form-label " for="input-comparison-main-filter-criteria">
                  <SimpleText text-key="comparison.create.primary-filter.title"/>
                </label>
                <select class="form-select" name="options" id="input-comparison-main-filter-criteria"
                        v-model="selectedComparisonCriteriaDropDown">
                  <option disabled value="">
                    <SimpleText text-key="generic.input.dropdown.option-select.name"/>
                  </option>
                  <option :key="1" :value="'SCOPES'">
                    <SimpleText text-key="sharedContent.scopes"/>
                  </option>
                  <option :key="2" :value="'CATEGORIES'">
                    <SimpleText text-key="sharedContent.main-categories"/>
                  </option>
                  <option :key="3" :value="'ENERGY'">
                    <SimpleText text-key="calculator.grouping.energy"/>
                  </option>
                  <option :key="4" :value="'PROCUREMENT'">
                    <SimpleText text-key="calculator.grouping.purchases"/>
                  </option>
                  <option :key="5" :value="'TRANSPORT'">
                    <SimpleText text-key="calculator.grouping.transport"/>
                  </option>
                  <option :key="6" :value="'TRASH'">
                    <SimpleText text-key="calculator.grouping.garbage"/>
                  </option>
                  <option :key="7" :value="'PRODUCTS'">
                    <SimpleText text-key="calculator.grouping.products"/>
                  </option>
                </select>
              </div>

              <div class="col-md-3 form-group">
                <ComparisonFilterMultiselect
                    :initial-dropdown-choice="selectedComparisonScopeCriterias"
                    @comparison-selected-scopes="updateSelectedScopes"
                ></ComparisonFilterMultiselect>
           <!--     <select class="form-selectxx" name="options" id="input-comparison-alt-filter-criteria"
                        multiple="true"
                        v-model="selectedComparisonAltCriteriaDD">
                  <option disabled value="">
                    <SimpleText text-key="generic.input.dropdown.option-select.name"/>
                  </option>
                  <option :key="1" :value="'SCOPE1'">Scope 1</option>
                  <option :key="2" :value="'SCOPE2'">Scope 2</option>
                  <option :key="3" :value="'SCOPE3'">Scope 3</option>
                  <option :key="4" :value="'ALLE SCOPES'">Alle Scopes</option>
                  <option :key="5" :value="'UDENFOR SCOPES'">Udenfor scope</option>
                </select> -->
              </div>
              <div class="col-md-4 form-group">
                <button id="submit-button-forecast-filter" class="button button-primary" style="margin-top: 10px;"
                        type="submit" :disabled="!isFinishedLoading" @click.prevent='filterChangeSubmit()'>
                  <SimpleText text-key="comparison.create.filter-button-submit.name"/>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div v-if="isScopeActive()">
          <h3 class="py-4 my-4" ><SimpleText :text-key="'comparison.create.graph-section.title1'"></SimpleText></h3>
          <ComparisonChartScope :mode="mode" ref="comparisonChartScopeRef" @mounted="updateChart"></ComparisonChartScope>
        </div>
        <div v-if="isCategoryActive()">
          <h3 class="py-4 my-4" ><SimpleText :text-key="'comparison.create.graph-section.title2'"></SimpleText></h3>
          <ComparisonChartMainCategory :mode="mode" ref="comparisonChartMainCategoryRef" @mounted="updateChart"></ComparisonChartMainCategory>
        </div>
        <div v-if="isSubCategoryActive()">
          <h3 class="py-4 my-4" ><SimpleText :text-key="'comparison.create.graph-section.title2'"></SimpleText></h3>
          <ComparisonChartIndividualGroups :mode="mode" ref="comparisonChartIndividualGroupsRef" @mounted="updateChart"></ComparisonChartIndividualGroups>
        </div>
      </div>

      <!--DYNAMIC GRAPH TABLE SECTION-->
      <div class="py-4" id="comparison-table-overview-section">
        <h3 class="py-4 my-4" v-if="isScopeActive()"><SimpleText text-key="comparison.distribution-scope"/></h3>
        <h3 class="py-4 my-4" v-if="isCategoryActive()"><SimpleText text-key="comparison.distribution-maincategory"/></h3>
        <h3 class="py-4 my-4" v-if="isSubCategoryActive()"><SimpleText text-key="comparison.distribution-category"/></h3>
        <div v-if="comparison.calculations" class="tableContainer">
          <!-- Left-aligned headers with data rows -->
          <table class="table w-percent-100">
            <tbody>
            <tr>
              <th class="text-primary" scope="row" style="width: 20%">{{ tableHeader }}</th>
              <th v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width" scope="col">
                {{ item.calculation?.calculationPurpose.name }}
              </th>
            </tr>
            <tr v-if="isScope1Active(true)">
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="shared.scope1"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ doPrettify(getEmissionValue(item, "SCOPE", "SCOPE_1"), 2) }}
              </td>
            </tr>
            <tr v-if="isScope2Active(true)">
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="shared.scope2"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ doPrettify(getEmissionValue(item, "SCOPE", "SCOPE_2"), 2) }}
              </td>
            </tr>
            <tr v-if="isScope3Active(true)">
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="shared.scope3"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ doPrettify(getEmissionValue(item, "SCOPE", "SCOPE_3"), 2) }}
              </td>
            </tr>
            <tr v-if="isCategoryActive()">
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="theme.text.category.energy"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ doPrettify(getEmissionValue(item, "G", "G_1"), 2) }}
              </td>
            </tr>
            <tr v-if="isCategoryActive()">
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="theme.text.category.purchase2"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ doPrettify(getEmissionValue(item, "G", "G_2"), 2) }}
              </td>
            </tr>
            <tr v-if="isCategoryActive()">
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="theme.text.category.transport"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ doPrettify(getEmissionValue(item, "G", "G_3"), 2) }}
              </td>
            </tr>
            <tr v-if="isCategoryActive()">
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="theme.text.category.waste"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ doPrettify(getEmissionValue(item, "G", "G_4"), 2) }}
              </td>
            </tr>
            <tr v-if="isCategoryActive()">
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="theme.text.category.soldproducts"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ doPrettify(getEmissionValue(item, "G", "G_5"), 2) }}
              </td>
            </tr>
            <template v-if="isSubCategoryActive()">
              <tr v-for="mergedGroup in getMergedGroupsByGroup(selectedComparisonCriteria)" :key="mergedGroup" >
                <th class="text-primary" scope="row" style="width: 20%"><SimpleText :text-key="mergedGroup"/></th>
                <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                    :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                  {{ doPrettify(getEmissionValueMergedGroup(item, mergedGroup, selectedComparisonScopeCriterias), 2) }}
                </td>
              </tr>
            </template>
            <tr class="bg-green">
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="sharedContent.total"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ doPrettify(getEmissionValue(item, "SCOPE", "SCOPE_TOTAL"), 2) }}
              </td>
            </tr>
            <tr v-if="isScopeOutsideActive(true)">
              <th class="text-primary" scope="row" style="width: 20%"><SimpleText text-key="sharedContent.outside-scope"/></th>
              <td v-for="item in comparison.calculations" :key="item.calculation?.id"
                  :data-cy-name="item.calculation?.calculationPurpose.name" class="text-dark dynamic-width">
                {{ doPrettify(getEmissionValue(item, "SCOPE", "OUTSIDE_SCOPE"), 2) }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container my-5">
        <label class="form-label " for="input-comparison-main-filter-criteria">
          <SimpleText text-key="comparison.create.comment-field.label"/>
        </label>
        <form @submit.prevent="submitComment">
          <div>
      <textarea
          id="input-area"
          v-model="comment"
          aria-labelledby="text-area-description"
          class="form-input"
          maxlength="255"
          name="input-area"
          rows="5"
          style="max-width: 555px"
      >
      </textarea>
          </div>

          <!-- Knap start -->
          <button id="submitButton" class="button button-secondary mt-5" type="submit">
            <SimpleText text-key="comparison.create.save-button.title"/>
          </button>
          <!-- Knap slut -->
        </form>
      </div>
      <a href="/klimakompasset/comparison/comparison-overview">
        <svg class='icon-svg' focusable='false' aria-hidden='true'>
          <use xlink:href='#arrow-left'></use>
        </svg>
        <SimpleText text-key='comparison.create.back-button.title'/>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Prop, Watch} from 'vue-property-decorator';
import RichText from "@/components/RichText.vue";
import Tooltip from "@/components/Tooltip.vue";
import SimpleText from "@/components/SimpleText.vue";
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue";
import ComparisonCalculationMultiSelect from "@/components/comparison/ComparisonCalculationMultiSelect.vue";
import {
  Calculation,
  CalculationFull,
  CalculationInfo,
  CalculationStateEnum,
  CompareFilterObject,
  CompareFilterObjectCompareTypeEnum,
  ComparisonObject,
  Versions,
} from "../../../openapi/generated-clients/climatecompass";
import { comparisonClient, sessionClient, versionClient } from '@/shared/BackendService'
import {FormValidation} from "@/shared/FormValidation";
import {AxiosResponse} from "axios";
import {useRouter} from "vue-router";
import ComparisonFilterMultiselect from "@/components/comparison/ComparisonFilterMultiselect.vue";
import ComparisonChartScope from "@/components/comparison/ComparisonChartScope.vue";
import ComparisonChartMainCategory from "@/components/comparison/ComparisonChartMainCategory.vue";
import ComparisonChartIndividualGroups from "@/components/comparison/ComparisonChartIndividualGroups.vue";
import ComparisonBase from "@/components/comparison/ComparisonBase";

@Options({
  components: {
    ComparisonChartIndividualGroups,
    ComparisonChartMainCategory,
    ComparisonChartScope,
    ComparisonFilterMultiselect,
    ComparisonCalculationMultiSelect,
    SimpleText,
    Tooltip,
    RichText,
    SharedHeader
  }
})
export default class CreateComparison extends ComparisonBase {
  /** mode can be 'edit' or 'new' */
  @Prop()
  mode = 'new'
  @Prop()
  comparisonId = 0

  comment = ''
  selectedComparisonCriteria = 'SCOPES'
  selectedComparisonScopeCriterias = ['SCOPE1', 'SCOPE2', 'SCOPE3']
  comparisonScopeCriteriasToLock: string[] = []
  selectedComparisonCriteriaDropDown = 'SCOPES'
  selectedComparisonScopeCriteriasDropDown = ['SCOPE1', 'SCOPE2', 'SCOPE3']
  formValidation = new FormValidation()
  comparisonCalculations = [] as CalculationInfo[]
  possibleCalculations: Calculation[] = []
  comparison = {} as ComparisonObject
  editExistingComparison = false
  tableHeader = 'Scope'
  isFinishedLoading = false
  router = useRouter()

  $refs!: {
    comparisonChartMainCategoryRef: ComparisonChartMainCategory
    comparisonChartIndividualGroupsRef: ComparisonChartIndividualGroups
    comparisonChartScopeRef: ComparisonChartScope
  }

  async beforeMount() {
    let possibleUpdated = false
    const elem = document.getElementById('create-comparison-link')
    //console.log("CreateComparison.vue, elem:", elem, "mode", this.mode)
    if (elem ) {
        elem.addEventListener('click', async () => {
          // set in new mode
          console.log("opret en sammenlign er clicked")
          this.editExistingComparison = false
          this.isFinishedLoading = false
          this.comparison = {}
          // added here to make sure is run
          await this.setupPossibleCalculations()
          possibleUpdated = true
        }, {once: true})
    }


    if (this.route.query.comparisonId) {
      const showComparisonId = this.route.query.comparisonId
      await comparisonClient.getComparison(Number(showComparisonId), this.getContactPerson?.vatNo,
          this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null').then((result: AxiosResponse<ComparisonObject>) => {
        this.extractComparisonResultData(result);
      })
      this.editExistingComparison = true
      this.isFinishedLoading = true
    } else {
      this.editExistingComparison = this.mode === 'edit'
    }
    this.filterChangeSubmit()

    if (!possibleUpdated) {
     await this.setupPossibleCalculations()
    }
  }

  updateChart() {
    console.log("CreateC updateChart: update graph", this.$refs.comparisonChartScopeRef, this.$refs.comparisonChartMainCategoryRef, this.$refs.comparisonChartIndividualGroupsRef,
        this.comparisonCalculations, this.selectedComparisonCriteria, this.selectedComparisonScopeCriteriasDropDown)
    if (this.comparison && this.selectedComparisonCriteria && this.selectedComparisonScopeCriteriasDropDown) {
      if (this.isScopeActive() && this.$refs.comparisonChartScopeRef) {
        this.$refs.comparisonChartScopeRef.updateChart(this.selectedComparisonCriteria, this.selectedComparisonScopeCriteriasDropDown, this.comparison)
      } else if (this.isCategoryActive() && this.$refs.comparisonChartMainCategoryRef) {
        this.$refs.comparisonChartMainCategoryRef.updateChart(this.selectedComparisonCriteria, this.selectedComparisonScopeCriteriasDropDown, this.comparison)
      } else if (this.isSubCategoryActive() && this.$refs.comparisonChartIndividualGroupsRef) {
        this.$refs.comparisonChartIndividualGroupsRef.updateChart(this.selectedComparisonCriteria, this.selectedComparisonScopeCriteriasDropDown, this.comparison)
      }
    }
  }

  @Watch('comparison')
  comparisonWatch() {
    this.updateChart()
  }

  private extractComparisonResultData(result: AxiosResponse<ComparisonObject>) {
    if (result.data && result.data.comparisonMetadata) {
      this.comparison = result.data
      this.comment = result.data.comparisonMetadata.description || ''
      const secondDD = []
      // set filter dropdowns
      if (result.data.comparisonMetadata.compareFilters) {
        for (const filter of result.data.comparisonMetadata.compareFilters) {
          if (filter && filter.compareType && filter.dropdownId) {
            this.selectedComparisonCriteriaDropDown = this.getfirstDropdownValue(filter.compareType)
            secondDD.push(this.getSecondDropdownValue(filter.dropdownId))
          }
        }
        this.selectedComparisonScopeCriteriasDropDown = secondDD
        console.log("Setting filter on load firstDD:", this.selectedComparisonCriteriaDropDown, "secondDD:", this.selectedComparisonScopeCriteriasDropDown)
      }
      this.updateChart()
    }
  }

  filterChangeSubmit() {
    //console.log("filter changed:", this.selectedComparisonCriteriaDropDown, this.selectedComparisonScopeCriteriasDropDown)
    //console.log("filter changed:", this.comparisonScopeCriteriasToLock, this.selectedComparisonScopeCriteriasDropDown)
    if (this.selectedComparisonCriteriaDropDown !== this.selectedComparisonCriteria || this.selectedComparisonScopeCriteriasDropDown !== this.selectedComparisonScopeCriterias) {
      //console.log("filter changed update criterias to", this.selectedComparisonCriteriaDropDown, this.selectedComparisonScopeCriteriasDropDown)
      this.selectedComparisonCriteria = this.selectedComparisonCriteriaDropDown
      this.selectedComparisonScopeCriterias = this.selectedComparisonScopeCriteriasDropDown
      this.updateChart()
    }

    if (this.selectedComparisonCriteria === 'SCOPES') {
      this.tableHeader = "Scope"
    } else if (this.selectedComparisonCriteria === 'CATEGORIES') {
      this.tableHeader = 'Tema'
    } else {
      this.tableHeader = "Emner"
    }

  }

  updateSelectedScopes(newVal: any) {
  console.log('Emit received, updating values...')
    const defaultValue = ['SCOPE1', 'SCOPE2', 'SCOPE3', 'UDENFOR SCOPES']
    const selectedScopes = newVal
    console.log('selected Scopes = ', JSON.stringify(selectedScopes, null, 2))
    console.log('selected Scopes.Lenght: ', selectedScopes.length)

    if(selectedScopes.length === 4 || selectedScopes.length === 0) {
      this.comparisonScopeCriteriasToLock = defaultValue
      console.log('defaultValue triggered: ', this.comparisonScopeCriteriasToLock)
    }
    else {
      {
        const altCriteria: string[] = []

        selectedScopes.forEach((scope: string) => {
          //console.log('Checking scope: ', scope);
          if (scope === 'SCOPE1') {
            altCriteria.push('SCOPE1');
          }
          if (scope === 'SCOPE2') {
            altCriteria.push('SCOPE2');
          }
          if (scope === 'SCOPE3') {
            altCriteria.push('SCOPE3');
          }
          if (scope === 'UDENFOR SCOPES') {
            altCriteria.push('UDENFOR SCOPES');
          }
        });

        this.comparisonScopeCriteriasToLock = altCriteria;
        console.log('Final comparisonCriteriaToLock = ', JSON.stringify(this.comparisonScopeCriteriasToLock, null,2))
      }
      console.log('Submitting with criteria:', JSON.stringify(this.comparisonScopeCriteriasToLock, null, 2));
      console.log('Selected Comparison Alt Criteria:', JSON.stringify(this.selectedComparisonScopeCriterias, null, 2));
    }
    this.selectedComparisonScopeCriteriasDropDown = this.comparisonScopeCriteriasToLock
  }

  isScopeActive() {
    if (this.selectedComparisonCriteria === 'SCOPES') {
      return true
    } else {
      return false
    }
  }

  isCategoryActive() {
    if (this.selectedComparisonCriteria === 'CATEGORIES') {
      return true
    } else {
      return false
    }
  }

  isSubCategoryActive() {
    if (this.selectedComparisonCriteria === 'ENERGY'
        || this.selectedComparisonCriteria === 'PROCUREMENT'
        || this.selectedComparisonCriteria === 'TRANSPORT'
        || this.selectedComparisonCriteria === 'TRASH'
        || this.selectedComparisonCriteria === 'PRODUCTS') {
      return true
    } else {
      return false
    }
  }

  isScope1Active(ignoreSecondDropdown: boolean) {
    if (this.selectedComparisonCriteria === 'SCOPES') {
      if (ignoreSecondDropdown) {
        return true
      } else {
        if (this.selectedComparisonScopeCriterias.length === 0
            || this.selectedComparisonScopeCriterias.length === 4
            || this.selectedComparisonScopeCriterias.includes('SCOPE1')
        ) {
          return true;
        }
      }
    }
    return false;
  }

  isScope2Active(ignoreSecondDropdown: boolean) {
    if (this.selectedComparisonCriteria === 'SCOPES') {
      if (ignoreSecondDropdown) {
        return true
      } else {
        if (this.selectedComparisonScopeCriterias.length === 0
            || this.selectedComparisonScopeCriterias.length === 4
            || this.selectedComparisonScopeCriterias.includes('SCOPE2')
        ) {
          return true;
        }
      }
    }
    return false;
  }

  isScope3Active(ignoreSecondDropdown: boolean) {
    if (this.selectedComparisonCriteria === 'SCOPES') {
      if (ignoreSecondDropdown) {
        return true
      } else {
        if (this.selectedComparisonScopeCriterias.length === 0
            || this.selectedComparisonScopeCriterias.length === 4
            || this.selectedComparisonScopeCriterias.includes('SCOPE3')
        ) {
          return true
        }
      }
    }
    return false
  }

  isScopeOutsideActive(ignoreSecondDropdown: boolean) {
    if (this.selectedComparisonCriteria === 'SCOPES') {
      if (ignoreSecondDropdown) {
        return true
      } else {
        if (this.selectedComparisonScopeCriterias.length === 0
            || this.selectedComparisonScopeCriterias.length === 4
            || this.selectedComparisonScopeCriterias.includes('UDENFOR SCOPES')
        ) {
          return true;
        }
      }
    }
    return false;
  }

  getfirstDropdownValue(item: CompareFilterObjectCompareTypeEnum) {
    if (item === CompareFilterObjectCompareTypeEnum.SCOPE) {
      return "SCOPES"
    } else if (item === CompareFilterObjectCompareTypeEnum.CATEGORY) {
      return "CATEGORIES"
    } else if (item === CompareFilterObjectCompareTypeEnum.ENERGY) {
        return "ENERGY"
    } else if (item === CompareFilterObjectCompareTypeEnum.PROCUREMENT) {
        return "PROCUREMENT"
    } else if (item === CompareFilterObjectCompareTypeEnum.TRANSPORT) {
        return "TRANSPORT"
    } else if (item === CompareFilterObjectCompareTypeEnum.TRASH) {
        return "TRASH"
    } else if (item === CompareFilterObjectCompareTypeEnum.PRODUCTS) {
        return "PRODUCTS"
    } else {
      return ''
    }
  }

  getSecondDropdownValue(dropdownId: number) {
      if (dropdownId === 1) {
        return "SCOPE1"
      } else if (dropdownId === 2) {
        return "SCOPE2"
      } else if (dropdownId === 3) {
        return "SCOPE3"
      } else if (dropdownId === 4) {
        return "ALLE SCOPES"
      } else if (dropdownId === 5) {
        return "UDENFOR SCOPES"
      } else {
        return ''
      }
  }

  /** Creating total is easier in BE so use this or use raw calculation.fieldValues */
  getEmissionValue(calculation: CalculationFull, filterType: string, filter: string) {
    //console.log("getData start", calculation.calculation?.calculationPurpose.name, filterType, filter)
    if (filterType === 'SCOPE') {
      // scope
      return this.getEmissionValueScopeOrTotal(calculation, filter)
    } else if (filterType === 'G') {
      // group
      if (filter === 'G_1') {
        console.log("getData G G_1", this.getEmissionValueGroup(calculation, "ENERGY", this.selectedComparisonScopeCriterias))
        return this.getEmissionValueGroup(calculation, "ENERGY", this.selectedComparisonScopeCriterias)
      } else if (filter === 'G_2') {
        return this.getEmissionValueGroup(calculation, "PROCUREMENT", this.selectedComparisonScopeCriterias)
      } else if (filter === 'G_3') {
        return this.getEmissionValueGroup(calculation, "TRANSPORT", this.selectedComparisonScopeCriterias)
      } else if (filter === 'G_4') {
        return this.getEmissionValueGroup(calculation, "TRASH", this.selectedComparisonScopeCriterias)
      } else if (filter === 'G_5') {
        return this.getEmissionValueGroup(calculation, "PRODUCTS", this.selectedComparisonScopeCriterias)
      }
    } else {
      return ''
    }
  }

  getEmissionValueScopeOrTotal(calculation: CalculationFull, filter: string) {
    //console.log("getDataScope start", calculation.calculation?.calculationPurpose.name, filter, this.selectedComparisonCriteria, this.selectedComparisonScopeCriterias);
    if (calculation && calculation.totals) {
      //console.log("getDataScope calculation.totals", calculation.totals);
      if (filter === 'SCOPE_TOTAL') {
        //console.log("getDataScope SCOPE_TOTAL", this.selectedComparisonCriteria, this.isCategoryActive(), this.isSubCategoryActive());
        if (this.selectedComparisonCriteria === 'SCOPES') {
          // If scope comparison is selected, we find the total from the total field for scope 1+2+3
          //console.log("getDataScope all scope total")
          const totalInfo = calculation.totals.find(t => t.key === 'ALL')
          if (totalInfo) {
            return (totalInfo.scope1 ? totalInfo.scope1 : 0) + (totalInfo.scope2 ? totalInfo.scope2 : 0) + (totalInfo.scope3 ? totalInfo.scope3 : 0)
          }
        } else if (this.isCategoryActive()) {
          // If group comparison is selected for NOT all scopes, we find the total by summation of selected scopes values
          let totalSum = 0
          for (const totalInfo of calculation.totals) {
            if (totalInfo.key !== 'ALL') {
              if (this.selectedComparisonScopeCriterias.includes('SCOPE1')) {
                totalSum += (totalInfo.scope1 ? totalInfo.scope1 : 0)
              }
              if (this.selectedComparisonScopeCriterias.includes('SCOPE2')) {
                totalSum += (totalInfo.scope2 ? totalInfo.scope2 : 0)
              }
              if (this.selectedComparisonScopeCriterias.includes('SCOPE3')) {
                totalSum += (totalInfo.scope3 ? totalInfo.scope3 : 0)
              }
              if (this.selectedComparisonScopeCriterias.includes('UDENFOR SCOPES')) {
                totalSum += (totalInfo.outsideScope ? totalInfo.outsideScope : 0)
              }
            }
          }
          return totalSum
        } else if (this.isSubCategoryActive()) {
          // If subgroup comparison is selected for NOT all scopes, we find the total by summation of selected scopes values of the subgroups for the selected group
          let totalSum = 0
          const selectedSubgroups = this.subgroupByGroupMap.get(this.selectedComparisonCriteria)
          for (const totalInfo of calculation.totals) {
            //console.log("getDataScope sg total", totalInfo.key, totalInfo)
            if (totalInfo.key && selectedSubgroups && totalInfo.key !== 'ALL' && selectedSubgroups.includes(totalInfo.key)) {
              if (this.selectedComparisonScopeCriterias.includes('SCOPE1')) {
                totalSum += (totalInfo.scope1 ? totalInfo.scope1 : 0)
              }
              if (this.selectedComparisonScopeCriterias.includes('SCOPE2')) {
                totalSum += (totalInfo.scope2 ? totalInfo.scope2 : 0)
              }
              if (this.selectedComparisonScopeCriterias.includes('SCOPE3')) {
                totalSum += (totalInfo.scope3 ? totalInfo.scope3 : 0)
              }
              if (this.selectedComparisonScopeCriterias.includes('UDENFOR SCOPES')) {
                totalSum += (totalInfo.outsideScope ? totalInfo.outsideScope : 0)
              }
            }
            //console.log("getDataScope sg total sum intermediate", totalSum)
          }
          //console.log("getDataScope sg total sum final", totalSum)
          return totalSum
        }
      } else {
        for (const totalInfo of calculation.totals) {
          if (totalInfo.key === 'ALL') {
            // scopes
            if (filter == "SCOPE_1") {
              return totalInfo.scope1
            }
            if (filter == "SCOPE_2") {
              return totalInfo.scope2
            }
            if (filter == "SCOPE_3") {
              return totalInfo.scope3
            }
            if (filter == "OUTSIDE_SCOPE") {
              return totalInfo.outsideScope
            }
          }
        }
      }
    }
    return 0
  }

  monetaryDifference() {
    /*if (this.comparison) {
      if (this.comparison.comparisonMetadata?.hasMonetaryCalculations) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }*/
    return false
  }

  timePeriodDifferenceText() {
    return this.getContentfulString("comparison.create.time-period-difference.warning.message")
  }

  timePeriodDifference() {
    if (this.comparison && this.comparison.calculations) {
      return this.comparison.comparisonMetadata?.hasDifferentEMF
    } else {
      return false
    }
  }

  async submitComment() {
    //console.log("submitComment", this.comparison.comparisonMetadata, "firstDD", this.selectedComparisonCriteriaDropDown, "secondDD", this.selectedComparisonScopeCriterias)
    if (this.comparison && this.comparison.comparisonMetadata) {
      this.comparison.comparisonMetadata.description = this.comment;
      this.comparison.comparisonMetadata.compareFilters = [];
      this.comparison.comparisonMetadata.calculations = []
      if (window.sessionStorage.getItem('vatNo')) {
        this.comparison.comparisonMetadata.vatNo = window.sessionStorage.getItem('vatNo') ?? this.comparison.comparisonMetadata.vatNo
      }
      this.comparison.comparisonMetadata.thirdPartyVatNo = this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null'
      if (this.comparison.calculations) {
        for (const calc of this.comparison.calculations) {
          const cc: CalculationInfo = {
            calculationId: calc.calculation?.id,
            calculationName: calc.calculation?.calculationPurpose.name,
            contactpersonRid: calc.calculation?.contactPersonRID,
            isKladde: false
          };
          //console.log("add", cc)
          this.comparison.comparisonMetadata.calculations.push(cc)
        }
      }
      const firstDropdownValue = this.selectedComparisonCriteriaDropDown;
      const secondDDItems = this.selectedComparisonScopeCriterias;
      if (firstDropdownValue) {
        for (const item of secondDDItems) {
          const filterObject: CompareFilterObject = {
            compareType: this.getCompareFilterObjectCompareTypeEnum(firstDropdownValue),
            dropdownId: this.getDropdownId(item)
          };
          this.comparison.comparisonMetadata.compareFilters.push(filterObject);
        }
      }
    }
    //console.log("updateComparison", this.comparison.comparisonMetadata)
    await comparisonClient.updateComparison(this.comparison.comparisonMetadata)
    const message = this.getRenderedContentfulRT('comparison.create.save-button.toast-text', false)
      .replace('#1#', this.comparison.comparisonMetadata?.name)

    this.addToast('createComparisonToast', 'success', this.getContentfulString('comparison.create.save-button.toast-title'), message, true, false, true, true)
  }

  getCompareFilterObjectCompareTypeEnum(item: string): CompareFilterObjectCompareTypeEnum {
    switch (item) {
      case 'SCOPES': return CompareFilterObjectCompareTypeEnum.SCOPE;
      case 'CATEGORIES': return CompareFilterObjectCompareTypeEnum.CATEGORY;
      case 'ENERGY': return CompareFilterObjectCompareTypeEnum.ENERGY;
      case 'PROCUREMENT': return CompareFilterObjectCompareTypeEnum.PROCUREMENT;
      case 'TRANSPORT': return CompareFilterObjectCompareTypeEnum.TRANSPORT;
      case 'TRASH': return CompareFilterObjectCompareTypeEnum.TRASH;
      case 'PRODUCTS': return CompareFilterObjectCompareTypeEnum.PRODUCTS;
      default: return CompareFilterObjectCompareTypeEnum.SCOPE;
    }
  }

  getDropdownId(item: string): number {
    switch (item) {
      case 'SCOPE1': return 1;
      case 'SCOPE2': return 2;
      case 'SCOPE3': return 3;
      case 'ALLE SCOPES': return 4;
      case 'UDENFOR SCOPES': return 5;
      default: return 0;
    }
  }



  getEnumValue(firstDDValue : string) {
    if (firstDDValue === "ENERGY") {
      return CompareFilterObjectCompareTypeEnum.ENERGY
    } else if (firstDDValue === "TRANSPORT") {
      return CompareFilterObjectCompareTypeEnum.TRANSPORT
    } else if (firstDDValue === "PROCUREMENT") {
      return CompareFilterObjectCompareTypeEnum.PROCUREMENT
    } else if (firstDDValue === "TRASH") {
      return CompareFilterObjectCompareTypeEnum.TRASH
    } else if (firstDDValue === "PRODUCTS") {
      return CompareFilterObjectCompareTypeEnum.PRODUCTS
    }
  }

  /* use values for db table field_enum */
  getScopeFilterId(scope: string) {
    if (scope === 'SCOPE1') {
      return 1;
    } else if (scope === 'SCOPE2') {
      return 2;
    } else if (scope === 'SCOPE3') {
      return 3;
    } else if (scope === 'ALLE SCOPES') {
      return 4;
    } else if (scope === 'UDENFOR SCOPES') {
      return 5;
    } else {
      return 0
    }
  }

  exportComparisonToExcel() {
    console.log('Functionality not yet implemented - Sorry! :( ')
  }

  handleComparisonLoaded(result: AxiosResponse<ComparisonObject>) {
    this.extractComparisonResultData(result)
    this.isFinishedLoading = true;
    console.log('Comparison has finished loading');
    this.updateChart()
  }

  updateComparisonCalcList(selectedComparisons: CalculationInfo[]) {
    this.comparisonCalculations = selectedComparisons
    console.log('UpdateCalcList = ', JSON.stringify(this.comparisonCalculations, null, 2))
  }

  async setupPossibleCalculations() {
    //this.eventHub.emit("start-loading", "CreateComparison")
    await this.store.dispatch("loadCalculations").finally(() => {
      //this.eventHub.emit("stop-loading", "CreateComparison")
    })
    const cals = this.store.getters.getCalculations
    console.log("setupPossibleCalculations", cals)
    this.possibleCalculations = cals
        .filter((cal: Calculation) => (cal.state === CalculationStateEnum.Finished || cal.state === CalculationStateEnum.FinishedDuplicated || cal.state === CalculationStateEnum.Readonly))
        .filter((cal: Calculation) => (this.isCalculationEcVersionAllowedForRecalculation(cal)))
    this.possibleCalculations = this.possibleCalculations.sort((a, b) => a.calculationPurpose.name.localeCompare(b.calculationPurpose.name))
  }

  get thirdpartyInfo() {
    return this.store.getters.getThirdpartyInfo
  }

  get getContactPerson() {
    return this.store.getters.getContactPerson
  }

  async submitForm(e: Event) {
    //TODO SUBMIT
    console.log('does nothing right now. Check back later')
  }

}

function getCompareFilterObjectCompareTypeEnum(item: any, string: any) {
    throw new Error('Function not implemented.');
}
</script>

<style lang="scss" scoped>

.info-icon-size, .icon-svg-warning {
  width: 100%;
  max-height: 25px;
  min-height: 25px;
  max-width: 30px;
  min-width: 30px;
}

#info-box-wrapper-div p,
#info-box-declaration-wrapper-div p,
#info-box-declaration2-wrapper-div p {
  //used to overwrite inherited p margin.
  margin: 0 0 0 0;
}

.tableContainer {
  width: 100%;
  margin: 0 auto;
  border: 1px solid $color-primary;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tableContainer table tbody tr:first-child th,
.tableContainer table tbody tr:first-child td {
  background-color: $color-primary;
  color: white;
}

.bg-green {
  background-color: #078856 !important;
  color: white;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid $color-primary;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  width: 30%;
}

td {
  width: 70%;
}

.dynamic-width {
  width: auto;
}

.multiselect-calculation {
  --ms-font-size: 1.6rem;
  --ms-border-color: #747474;
  --ms-border-width: 1px;
  --ms-option-font-size: 1.6rem;
  --ms-option-line-height: 1.8rem;
  --ms-option-py: 7px;
  --ms-option-px: 34px;
  --ms-tag-font-size: 1.6rem;
  --ms-line-height: 2.4rem;
  --ms-tag-line-height: 2.4rem;
  --ms-px: 4px;
  --ms-py: 15px;
  --ms-tag-bg: black;
  --ms-tag-color: white;
  padding: 5px var(--ms-px) 5px var(--ms-px);

  --ms-max-height: 30rem;

  --ms-option-bg-selected-pointed: #F2F2F2;
  --ms-option-color-selected-pointed: black;
  --ms-option-bg-pointed: #F2F2F2;

  --ms-group-label-py: 7px;
  --ms-group-label-line-height: 1.8rem;
  --ms-group-label-bg: #D7D7D7;
  --ms-group-label-bg-pointed: #C7C7C7;
  --ms-group-label-bg-selected: #D7D7D7;
  --ms-group-label-color-selected: black;
  --ms-group-label-bg-selected-pointed: #C7C7C7;
  --ms-group-label-bg-selected-disabled: #D7D7D7;
}

</style>
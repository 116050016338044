<template>
  <div v-if="isTestFramework" :data-cy-readystate="state" :data-cy-inner="inner" :data-cy-type="type" :id="identifier" class="cy-cypress-calculator" style="display:none"></div>
</template>

<script lang="ts">
import {Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";

/***
 * Testhelper: Adds div for testing purposes - "display: none".
 * Only exists during Cypress test runs.
 */
export default class CypressTestElement extends BaseComponent {
  @Prop()
  inner = ''

  @Prop()
  state = ''

  @Prop()
  identifier = ''

  @Prop()
  type = ''

}
</script>

<style scoped>

</style>